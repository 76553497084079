import React from 'react';
import { styled, Slider, SliderThumb, IconButton } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function LikertScaleThumb(props: any) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      {/* <IconButton size="large">
        <ArrowDropDownIcon
          style={{
            width: '40px',
            height: '40px',
            position: 'relative',
            top: '-10px',
            left: '2px',
            color: '#000',
          }}
        />
      </IconButton> */}
    </SliderThumb>
  );
}

const LikertScaleSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: '10px', // Height of the line representing the thumb
    width: '1px', // Width of the line, making it thin
    borderRadius: '0', // Remove rounded corners
    backgroundColor: '#707172', // Color of the line
    marginTop: '0px', // Adjust this value to center the line on the track
    marginLeft: '-1px', // Adjust to align the center of the line with the tick marks if needed
    '&:before': {
      display: 'none', // Hide pseudo-elements that might interfere
    },
    '&:after': {
      display: 'none', // Hide pseudo-elements that might interfere
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    background: '#000',
    height: 1,
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#000',
    width: '5px',
    height: '5px',
    borderRadius: '50%',
  },
  '& .MuiSlider-valueLabel': {
    top: 'calc(150%)', // Adjust vertical position as needed
    //top: 'calc(50% - 10px)', // Adjust vertical position as needed
    //left: 'calc(-50% + 4px)', // Adjust this value to align the label above the thumb
    transform: 'translateX(-50%) scale(1)', // Add scale(1) to ensure transform is applied
    padding: '1px 4px', // Smaller padding reduces the size of the box
    fontSize: '0.75rem', // Smaller font size reduces the size of the box
    fontWeight: 'bold', // Optional: makes the number stand out
    backgroundColor: 'grey', // Optional: changes the background color of the box
    color: 'white', // Optional: changes the text color
    '&::before': {
      display: 'none', // Removes the arrow below the label
    },
  }
}));
interface LikertScaleProps {
  value: number;
}
const StaticLikertScale = ({ value }: LikertScaleProps) => {
  return (
    <LikertScaleSlider
      value={value}
      marks={[{ value: 0.7 }, { value: 5.2 }]}
      step={1}
      min={0.7}
      max={5.2}
      slots={{ thumb: LikertScaleThumb }}
      disabled
      valueLabelDisplay="on"
    />
  );
};

export default StaticLikertScale;
