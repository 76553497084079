import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../../config/axios";
import { EndPoints } from "../../../constants/apiUrls";
import { RequestStatus } from "../../../types/request";
import { storeKey } from "../constants";

const initialState = {
  status : RequestStatus.Init
}

const deleteAccountSlice = createSlice({name:`${storeKey}/deleteAccount`,
  initialState:initialState,
  reducers:{},
  extraReducers(builder) {
    builder.addCase(deleteAccountThunk.pending,state=>{
      state.status = RequestStatus.Loading
    });
    builder.addCase(deleteAccountThunk.fulfilled,state=>{
      state.status = RequestStatus.Done;
      // toast.success("Account Deleted Successfully!");
    });
    builder.addCase(deleteAccountThunk.rejected,state=>{
      state.status = RequestStatus.Error
    });
  },})

export const deleteAccountThunk = createAsyncThunk<string,string>(`${storeKey}/accountDelete`,
  async (account_id:string,{rejectWithValue})=>{
    try{
      const result:AxiosResponse<string> = await axiosInstance.delete(EndPoints.DELETE_ACCOUNT,{data:{account_id}});
      return result.data;

    }catch (error){
      return rejectWithValue(error)
    }
  })


export const deleteAccountReducer = deleteAccountSlice.reducer;