import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { UpdateEvaluationType } from '../type';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

const initialState = {
  status: RequestStatus.Init,
};

const putEvaluationSlice = createSlice({
  name: 'evaluation/putEvaluation',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(putEvaluationThunk.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(putEvaluationThunk.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      // toast.success('Evaluation Updated Successfully');
    });
    builder.addCase(putEvaluationThunk.rejected, (state) => {
      state.status = RequestStatus.Error;
      toast.error("Evaluation Couldn't Update");
    });
  },
});

export const putEvaluationThunk = createAsyncThunk<
  string,
  UpdateEvaluationType
>(
  'updateEvaluation',
  async (evaluationPayload: UpdateEvaluationType, { rejectWithValue }) => {
    try {
      const result: AxiosResponse<string> = await axiosInstance.put(
        EndPoints.POST_EVALUATION,
        evaluationPayload,
      );
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default putEvaluationSlice.reducer;
