// hooks/useGetCloudinarySignature.ts

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { CloudinarySignatureResponse, getCloudinarySignature } from '../reducers/getCloudinarySignature';

interface UseGetCloudinarySignatureProps {
  loading: boolean;
  error: unknown;
  fetchSignature: () => Promise<CloudinarySignatureResponse | undefined>;
}

const useGetCloudinarySignature = (): UseGetCloudinarySignatureProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const fetchSignature = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(getCloudinarySignature());
      setLoading(false);
      if (getCloudinarySignature.fulfilled.match(resultAction)) {
        return resultAction.payload;
      } else if (getCloudinarySignature.rejected.match(resultAction)) {
        throw resultAction.payload;
      }
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  return { loading, error, fetchSignature };
};

export default useGetCloudinarySignature;
