import { useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import { getAccountSetting } from '../../Accounts/reducers/getAccountSetting';

const useGetAccountSettingsUsers = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const getAccountSettingsUsers = async (id: string) => {
    try {
      const resultAction = await dispatch(getAccountSetting(id));
      if (getAccountSetting.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return { loading, error, getAccountSettingsUsers };
};

export default useGetAccountSettingsUsers;
