import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const NavigationLink = styled(NavLink)(({ theme }) => ({
  color: 'black',
  padding: '10px',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
}));
