import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { AssessmentEnquiryFormRequest } from '../type';
import { useState } from 'react';
import { postAssessmentEnquiryForm } from '../reducers/postAssessmentEnquiryForm';

const usePostAssessmentEnquiryForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const postSurvey = async (
    assessmentId: string,
    requestPayload: AssessmentEnquiryFormRequest,
  ): Promise<boolean> => {
    try {
      const resultAction = await dispatch(
        postAssessmentEnquiryForm({ assessmentId, requestPayload }),
      );

      if (postAssessmentEnquiryForm.fulfilled.match(resultAction)) {
        setLoading(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, postSurvey };
};

export default usePostAssessmentEnquiryForm;
