import translations from './en/translation.json';

export default function translate(
  key: string,
  values: { [placeholder: string]: string | number } = {},
): string {
  if (!translations[key]) {
    return '';
  }

  let translatedString: string = translations[key];

  for (const placeholder in values) {
    if (Object.prototype.hasOwnProperty.call(values, placeholder)) {
      const placeholderValue = values[placeholder];
      translatedString = translatedString.replace(
        `{{${placeholder}}}`,
        placeholderValue.toString(),
      );
    }
  }

  return translatedString;
}
