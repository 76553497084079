import { useDispatch } from 'react-redux';
import { deleteAssessment } from '../reducers/deleteAssessment';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface useDeleteAssessmentProps {
    loading: boolean;
    success: boolean;
    error: string | null;
    deleteAssessmentById: (id: string) => void;
}

const useDeleteAssessment = (): useDeleteAssessmentProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const deleteAssessmentById = async (id: string) => {
        try {
            setLoading(true);
            setError(null);
            const resultAction = await dispatch(deleteAssessment(id));
            if (deleteAssessment.fulfilled.match(resultAction)) {
                setSuccess(true);
                navigate('/evaluation-assessment-list');
            } else {
                setSuccess(false);
                setError(resultAction.payload as string || 'Failed to delete');
            }
        } catch (err) {
            setSuccess(false);
            setError('An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    return { error, loading, success, deleteAssessmentById };
};

export default useDeleteAssessment;