import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { UpsertAssessmentPayload } from '../type';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';

const initialState = {
    status: RequestStatus.Init,
};

const putAssessmentSlice = createSlice({
    name: 'assessment/putAssessment',
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(putAssessmentThunk.pending, (state) => {
            state.status = RequestStatus.Loading;
        });
        builder.addCase(putAssessmentThunk.fulfilled, (state) => {
            state.status = RequestStatus.Done;
        });
        builder.addCase(putAssessmentThunk.rejected, (state) => {
            state.status = RequestStatus.Error;
        });
    },
});

export const putAssessmentThunk = createAsyncThunk<
    string,
    UpsertAssessmentPayload
>(
    'updateAssessment',
    async (assessmentPayload: UpsertAssessmentPayload, { rejectWithValue }) => {
        try {
            const result: AxiosResponse<string> = await axiosV2Instance.put(
                EndPoints.ASSESSMENT,
                assessmentPayload,
            );
            return result.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export default putAssessmentSlice.reducer;
