import { useEffect, useState } from 'react';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AssessmentDefinitionResponse } from '../type';

interface useGetAssessmentDefinitionsResponse {
    data: AssessmentDefinitionResponse[];
    loading: boolean;
    error: unknown;
}

const useGetAssessmentDefinitions = (): useGetAssessmentDefinitionsResponse => {
    const [data, setData] = useState<AssessmentDefinitionResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosV2Instance.get<AssessmentDefinitionResponse[]>(
                    EndPoints.ASSESSMENT_DEFINITIONS
                );
                setData(response.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    }, []); // Runs once on component mount

    return { data, loading, error };
};

export default useGetAssessmentDefinitions;