import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import { RequestStatus } from '../../../types/request';
import { toast } from 'react-toastify';

export interface ForgetPasswordSliceType {
  status: RequestStatus;
  response: { success: string } | null;
}

const initialState: ForgetPasswordSliceType = {
  status: RequestStatus.Init,
  response: null,
};

const forgetPasswordSlice = createSlice({
  name: '/auth/forgetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postForgetPassword.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postForgetPassword.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      toast.success('Reset password link is sent.');
    });
    builder.addCase(postForgetPassword.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      const errorMessage =
        (
          (action.payload as AxiosError).response?.data as {
            error: string;
          }
        ).error ?? '';
      toast.error(errorMessage);
    });
  },
});

export const postForgetPassword = createAsyncThunk<string, string>(
  '/auth/forget-password',
  async (email: string, { rejectWithValue }) => {
    try {
      const result: AxiosResponse<string> = await axiosInstance.post(
        EndPoints.POST_FORGET_PASSWORD,
        { email },
      );
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default forgetPasswordSlice.reducer;
