import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { logoutFromApplication } from '../reducers/logout';
import { logout } from '../reducers/authenticateUser';

const useLogout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const postLogoutUser = async () => {
    try {
      const resultAction = await dispatch(logoutFromApplication());
      if (logoutFromApplication.fulfilled.match(resultAction)) {
        dispatch(logout());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { postLogoutUser };
};

export default useLogout;
