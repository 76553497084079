import React from 'react';
import {
  Breadcrumbs,
  Typography,
} from '@mui/material';
import './Header.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useRoutes } from '../../constants/routes';

export default function Header() {
  const location = useLocation();
  const { id } = useParams();
  const urlStrings = location.pathname.split('/');
  const Routes = useRoutes();

  let parentUrl = '';
  let menuName = '';
  let subMenuName = '';

  function getNameByUrl(): void {
    for (const item of Routes) {
      if (urlStrings.includes(item.url)) {
        menuName = item.name;
      }

      if (item.submenus) {
        for (const submenu of item.submenus) {
          if (urlStrings.includes(submenu.url)) {
            menuName = item.name;
            subMenuName = submenu.name;
            parentUrl = item.url;
          }
        }
      }
    }
  }

  getNameByUrl();

  return (
    <div className="header-container">
      <div className="side-bar">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="var(--black-40, rgba(28, 28, 28, 0.40)">
            <Link
              style={{
                textDecoration: 'none',
                color: 'var(--black-40, rgba(28, 28, 28, 0.40)',
              }}
              to={'/' + parentUrl}
            >
              {menuName}
            </Link>
          </Typography>
          {subMenuName && (
            <Typography color="#1C1C1C">
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#1C1C1C',
                }}
                to={urlStrings[1] + (id ? '/' + id : '')}
              >
                {subMenuName}
              </Link>
            </Typography>
          )}
        </Breadcrumbs>
      </div>
    </div>
  );
}
