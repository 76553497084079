import React from 'react';
import { Box, Paper, Typography, makeStyles } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

export const SuccessPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CheckCircleOutlineOutlinedIcon
          sx={{
            fontSize: 60,
            color: 'green',
          }}
        />
        <Typography
          variant="h5"
          sx={{
            marginTop: '30px',
            textAlign: 'center',
          }}
        >
          Success! Your evaluation survey was completed successfully.
        </Typography>
      </Paper>
    </Box>
  );
};
