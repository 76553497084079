import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';
import { RequestStatus } from '../../../types/request';

export interface GroupInitialState {
  status: RequestStatus;
  groupData: PostGroupResponse | null;
}

const initialState : GroupInitialState = {
  status: RequestStatus.Init,
  groupData: null,
};

const postGroupSlice = createSlice({
  name: 'postGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postGroup.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postGroup.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.groupData=action.payload;
    });
    builder.addCase(postGroup.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const postGroup = createAsyncThunk<PostGroupResponse, PostGroupRequest>(
  `public/assessment/postGroup`,
  async (postGroupPayload: PostGroupRequest, { rejectWithValue }) => {
    try {
      const endpoint = `public/assessment/group`;
      const response: AxiosResponse<PostGroupResponse> = await axiosV2Instance.post(
        endpoint,
        postGroupPayload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postGroupsReducer = postGroupSlice.reducer;

export type PostGroupResponse = {
  id: number;
  uuid: string; 
  name: string; 
}

export type PostGroupRequest = {
    account_id: string; 
    name: string; 
  }
  
