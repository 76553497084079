import React, { createContext, useContext, ReactNode } from 'react';
import { CustomAccountBranding } from '../type';
import { useGetBranding } from '../hooks/useGetBranding';

const BrandingContext = createContext<CustomAccountBranding | null>(null);

interface BrandingProviderProps {
  children: ReactNode;
}

export const useBranding = (): CustomAccountBranding | null => {
  const context = useContext(BrandingContext);
  if (context === undefined) {
    throw new Error('useBranding must be used within a BrandingProvider');
  }
  return context;
};

export const BrandingProvider: React.FC<BrandingProviderProps> = ({ children }) => {
  const branding = useGetBranding();

  return (
    <BrandingContext.Provider value={branding}>
      {children}
    </BrandingContext.Provider>
  );
};