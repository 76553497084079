import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AccountDetails, GetClientReducer } from '../types';
import { storeKey } from '../constants';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: GetClientReducer = {
  status: RequestStatus.Init,
  client: null,
};

const getClientSlice = createSlice({
  name: 'getClient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSingleClient.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getSingleClient.fulfilled, (state, action) => {
      state.client = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(getSingleClient.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});


export const getClientReducer = getClientSlice.reducer;

export const getSingleClient = createAsyncThunk<AccountDetails, string>(
  `${storeKey}/users/fetchByIdStatus`,
  async (userId, thunkAPI) => {
    try {
      const response = await getUserByID(userId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getUserByID = async (
  userID: string,
): Promise<AxiosResponse<AccountDetails>> => {
  return axiosInstance.get(`${EndPoints.GET_CLIENT_LIST + userID}/`);
};
