const industryTypes = [
  { id: 1, definition: "311000 - Food Manufacturing" },
  { id: 2, definition: "312000 - Beverage and Tobacco Product Manufacturing" },
  { id: 3, definition: "313000 - Textile Mills" },
  { id: 4, definition: "314000 - Textile Product Mills" },
  { id: 5, definition: "315000 - Apparel Manufacturing" },
  { id: 6, definition: "316000 - Leather and Allied Product Manufacturing" },
  { id: 7, definition: "321000 - Wood Product Manufacturing" },
  { id: 8, definition: "322000 - Paper Manufacturing" },
  { id: 9, definition: "323000 - Printing and Related Support Activities" },
  { id: 10, definition: "324000 - Petroleum and Coal Products Manufacturing" },
  { id: 11, definition: "325000 - Chemical Manufacturing" },
  { id: 12, definition: "326000 - Plastics and Rubber Products Manufacturing" },
  { id: 13, definition: "327000 - Nonmetallic Mineral Product Manufacturing" },
  { id: 14, definition: "331000 - Primary Metal Manufacturing" },
  { id: 15, definition: "332000 - Fabricated Metal Product Manufacturing" },
  { id: 16, definition: "333000 - Machinery Manufacturing" },
  { id: 17, definition: "334000 - Computer and Electronic Product Manufacturing" },
  { id: 18, definition: "335000 - Electrical Equipment, Appliance, and Component Manufacturing" },
  { id: 19, definition: "336000 - Transportation Equipment Manufacturing" },
  { id: 20, definition: "337000 - Furniture and Related Product Manufacturing" },
  { id: 21, definition: "339000 - Miscellaneous Manufacturing" },
  { id: 22, definition: "423510 - Metal Service Centers and Other Metal Merchant Wholesalers" },
  { id: 23, definition: "4841 - General Freight Trucking" },
  { id: 24, definition: "4842 - Specialized Freight Trucking" },
  { id: 25, definition: "488510 - Freight Transportation Arrangement" },
  { id: 26, definition: "488991 - Packing and Crating" },
  { id: 27, definition: "4931xx - Warehousing and Storage" },
  { id: 28, definition: "541330 - Engineering Services" },
  { id: 29, definition: "541380 - Testing Laboratories" },
  { id: 30, definition: "541420 - Industrial Design Services" },
  { id: 31, definition: "541511 - Custom Computer Programming Services" },
  { id: 32, definition: "541512 - Computer Systems Design Services" },
  { id: 33, definition: "54171x - Research and Development in the Physical, Engineering, and Life" },
  { id: 34, definition: "5417 - Scientific Research and Development Services" },
  { id: 35, definition: "541713 - Research and Development in Nanotechnology" },
  { id: 36, definition: "541714 - Research and Development in Biotechnology (except Nanobiotechnology)" },
  { id: 37, definition: "541715 - Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)" },
  { id: 38, definition: "541720 - Research and Development in the Social Sciences and Humanities" },
  { id: 39, definition: "811310 - Commercial and Industrial Machinery & Equipment (except Automotive & Electronic) Repair & Maintenance" }
];


export default industryTypes;
