import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { postForgetPassword } from '../reducers/forgetPassword';
import { useNavigate } from 'react-router';

const useForgetPAssword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const postForgetPasswordEmailId = async (emailId: string) => {
    try {
      const resultAction = await dispatch(postForgetPassword(emailId));
      if (postForgetPassword.fulfilled.match(resultAction)) {
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { postForgetPasswordEmailId };
};

export default useForgetPAssword;
