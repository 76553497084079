import React, { useState, useRef, useEffect } from 'react';
import { Box, CircularProgress, Paper, Typography, Grid, Container } from '@mui/material';

import { useBranding } from '../Branding/reducers/getBranding';
import LegacyFormTabs from './components/Legacy/LegacyFormTabs';
import AssessmentFormTabs from './components/AssessmentFormTabs';
import { useParams } from 'react-router-dom';
import {FeatureFlags} from '../Branding/type'

const EnquiryForm = () => {
  const branding = useBranding();
  const [loading, setLoading] = useState(true);
  const [useLegacyEndpoints, setUseLegacyEndpoints] = useState<boolean | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (branding !== null) {
      // When branding data is fetched, update the loading state and the useLegacyEndpoints flag
      setUseLegacyEndpoints(branding?.active_feature_flags?.[FeatureFlags.LEGACY_V1_ENDPOINTS] === true);
      setLoading(false);
    }
  }, [branding]);

  if (loading || !branding || !id) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return useLegacyEndpoints ? (
    <LegacyFormTabs branding={branding} />
  ) : (
    <AssessmentFormTabs id={id} branding={branding} />
  );
};

export default EnquiryForm;
