import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssessmentCreation, AssessmentCreationReducer, AssessmentDetails } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: AssessmentCreationReducer = {
    assessment_details: null,
    status: RequestStatus.Init,
};

export const PostAssessment = createAsyncThunk(
    'assessment/PostAssessment',
    async (assessmentPayload : AssessmentCreation, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.post<AssessmentDetails>(
                'public/assessment',
                assessmentPayload
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const PostAssessmentSlice = createSlice({
    name: 'PostAssessment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(PostAssessment.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(PostAssessment.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.assessment_details = action.payload;
            })
            .addCase(PostAssessment.rejected, (state, action) => {
                state.status = RequestStatus.Error;
            });
    },
});

export default PostAssessmentSlice.reducer;
