/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import EvaluationHeader from './EvaluationHeader';
import EvaluationCard from './EvaluationCard';
import { Box, CircularProgress } from '@mui/material';
import usePostEvaluationList from '../../../hooks/usePostEvaluationList';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../../../../redux/store.type';
import { useNavigate } from 'react-router-dom';
import useDeleteEvaluation from '../../../hooks/useDeleteEvaluation';
import ConfirmationBox from '../../../../../components/ConfirmationBox/ConfirmBox';
import { AccountDetails } from '../../../../Accounts/types';
import useDebounce from '../../../../../hooks/useDebounce';

const LegacyEvaluationList = () => {
    const { error, loading, getAllEvaluations } = usePostEvaluationList();
    const deleteEvaluationReducer = useDeleteEvaluation();
    const [openConfirmBox, SetOpenConfirmBox] = useState(false);
    const [deleteEvaluationId, setDeleteEvaluationId] = useState('');
    const [isFirstRenderDone, setFirstRenderDone] = useState(false);

    const { debounce } = useDebounce(1500);
    const navigate = useNavigate();

    let totalRecords = 0;
    const [pageNumber, setPageNumber] = useState(1);
    const [statusType, setStatusType] = useState('');
    const [searchText, setSearchText] = useState('');

    const handlePageChange = (page: number) => {
        if (page !== pageNumber) {
            setPageNumber(page);
        }
    };

    const handleStatusChange = (status: string) => {
        setStatusType(status);
    };

    const handleSearchTextChange = (text: string) => {
        setSearchText(text);
    };

    useEffect(() => {
        if (isFirstRenderDone) {
            getAllEvaluations({
                page: pageNumber,
                search: searchText,
                evaluation_status: statusType === 'All' ? '' : statusType,
            });
        }
    }, [pageNumber]);

    useEffect(() => {
        if (isFirstRenderDone) {
            getAllEvaluations({
                page: 1,
                search: searchText,
                evaluation_status: statusType === 'All' ? '' : statusType,
            });
        }
    }, [statusType, searchText]);

    useEffect(() => {
        getAllEvaluations({});
        setFirstRenderDone(true);
    }, []);

    function handleDelete(id: string) {
        SetOpenConfirmBox(true);
        setDeleteEvaluationId(id);
    }

    useEffect(() => {
        if (!deleteEvaluationReducer.loading && deleteEvaluationReducer.success) {
            getAllEvaluations({});
        }
    }, [deleteEvaluationReducer.loading, deleteEvaluationReducer.success]);

    const evaluationDetailInfo = useSelector((state: RootStoreType) => {
        return state.allEvaluationReducers.evaluationDetailReducer.evaluationDetail
            ?.results[0];
    });

    const companyList: AccountDetails[] | undefined = useSelector(
        (state: RootStoreType) => {
            return (
                state.allClientReducer.clientListReducer.accountListResponse?.results ||
                []
            );
        },
    );

    const evaluationDetails = useSelector((state: RootStoreType) => {
        totalRecords = state.allEvaluationReducers.postEvaluationListReducer
            .evaluationResponse
            ? state.allEvaluationReducers.postEvaluationListReducer.evaluationResponse
                ?.count
            : 0;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return state.allEvaluationReducers.postEvaluationListReducer
            .evaluationResponse?.results;
    });

    return (
        <Box sx={{ maxWidth: '1540px' }}>
            {error || loading || deleteEvaluationReducer.loading ? (
                <CircularProgress sx={{ margin: '20vh 48%' }} />
            ) : (
                <>
                    <EvaluationHeader
                        changePage={handlePageChange}
                        changeStatus={handleStatusChange}
                        changeSearchText={handleSearchTextChange}
                        totalRecords={totalRecords}
                        currentSearchText={searchText}
                    />
                    {openConfirmBox && (
                        <ConfirmationBox
                            doOpen={openConfirmBox}
                            deleteRecord={() => {
                                deleteEvaluationReducer.deleteEvaluationById(
                                    deleteEvaluationId,
                                );
                            }}
                            doClose={() => {
                                SetOpenConfirmBox(false);
                            }}
                            confirmationMessage="Respondents will not be able to submit responses after it has been deleted."
                        />
                    )}
                    {evaluationDetails && evaluationDetails.length > 0 ? (
                        evaluationDetails.map((evaluation) => {
                            return (
                                <Box
                                    key={evaluation.id}
                                    sx={{ cursor: 'pointer', paddingBottom: '20px' }}
                                    onClick={() => {
                                        navigate('/evaluation-assessment-detail/' + evaluation.id);
                                    }}
                                >
                                    <EvaluationCard
                                        evaluationDetails={evaluation}
                                        deleteEvaluation={handleDelete}
                                    />
                                </Box>
                            );
                        })
                    ) : (
                        <Box
                            sx={{ margin: '25px 35%', fontWeight: '600', color: '#A3A3A3' }}
                        >
                            {'No Evaluations available'}
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default LegacyEvaluationList;
