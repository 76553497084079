import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import {
  EvaluationListResponse,
  EvaluationListPayload,
  PostEvaluationListReducer,
} from '../type';
import { RequestStatus } from '../../../types/request';

const initialState: PostEvaluationListReducer = {
  status: RequestStatus.Init,
  evaluationResponse: null,
};

const postEvaluationListSlice = createSlice({
  name: '/postEvaluationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postEvaluationList.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postEvaluationList.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.evaluationResponse = action.payload;
    });
    builder.addCase(postEvaluationList.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const postEvaluationList = createAsyncThunk<
  EvaluationListResponse,
  EvaluationListPayload
>(
  '/getEvaluations',
  async (requestPayload: EvaluationListPayload, thunkAPI) => {
    try {
      const response: AxiosResponse<EvaluationListResponse, void> =
        await getEvaluations(requestPayload);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const getEvaluations = async (
  requestPayload: EvaluationListPayload,
): Promise<AxiosResponse<EvaluationListResponse>> => {
  return await axiosInstance.post(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `${EndPoints.GET_EVALUATION_LIST}?page=${
      requestPayload.page ? requestPayload.page : 1
    }`,
    requestPayload,
  );
};

export default postEvaluationListSlice.reducer;
