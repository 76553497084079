import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import {
  getEvaluationResponseBreakdown,
  EvaluationResponseBreakdownPayload,
} from '../reducers/postEvaluationResponseBreakdown';

const usePostEvaluationResponseBreakdown = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const postResponseBreakdown = async (
    requestPayload: EvaluationResponseBreakdownPayload,
  ) => {
    try {
      const resultAction = await dispatch(getEvaluationResponseBreakdown(requestPayload));
      if (getEvaluationResponseBreakdown.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, postResponseBreakdown };
};

export default usePostEvaluationResponseBreakdown;
