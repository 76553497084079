import React from 'react';

import { Modal, Button, Typography } from "@mui/material";

const IntakeFormFailureModal = ({ open, onClose, onRetry, errorMessage }: {
  open: boolean;
  onClose: () => void;
  onRetry: () => void;
  errorMessage: string[] | null;
}) => (
  <Modal open={open} onClose={onClose}>
    <div style={{ padding: "20px", backgroundColor: "white", margin: "100px auto", width: "400px", borderRadius: "8px" }}>
      <Typography variant="h6">Uh oh... something went wrong :</Typography>
      {errorMessage && errorMessage.length > 0 ? (
        <Typography sx={{marginTop: "3%"}}>
          {errorMessage.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </Typography>
      ) : (
        <Typography sx={{marginTop: "3%"}}>No errors to display.</Typography>
      )}
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
        <Button variant="outlined" onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={onRetry}>Retry</Button>
      </div>
    </div>
  </Modal>
);

export default IntakeFormFailureModal;
