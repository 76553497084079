import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RequestStatus } from '../../../types/request';
import { storeKey } from '../constants';
import { AccountListResponse, GetAllClientsReducer } from '../types';
import axiosInstance, { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: GetAllClientsReducer = {
  status: RequestStatus.Init,
  accountListResponse: null,
};

export type ClientListParams = {
  search?: string;
  page?: number;
  status?: string;
};

const getClientListSlice = createSlice({
  name: 'getClientList',
  initialState,
  reducers: {
    clearClientList: (state) => {
      state.accountListResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientList.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getClientList.fulfilled, (state, action) => {
      state.accountListResponse = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(getClientList.rejected, (state) => {
      state.status = RequestStatus.Error;
    });

    builder.addCase(publicGetClientList.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(publicGetClientList.fulfilled, (state, action) => {
      state.accountListResponse = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(publicGetClientList.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const publicGetClientList = createAsyncThunk<
  AccountListResponse | null,
  ClientListParams
>(`${storeKey}/publicGetClientList`, async (params: ClientListParams, thunkAPI) => {
  try {
    const response: AxiosResponse<AccountListResponse> = await publicGetAllClients(
      params,
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const publicGetAllClients = async (
  params: ClientListParams,
): Promise<AxiosResponse<AccountListResponse>> => {
  return await axiosV2Instance.get('public/account', {
    params,
  });
};

export const getClientList = createAsyncThunk<
  AccountListResponse | null,
  ClientListParams
>(`${storeKey}/getClientList`, async (params: ClientListParams, thunkAPI) => {
  try {
    const response: AxiosResponse<AccountListResponse> = await getAllClients(
      params,
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const getAllClients = async (
  params: ClientListParams,
): Promise<AxiosResponse<AccountListResponse>> => {
  return await axiosInstance.get(EndPoints.GET_CLIENT_LIST, {
    params,
  });
};

export const { clearClientList } = getClientListSlice.actions;
export const reducer = getClientListSlice.reducer;
