import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getNaicsCodeInfo } from '../reducers/getNaicsCodes';

interface actionStateProps {
  loading: boolean;
  error: unknown;
  getNaicsCodes: () => void;
}

const useGetNaicsCodes = (): actionStateProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const getNaicsCodes = useCallback(async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(getNaicsCodeInfo());
      if (getNaicsCodeInfo.fulfilled.match(resultAction)) {
        setLoading(false);
      } else {
        setLoading(false);
        setError(resultAction.payload);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [dispatch]);

  return { loading, error, getNaicsCodes };
};

export default useGetNaicsCodes;
