import React from 'react';

function MapPinIcon({ width = 16, height = 16, fill = '#1C1C1C' }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46448 2.96448C5.40216 2.02679 6.67389 1.5 8 1.5C9.32611 1.5 10.5978 2.02679 11.5355 2.96448C12.4732 3.90216 13 5.17389 13 6.5C13 11 8 14.5 8 14.5C8 14.5 3 11 3 6.5C3 5.17389 3.52679 3.90216 4.46448 2.96448ZM6.88885 8.16296C7.21777 8.38269 7.60443 8.5 8 8.5C8.53046 8.5 9.03912 8.28931 9.41418 7.91418C9.78931 7.53912 10 7.03046 10 6.5C10 6.10443 9.88269 5.71777 9.66296 5.38885C9.44318 5.05994 9.1308 4.80359 8.76538 4.65222C8.3999 4.50085 7.9978 4.46124 7.6098 4.53845C7.22186 4.6156 6.86548 4.80609 6.58582 5.08582C6.30609 5.36548 6.1156 5.72186 6.03845 6.1098C5.96124 6.4978 6.00085 6.8999 6.15222 7.26538C6.30359 7.6308 6.55994 7.94318 6.88885 8.16296Z"
        fill="#1C1C1C"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C8 4 9.03553 4 9.76777 4.73223C9.76777 4.73223 10.5 5.46447 10.5 6.5C10.5 6.5 10.5 7.53553 9.76777 8.26777C9.76777 8.26777 9.03553 9 8 9C8 9 6.96447 9 6.23223 8.26777C6.23223 8.26777 5.5 7.53553 5.5 6.5C5.5 6.5 5.5 5.46447 6.23223 4.73223C6.23223 4.73223 6.96447 4 8 4ZM8 5C8 5 7.37868 5 6.93934 5.43934C6.93934 5.43934 6.5 5.87868 6.5 6.5C6.5 6.5 6.5 7.12132 6.93934 7.56066C6.93934 7.56066 7.37868 8 8 8C8 8 8.62132 8 9.06066 7.56066C9.06066 7.56066 9.5 7.12132 9.5 6.5C9.5 6.5 9.5 5.87868 9.06066 5.43934C9.06066 5.43934 8.62132 5 8 5Z"
        fill="#1C1C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0813 4.39524C13.0813 4.39524 13.5 5.40598 13.5 6.5C13.5 6.5 13.5 9.49949 10.8773 12.5156C10.8773 12.5156 10.0713 13.4425 9.10507 14.2638C9.10507 14.2638 8.61845 14.6774 8.28673 14.9096C8.11457 15.0301 7.88543 15.0301 7.71327 14.9096C7.71327 14.9096 7.38155 14.6774 6.89493 14.2638C6.89493 14.2638 5.92869 13.4425 5.1227 12.5156C5.1227 12.5156 2.5 9.49949 2.5 6.5C2.5 6.5 2.5 5.40598 2.91866 4.39524C2.91866 4.39524 3.33733 3.3845 4.11091 2.61091C4.11091 2.61091 4.8845 1.83733 5.89524 1.41866C5.89524 1.41866 6.90598 1 8 1C8 1 9.09402 1 10.1048 1.41866C10.1048 1.41866 11.1155 1.83732 11.8891 2.61091C11.8891 2.61091 12.6627 3.3845 13.0813 4.39524ZM12.5 6.5C12.5 6.5 12.5 5.6049 12.1575 4.77792C12.1575 4.77792 11.8149 3.95096 11.182 3.31802C11.182 3.31802 10.549 2.68508 9.72207 2.34254C9.72207 2.34254 8.8951 2 8 2C8 2 7.10489 2 6.27792 2.34254C6.27792 2.34254 5.45095 2.68508 4.81802 3.31802C4.81802 3.31802 4.18508 3.95095 3.84254 4.77792C3.84254 4.77792 3.5 5.60489 3.5 6.5C3.5 6.5 3.5 9.12551 5.8773 11.8594C5.8773 11.8594 6.6338 12.7294 7.54257 13.5018C7.54257 13.5018 7.79623 13.7175 8 13.8767C8 13.8767 8.20377 13.7175 8.45743 13.5018C8.45743 13.5018 9.3662 12.7294 10.1227 11.8594C10.1227 11.8594 12.5 9.12551 12.5 6.5Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default MapPinIcon;
