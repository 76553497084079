import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { UserSummary } from '../type';




export interface UserSummaryInitialState {
  status: string;
  userSummary: UserSummary | null;
}

const initialState: UserSummaryInitialState = {
  status: '',
  userSummary: null
};


export const getUserSummary = createAsyncThunk<UserSummary>(
  'UserSummary',
  async () => {
    const response: AxiosResponse<UserSummary> = await axiosInstance.get(
      `${EndPoints.GET_USER_SUMMARY}`,
    );
    return response.data;
  },
);

const getUserSummarySlice = createSlice({
  name: 'userSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserSummary.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(
      getUserSummary.fulfilled,
      (state, action: PayloadAction<UserSummary>) => {
        state.userSummary = action.payload;
        state.status = 'done';
      },
    );
    builder.addCase(getUserSummary.rejected, (state) => {
      state.status = 'error';
    });
  },
});

export default getUserSummarySlice.reducer;
