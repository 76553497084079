import React from 'react';

export const MobileIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 3.125C4.375 3.125 4.375 2.34835 4.92417 1.79917C4.92417 1.79917 5.47335 1.25 6.25 1.25H13.75C13.75 1.25 14.5267 1.25 15.0758 1.79917C15.0758 1.79917 15.625 2.34835 15.625 3.125V16.875C15.625 16.875 15.625 17.6517 15.0758 18.2008C15.0758 18.2008 14.5266 18.75 13.75 18.75H6.25C6.25 18.75 5.47335 18.75 4.92417 18.2008C4.92417 18.2008 4.375 17.6517 4.375 16.875V3.125ZM5.625 3.125L5.625 16.875C5.625 16.875 5.625 17.1339 5.80806 17.3169C5.80806 17.3169 5.99112 17.5 6.25 17.5H13.75C13.75 17.5 14.0089 17.5 14.1919 17.3169C14.1919 17.3169 14.375 17.1339 14.375 16.875V3.125C14.375 3.125 14.375 2.86612 14.1919 2.68306C14.1919 2.68306 14.0089 2.5 13.75 2.5L6.25 2.5C6.25 2.5 5.99112 2.5 5.80806 2.68306C5.80806 2.68306 5.625 2.86612 5.625 3.125Z"
        fill="#1C1C1C"
      />
      <path
        d="M5 5H15C15.3452 5 15.625 4.72018 15.625 4.375C15.625 4.02982 15.3452 3.75 15 3.75H5C4.65482 3.75 4.375 4.02982 4.375 4.375C4.375 4.72018 4.65482 5 5 5Z"
        fill="#1C1C1C"
      />
      <path
        d="M5 16.25H15C15.3452 16.25 15.625 15.9702 15.625 15.625C15.625 15.2798 15.3452 15 15 15H5C4.65482 15 4.375 15.2798 4.375 15.625C4.375 15.9702 4.65482 16.25 5 16.25Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export const EnvelopeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 4.375L10 11.25L2.5 4.375H17.5Z"
        fill="#1C1C1C"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 15V4.375C1.875 4.02982 2.15482 3.75 2.5 3.75H17.5C17.8452 3.75 18.125 4.02982 18.125 4.375V15C18.125 15 18.125 15.5178 17.7589 15.8839C17.7589 15.8839 17.3928 16.25 16.875 16.25H3.125C3.125 16.25 2.60723 16.25 2.24112 15.8839C2.24112 15.8839 1.875 15.5178 1.875 15ZM3.125 15H16.875V5H3.125V15Z"
        fill="#1C1C1C"
      />
      <path
        d="M2.92233 3.91428C2.80706 3.80862 2.65637 3.75 2.5 3.75C2.49095 3.75 2.4819 3.7502 2.47285 3.75059C2.30725 3.75779 2.15129 3.83048 2.03928 3.95267C1.93362 4.06794 1.875 4.21863 1.875 4.375C1.875 4.38405 1.8752 4.3931 1.87559 4.40215C1.88279 4.56775 1.95548 4.72371 2.07767 4.83572L9.57767 11.7107C9.81662 11.9298 10.1834 11.9298 10.4223 11.7107L17.9219 4.8361L17.9223 4.83572C18.0515 4.71734 18.125 4.55019 18.125 4.375L18.125 4.3698C18.1237 4.21523 18.0652 4.06662 17.9607 3.95267C17.8487 3.83048 17.6928 3.75779 17.5271 3.75059C17.5181 3.7502 17.5091 3.75 17.5 3.75L17.4971 3.75001C17.3417 3.75072 17.1922 3.80929 17.0777 3.91428L17.0773 3.91466L10 10.4021L2.92233 3.91428Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export const ShareIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 15.625C16.25 17.0057 15.1307 18.125 13.75 18.125C12.3693 18.125 11.25 17.0057 11.25 15.625C11.25 14.2443 12.3693 13.125 13.75 13.125C15.1307 13.125 16.25 14.2443 16.25 15.625Z"
        fill="#1C1C1C"
      />
      <path
        d="M16.25 4.375C16.25 5.75573 15.1307 6.875 13.75 6.875C12.3693 6.875 11.25 5.75573 11.25 4.375C11.25 2.99427 12.3693 1.875 13.75 1.875C15.1307 1.875 16.25 2.99427 16.25 4.375Z"
        fill="#1C1C1C"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6.875C5 6.875 6.29442 6.875 7.20971 7.79029C7.20971 7.79029 8.125 8.70558 8.125 10C8.125 10 8.125 11.2944 7.20971 12.2097C7.20971 12.2097 6.29442 13.125 5 13.125C5 13.125 3.70558 13.125 2.79029 12.2097C2.79029 12.2097 1.875 11.2944 1.875 10C1.875 10 1.875 8.70558 2.79029 7.79029C2.79029 7.79029 3.70558 6.875 5 6.875ZM5 8.125C5 8.125 4.22335 8.125 3.67418 8.67417C3.67418 8.67417 3.125 9.22335 3.125 10C3.125 10 3.125 10.7767 3.67418 11.3258C3.67418 11.3258 4.22335 11.875 5 11.875C5 11.875 5.77665 11.875 6.32583 11.3258C6.32583 11.3258 6.875 10.7767 6.875 10C6.875 10 6.875 9.22335 6.32583 8.67417C6.32583 8.67417 5.77665 8.125 5 8.125Z"
        fill="#1C1C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 12.5C13.75 12.5 15.0444 12.5 15.9597 13.4153C15.9597 13.4153 16.875 14.3306 16.875 15.625C16.875 15.625 16.875 16.9194 15.9597 17.8347C15.9597 17.8347 15.0444 18.75 13.75 18.75C13.75 18.75 12.4556 18.75 11.5403 17.8347C11.5403 17.8347 10.625 16.9194 10.625 15.625C10.625 15.625 10.625 14.3306 11.5403 13.4153C11.5403 13.4153 12.4556 12.5 13.75 12.5ZM13.75 13.75C13.75 13.75 12.9733 13.75 12.4242 14.2992C12.4242 14.2992 11.875 14.8483 11.875 15.625C11.875 15.625 11.875 16.4017 12.4242 16.9508C12.4242 16.9508 12.9733 17.5 13.75 17.5C13.75 17.5 14.5267 17.5 15.0758 16.9508C15.0758 16.9508 15.625 16.4017 15.625 15.625C15.625 15.625 15.625 14.8483 15.0758 14.2992C15.0758 14.2992 14.5267 13.75 13.75 13.75Z"
        fill="#1C1C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 1.25C13.75 1.25 15.0444 1.25 15.9597 2.16529C15.9597 2.16529 16.875 3.08058 16.875 4.375C16.875 4.375 16.875 5.66942 15.9597 6.58471C15.9597 6.58471 15.0444 7.5 13.75 7.5C13.75 7.5 12.4556 7.5 11.5403 6.58471C11.5403 6.58471 10.625 5.66942 10.625 4.375C10.625 4.375 10.625 3.08058 11.5403 2.16529C11.5403 2.16529 12.4556 1.25 13.75 1.25ZM13.75 2.5C13.75 2.5 12.9733 2.5 12.4242 3.04917C12.4242 3.04917 11.875 3.59835 11.875 4.375C11.875 4.375 11.875 5.15165 12.4242 5.70083C12.4242 5.70083 12.9733 6.25 13.75 6.25C13.75 6.25 14.5267 6.25 15.0758 5.70083C15.0758 5.70083 15.625 5.15165 15.625 4.375C15.625 4.375 15.625 3.59835 15.0758 3.04917C15.0758 3.04917 14.5267 2.5 13.75 2.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M11.9863 6.25236L11.9904 6.24968C12.167 6.13425 12.2734 5.93752 12.2734 5.72656C12.2734 5.60675 12.239 5.48947 12.1742 5.38868L12.1716 5.38456C12.0818 5.24724 11.9416 5.15073 11.7813 5.11585L11.7767 5.11486C11.7345 5.10602 11.6915 5.10156 11.6484 5.10156C11.5286 5.10156 11.4113 5.136 11.3106 5.20077L6.76388 8.12251L6.76368 8.12264C6.62423 8.21225 6.52609 8.35359 6.49085 8.51556C6.48135 8.55922 6.47656 8.60376 6.47656 8.64844L6.47684 8.66691C6.48019 8.78039 6.51439 8.89081 6.57577 8.98632C6.69076 9.16526 6.88886 9.27344 7.10156 9.27344C7.11057 9.27344 7.11957 9.27324 7.12856 9.27285C7.23909 9.26807 7.34637 9.23404 7.43944 9.17423L7.43965 9.1741L11.9863 6.25236Z"
        fill="#1C1C1C"
      />
      <path
        d="M11.3103 14.7991L11.3106 14.7992C11.4113 14.864 11.5286 14.8984 11.6484 14.8984L11.6534 14.8984C11.8643 14.8968 12.0602 14.7888 12.1742 14.6113C12.239 14.5105 12.2734 14.3932 12.2734 14.2734L12.2734 14.2685C12.2718 14.0576 12.1638 13.8617 11.9863 13.7476L11.986 13.7475L7.43944 10.8258C7.33865 10.761 7.22137 10.7266 7.10156 10.7266L7.09665 10.7266C6.8857 10.7282 6.68981 10.8362 6.57577 11.0137C6.511 11.1145 6.47656 11.2318 6.47656 11.3516L6.47658 11.3565C6.47824 11.5674 6.58621 11.7633 6.76368 11.8774L11.3103 14.7991Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export const PrinterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4609 6.25H3.53912C2.79694 6.25 2.1875 6.81252 2.1875 7.5V13.75H5V11.875H15V13.75H17.8125V7.5C17.8125 6.81252 17.2031 6.25 16.4609 6.25Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 6.25V3.125C4.375 2.77982 4.65482 2.5 5 2.5H15C15.3452 2.5 15.625 2.77982 15.625 3.125V6.25C15.625 6.59518 15.3452 6.875 15 6.875H5C4.65482 6.875 4.375 6.59518 4.375 6.25ZM14.375 5.625H5.625V3.75H14.375V5.625Z"
        fill="#1C1C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 17.1875V11.875C4.375 11.5298 4.65482 11.25 5 11.25H15C15.3452 11.25 15.625 11.5298 15.625 11.875V17.1875C15.625 17.5327 15.3452 17.8125 15 17.8125H5C4.65482 17.8125 4.375 17.5327 4.375 17.1875ZM14.375 16.5625H5.625V12.5H14.375V16.5625Z"
        fill="#1C1C1C"
      />
      <path
        d="M17.1875 13.125H15C14.6548 13.125 14.375 13.4048 14.375 13.75C14.375 14.0952 14.6548 14.375 15 14.375H17.8125C18.1577 14.375 18.4375 14.0952 18.4375 13.75V7.5C18.4375 6.71116 17.8393 6.15822 17.8393 6.15822C17.2624 5.625 16.4609 5.625 16.4609 5.625L3.53906 5.625C2.73762 5.625 2.16073 6.15822 2.16073 6.15822C1.5625 6.71116 1.5625 7.5 1.5625 7.5V13.75C1.5625 14.0952 1.84232 14.375 2.1875 14.375H5C5.34518 14.375 5.625 14.0952 5.625 13.75C5.625 13.4048 5.34518 13.125 5 13.125H2.8125V7.5C2.8125 7.25796 3.00919 7.07616 3.00919 7.07616C3.22682 6.875 3.53906 6.875 3.53906 6.875L16.4609 6.875C16.7732 6.875 16.9908 7.07616 16.9908 7.07616C17.1875 7.25796 17.1875 7.5 17.1875 7.5V13.125Z"
        fill="#1C1C1C"
      />
      <path
        d="M15.625 9.0625C15.625 9.58023 15.2052 10 14.6875 10C14.1698 10 13.75 9.58023 13.75 9.0625C13.75 8.54477 14.1698 8.125 14.6875 8.125C15.2052 8.125 15.625 8.54477 15.625 9.0625Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="DownloadSimple-r">
        <path
          id="Vector"
          d="M16.5112 10.9966L16.5115 10.9963C16.6518 10.8554 16.7304 10.6645 16.73 10.4656C16.7296 10.2667 16.6502 10.076 16.5093 9.93569C16.3683 9.79532 16.1774 9.7167 15.9785 9.71711C15.7796 9.71752 15.589 9.79693 15.4486 9.93787L15.4483 9.93815L12.0485 13.352L8.63438 9.95199C8.49335 9.81153 8.30243 9.73291 8.10351 9.73332C7.9046 9.73373 7.714 9.81314 7.57364 9.95408C7.43327 10.095 7.35465 10.2859 7.35506 10.4849C7.35547 10.6838 7.43488 10.8744 7.57582 11.0147L11.5214 14.9441C11.6624 15.0845 11.8533 15.1631 12.0522 15.1627C12.2511 15.1623 12.4417 15.0829 12.5821 14.9419L16.5112 10.9966Z"
          fill="#1C1C1C"
        />
        <path
          id="Vector_2"
          d="M11.2789 3.91455L11.3005 14.4145C11.3014 14.8287 11.6378 15.1638 12.052 15.163C12.4663 15.1621 12.8014 14.8257 12.8005 14.4114L12.7789 3.91146C12.778 3.49725 12.4416 3.16215 12.0273 3.16301C11.6131 3.16386 11.278 3.50034 11.2789 3.91455Z"
          fill="#1C1C1C"
        />
        <path
          id="Vector_3"
          d="M4.56118 19.6781L4.55037 14.4281C4.54952 14.0139 4.21304 13.6788 3.79883 13.6796C3.38461 13.6805 3.04952 14.0169 3.05037 14.4312L3.06118 19.6811C3.06246 20.3025 3.5027 20.7409 3.5027 20.7409C3.94294 21.1793 4.56426 21.178 4.56426 21.178L19.5642 21.1472C20.1856 21.1459 20.624 20.7057 20.624 20.7057C21.0624 20.2654 21.0611 19.6441 21.0611 19.6441L21.0503 14.3941C21.0495 13.9799 20.713 13.6448 20.2988 13.6456C19.8846 13.6465 19.5495 13.983 19.5503 14.3972L19.5611 19.6472L4.56118 19.6781Z"
          fill="#1C1C1C"
        />
      </g>
    </svg>
  );
};
