import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { AxiosResponse } from 'axios';
import { EvaluationAssessorScoreData, EvaluationAssessorScoreRequest } from '../type';


export interface EvaluationAssessorScoreReducer {
  status: RequestStatus;
  evaluationAssessorScoreResponse: EvaluationAssessorScoreData[] | null;
}

const initialState: EvaluationAssessorScoreReducer = {
  status: RequestStatus.Init,
  evaluationAssessorScoreResponse: null,
};

const postEvaluationAssessorScoresSlice = createSlice({
  name: 'evaluation/postEvaluationAssessorScores',
  initialState: initialState,
  reducers: {
    resetEvalutaionAssessorScoreState: (state) => {
      state.evaluationAssessorScoreResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postEvaluationAssessorScore.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postEvaluationAssessorScore.fulfilled, (state, action) => {
      state.evaluationAssessorScoreResponse = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(postEvaluationAssessorScore.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const postEvaluationAssessorScore = createAsyncThunk<
  EvaluationAssessorScoreData[],
  EvaluationAssessorScoreRequest
>(
  'postEvaluationAssessorScore',
  async (
    evaluationAssessorScorePayload: EvaluationAssessorScoreRequest,
    { rejectWithValue },
  ) => {
    try {
      const url = `/evaluation/${evaluationAssessorScorePayload?.assessor_score_data[0].evaluation_id}/assessorScores`
      const result: AxiosResponse<EvaluationAssessorScoreData[]> =
        await axiosInstance.put<EvaluationAssessorScoreData[]>(
          url,
          evaluationAssessorScorePayload
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);


export const { resetEvalutaionAssessorScoreState } =
  postEvaluationAssessorScoresSlice.actions;

export default postEvaluationAssessorScoresSlice.reducer;
