import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ActivateAccountPayload } from '../Interfaces/Interfaces';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import { RequestStatus } from '../../../types/request';
import { toast } from 'react-toastify';

export type Status = {
  status: RequestStatus;
};

const initialState = {
  status: RequestStatus.Init,
};

const activateUserSlice = createSlice({
  name: 'activateUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postActivateUser.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postActivateUser.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      toast.success('Password reset was successful.');
    });
    builder.addCase(postActivateUser.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      const errorMessage =
        (
          (action.payload as AxiosError).response?.data as {
            error: string;
          }
        ).error ?? '';
      toast.error(errorMessage);
    });
  },
});



export const postActivateUser = createAsyncThunk<
  string,
  ActivateAccountPayload
>(
  '/activate-user',
  async (activateUser: ActivateAccountPayload, { rejectWithValue }) => {
    try {
      const result: AxiosResponse<string> = await postActivateUserData(
        activateUser,
      );
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const postActivateUserData = async (
  activateUser: ActivateAccountPayload,
): Promise<AxiosResponse<string>> => {
  return await axiosInstance.post(
    EndPoints.POST_ACTIVATE_PASSWORD,
    activateUser,
  );
};

export default activateUserSlice.reducer;
