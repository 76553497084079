import React from 'react';

function CloseIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Close">
        <path
          id="Vector"
          d="M6.46538 5.93452C6.2213 5.69045 5.82557 5.69045 5.5815 5.93452C5.33742 6.1786 5.33742 6.57433 5.5815 6.81841L9.11705 10.354L5.58154 13.8895C5.33747 14.1336 5.33747 14.5293 5.58154 14.7734C5.82562 15.0174 6.22135 15.0174 6.46543 14.7734L10.0009 11.2378L13.5364 14.7734C13.7805 15.0174 14.1763 15.0174 14.4203 14.7734C14.6644 14.5293 14.6644 14.1336 14.4203 13.8895L10.8848 10.354L14.4204 6.81841C14.6645 6.57433 14.6645 6.1786 14.4204 5.93452C14.1763 5.69045 13.7806 5.69045 13.5365 5.93452L10.0009 9.47008L6.46538 5.93452Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default CloseIcon;
