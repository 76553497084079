import React from 'react';
import { EvaluationDetails, EvaluationStatus } from '../../../type';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { MoreHorizRounded } from '@mui/icons-material';
import { FieldName, FieldValue } from '../../../../Assessments/AssessmentList/AssessmentList.style';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { resetState } from '../../../../Accounts/reducers/getAccountSetting';
import { useDispatch } from 'react-redux';

export default function EvaluationCard(prop: {
  evaluationDetails: EvaluationDetails;
  deleteEvaluation: (id: string) => void;
}) {
  const { evaluationDetails, deleteEvaluation } = prop;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(null);
  };

  return (
    <Grid
      container
      columnSpacing={2}
      sx={{
        borderRadius: '10px',
        background: 'var(--white-100, #FFF)',
        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
        minHeight: '200px',
        margin: '10px 0',
        padding: '20px',
        width: '100%',
      }}
    >
      <Grid
        item
        sx={{
          marginBottom: { xs: '20px' },
        }}
        sm={2.5}
        xs={8}
        order={{ xs: 1, sm: 1 }}
      >
        <Typography
          sx={{
            color: '#000',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
          }}
        >
          {evaluationDetails.account_name}
        </Typography>
        <Typography
          sx={{
            color: '#B5B5C3',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          }}
        >
          {evaluationDetails.evaluation_name}
        </Typography>
      </Grid>

      <Grid item sm={9} xs={12} order={{ xs: 3, sm: 2 }}>
        <Grid
          container
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Grid
            item
            sm={4.5}
            xs={12}
            sx={{
              justifyContent: { xs: '', sm: 'space-between' },
              display: 'flex',
              flexDirection: { xs: 'row', sm: 'column' },
              minHeight: { xs: '0', sm: '160px' },
              marginBottom: { xs: '10px', sm: '' },
            }}
          >
            <Box sx={{ width: '48%' }}>
              <FieldName>Type</FieldName>
              <Tooltip title={evaluationDetails.evaluation_category}>
                <FieldValue>{evaluationDetails.evaluation_category}</FieldValue>
              </Tooltip>
            </Box>

            <Box sx={{ width: '48%' }}>
              <FieldName>Date</FieldName>
              <Tooltip
                title={moment(evaluationDetails.created_at).format(
                  'MMM DD, YYYY',
                )}
              >
                <FieldValue>
                  {moment(evaluationDetails.created_at).format('MMM DD, YYYY')}
                </FieldValue>
              </Tooltip>
            </Box>
          </Grid>

          <Grid
            item
            sm={4}
            xs={12}
            sx={{
              justifyContent: { xs: '', sm: 'space-between' },
              display: 'flex',
              flexDirection: { xs: 'row', sm: 'column' },
              minHeight: { xs: '0', sm: '160px' },
              marginBottom: { xs: '10px', sm: '' },
            }}
          >
            <Box sx={{ width: '48%' }}>
              <FieldName>Evaluation Id</FieldName>
              <Tooltip title={evaluationDetails.encoded_evaluation_id}>
                <FieldValue>
                  {evaluationDetails.encoded_evaluation_id}
                </FieldValue>
              </Tooltip>
            </Box>

            <Box sx={{ width: '48%' }}>
              <FieldName>Version</FieldName>
              <FieldValue sx={{ color: '#1228F2' }}>
                {evaluationDetails.evaluation_version
                  ? evaluationDetails.evaluation_version
                  : 'NA'}
              </FieldValue>
            </Box>
          </Grid>

          <Grid
            item
            sm={3.5}
            xs={12}
            sx={{
              justifyContent: { xs: '', sm: 'space-between' },
              display: 'flex',
              flexDirection: { xs: 'row', sm: 'column' },
              minHeight: { xs: '0', sm: '160px' },
              marginBottom: { xs: '10px', sm: '' },
            }}
          >
            <Box sx={{ width: '48%' }}>
              <FieldName>Close Date</FieldName>
              <Tooltip
                title={moment(
                  evaluationDetails.closed_date,
                  'YYYY-MM-DD',
                ).format('MMM DD, YYYY')}
              >
                <FieldValue>
                  {moment(evaluationDetails.closed_date, 'YYYY-MM-DD').format(
                    'MMM DD, YYYY',
                  )}
                </FieldValue>
              </Tooltip>
            </Box>

            <Box sx={{ width: '48%' }}>
              <EvaluationStatusChip
                {...evaluationDetails}
              ></EvaluationStatusChip>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: { sm: 'space-between', xs: 'flex-end' },
        }}
        item
        sm={0.5}
        xs={4}
        order={{ xs: 2, sm: 3 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: { sm: 'center', xs: 'flex-start' },
            gap: { sm: '0 36px', xs: '0 10px' },
          }}
        >
          {/*
          <Box component={'span'} sx={{ cursor: 'pointer' }}>
            <PrinterIcon />
          </Box> 
          <Box component={'span'} sx={{ cursor: 'pointer' }}>
            <ShareIcon />
          </Box>*/}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <IconButton
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            onClick={handleClick}
            aria-controls={open ? 'basic-menu' : undefined}
          >
            <MoreHorizRounded
              sx={{
                fontSize: 20,
                color: 'rgba(28, 28, 28, 0.20)',
                cursor: 'pointer',
              }}
            />
          </IconButton>
          <Menu
            sx={{
              '& .MuiPaper-root': {
                boxShadow: '0px 2px 3px 0px rgb(225 213 213 / 25%)',
                border: '1px solid #E0E0E0',
              },
            }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={(event) => {
                handleClose(event);
                navigate(`/evaluation-assessment-detail/${evaluationDetails.id}`);
              }}
            >
              View
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                dispatch(resetState());
                handleClose(event);
                navigate(`/edit-evaluation-assessment/${evaluationDetails.id}`);
              }}
            >
              Edit
            </MenuItem>

            <MenuItem
              onClick={(event) => {
                handleClose(event);
                deleteEvaluation(evaluationDetails.id);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      </Grid>
    </Grid>
  );
}

export const EvaluationStatusChip = ({ evaluation_status }) => {
  return (
    <span>
      {evaluation_status === EvaluationStatus.CLOSED ? (
        <Chip
          sx={{
            borderRadius: '6px',
            backgroundColor: 'rgba(0, 219, 153, 0.20)',
            color: '#000',
            fontWeight: '600',
            textAlign: 'center',
            minWidth: '75px',
          }}
          label={EvaluationStatus.CLOSED}
          color="success"
        />
      ) : evaluation_status === EvaluationStatus.RESULTS_REVIEW ? (
        <Chip
          sx={{
            borderRadius: '6px',
            backgroundColor: 'rgba(70, 78, 95, 0.40)',
            color: '#000',
            fontWeight: '600',
            textAlign: 'center',
            minWidth: '75px',
          }}
          label={EvaluationStatus.RESULTS_REVIEW}
          color="success"
        />
      ) : evaluation_status === EvaluationStatus.IN_PROGRESS ? (
        <Chip
          sx={{
            borderRadius: '6px',
            backgroundColor: 'rgba(240, 187, 0, 0.40)',
            color: '#000',
            fontWeight: '600',
            textAlign: 'center',
            minWidth: '75px',
          }}
          label={EvaluationStatus.IN_PROGRESS}
        />
      ) : (
        <Chip
          sx={{
            borderRadius: '6px',
            backgroundColor: 'rgba(18, 40, 242, 0.40)',
            color: '#000',
            fontWeight: '600',
            textAlign: 'center',
            minWidth: '75px',
          }}
          label={EvaluationStatus.DRAFT}
        />
      )}
    </span>
  );
};
