import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getEvaluationNotes } from '../reducers/getEvaluationNotes';

interface actionStateProps {
  loading: boolean;
  error: unknown;
  evaluationNotes: (evaluationId: string) => void;
}

const useGetEvaluationNotes = (): actionStateProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const evaluationNotes = async (
    evaluationId: string
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        getEvaluationNotes({
          evaluation_id: evaluationId
        }),
      );
      if (getEvaluationNotes.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }
  };

  return { loading, error, evaluationNotes };
};

export default useGetEvaluationNotes;
