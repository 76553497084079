import { useDispatch } from 'react-redux';
import { getEvaluationQuestions } from '../reducers/getEvaluationQuestions';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

interface useGetQuestionsProps {
  loading: boolean;
  error: AxiosError | undefined;
}

const useGetEvaluationQuestions = (
  questionId: string,
): useGetQuestionsProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();

  const getQuestions = async () => {
    const resultAction = await dispatch(
      getEvaluationQuestions(questionId),
    ).then((res) => {
      if (getEvaluationQuestions.fulfilled.match(res)) {
        setLoading(false);
      }
      if (getEvaluationQuestions.rejected.match(res)) {
        setLoading(false);
        setError(res.payload as AxiosError);
      }
    });
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return { error, loading };
};

export default useGetEvaluationQuestions;
