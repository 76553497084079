import React, { useState } from 'react';
import {
  FormControl,
  TextField,
} from '@mui/material';

export default function TextEntryInput({
  question,
  formData,
  setFormData,
}: {
  question?: any;
  options?: any;
  formData?: any;
  setFormData?: any;
}) {

  const [localValue, setLocalValue] = useState(formData[question.id] || '');

  const handleBlur = () => {
    // Update formData only when input loses focus (onBlur)
    setFormData({ ...formData, [question.id]: localValue });
  };

  const handleChange = (ev: string) => {
    setLocalValue(ev);
  };

  return (
    <FormControl sx={{ marginTop: '20px', marginLeft: '40px' }}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        multiline
        sx={{
          width: '800px',
          borderRadius: '8px',
          background: "#F7F7F7",
          border: "1px solid #6A6A6A"
        }}
        rows={4}
        placeholder='Type your answer here'
        value={localValue}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
      />
    </FormControl>
  );
}
