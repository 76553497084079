import React from 'react';
import { TextField, ThemeProvider, Typography } from '@mui/material';
import translation from '../../../locales/en/translation.json';
import { Form, FormContainer } from '../Authentication.style';
import useForgetPassword from '../hooks/useForgetPassword';
import {
  CustomLink,
  ThemeForm,
  ErrorMessage,
  CustomButton,
} from '../../../styles/Global.styles';
import Hero from '../../../components/Hero/Hero';
import { useForm } from 'react-hook-form';
import { LoginFormInputs } from '../Interfaces/Interfaces';
import { useFormTheme } from '../../../hooks/useFormTheme';

const ForgetPasswordForm = () => {
  const formTheme = useFormTheme();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormInputs>();
  const { postForgetPasswordEmailId } = useForgetPassword();

  const onSubmit = (data: { email: string }) => {
    postForgetPasswordEmailId(data.email);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <ThemeProvider theme={formTheme}>
          <Typography my={2} variant="h4" align="center">
            Forget Your Password
          </Typography>
          <div>
            Please enter your email address you’d like your reset information
            sent to
          </div>
          <ThemeForm>
            <TextField
              fullWidth
              variant="outlined"
              label={translation.email}
              placeholder={translation.email}
              {...register('email', {
                required: {
                  value: true,
                  message: translation.email_is_required,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: translation.invalid_email,
                },
              })}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message?.toString()}</ErrorMessage>
            )}
            <CustomButton type="submit" variant="contained" size="medium">
              {translation.reset}
            </CustomButton>
          </ThemeForm>
        </ThemeProvider>
        <CustomLink
          to={'/'}
          style={{
            textAlign: 'center',
          }}
        >
          {translation.back_to_signin}
        </CustomLink>
      </FormContainer>
    </Form>
  );
};

const ForgetPasswordComponent = () => {
  return <Hero component={<ForgetPasswordForm />} />;
};

export default ForgetPasswordComponent;
