import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomAddButton } from '../../../../../styles/Global.styles';
import { EvaluationStatus } from '../../../type';
import { SearchTextField } from '../../../../Assessments/AssessmentList/AssessmentList.style';
import { resetState } from '../../../../Accounts/reducers/getAccountSetting';
import { useDispatch } from 'react-redux';
import { useBranding } from '../../../../Branding/reducers/getBranding';

const EvaluationHeader = (props: {
  totalRecords: number;
  changePage: (pageNumber: number) => void;
  changeStatus: (StatusType: string) => void;
  changeSearchText: (searchText: string) => void;
  currentSearchText: string;
}) => {
  const [statusType, setStatusType] = React.useState('All');
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [searchText, setSearchText] = React.useState(props.currentSearchText);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const branding = useBranding();

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setStatusType(event.target.value);
    props.changeStatus(event.target.value);
  };

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    props.changeSearchText(event.target.value);
  };

  return (
    <>
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={6} sm={2} xl={1.2}>
          <Box>
            <FormControl fullWidth>
              <Select
                sx={{
                  color: '#1C1C1C',
                  fontWeight: 600,
                  lineHeight: '20px',
                  '& .MuiSelect-select': {
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiSelect-icon': {
                    color: 'lightgrey',
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusType}
                onChange={handleStatusChange}
              >
                <MenuItem value={'All'}>All {branding?.evaluation_property_name_translation ?? 'Assessments'}</MenuItem>
                <MenuItem value={EvaluationStatus.DRAFT}>
                  {EvaluationStatus.DRAFT}
                </MenuItem>
                <MenuItem value={EvaluationStatus.IN_PROGRESS}>
                  {EvaluationStatus.IN_PROGRESS}
                </MenuItem>
                <MenuItem value={EvaluationStatus.RESULTS_REVIEW}>
                  {EvaluationStatus.RESULTS_REVIEW}
                </MenuItem>
                <MenuItem value={EvaluationStatus.CLOSED}>
                  {EvaluationStatus.CLOSED}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <SearchTextField
              size={'small'}
              variant="outlined"
              sx={{ margin: '10px 0px' }}
              placeholder={'Search'}
              value={searchText}
              onChange={handleSearchTextChange}
            />
            <CustomAddButton
              sx={{ margin: '10px 30px' }}
              onClick={() => {
                dispatch(resetState());
                navigate('/create-evaluation-assessment');
              }}
            >
              {branding?.evaluation_property_name_translation ?? 'Assessment'} +
            </CustomAddButton>
          </Box>
        </Grid>
      </Grid >

      <Grid
        container
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Grid item xs={12} sm={8}>
          <Pagination
            sx={{
              float: 'right',
              borderRadius: '20px',
              background: 'var(--white-100, #FFF)',
              boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.03)',
              '& .MuiPaginationItem-root': {
                borderColor: 'primary.main',
                '&.Mui-selected': {
                  color: 'primary.main',
                  borderColor: 'red',
                  background: 'white',
                },
              },
              '&.MuiPagination-sizeSmall': {
                '.MuiPaginationItem-root': {
                  fontSize: '12px',
                  minWidth: '24px',
                },
              },
            }}
            onChange={(_, page) => {
              setCurrentPage(page);
              props.changePage(page);
            }}
            page={currentPage}
            count={Math.ceil(props.totalRecords / 10)}
            size="large"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EvaluationHeader;
