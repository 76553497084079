import React from 'react';

function HouseSimpleIcon({ width = 16, height = 16, fill = '#1C1C1C' }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3375 6.85016L8.33752 2.3064C8.2453 2.22205 8.12494 2.17529 8 2.17529C7.87506 2.17529 7.7547 2.22205 7.66248 2.3064L2.66248 6.85016C2.61218 6.89764 2.5719 6.95477 2.54401 7.01807C2.51605 7.08142 2.5011 7.14966 2.5 7.21887V13.0001C2.5 13.1328 2.55267 13.2599 2.64642 13.3537C2.74023 13.4474 2.86737 13.5001 3 13.5001H13C13.1326 13.5001 13.2598 13.4474 13.3536 13.3537C13.4473 13.2599 13.5 13.1328 13.5 13.0001V7.21887C13.4989 7.14966 13.4839 7.08142 13.456 7.01807C13.4281 6.95477 13.3878 6.89764 13.3375 6.85016V6.85016Z"
        fill="#1C1C1C"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6808 6.48662C13.6808 6.48662 13.8305 6.62802 13.9136 6.81646C13.9136 6.81646 13.9966 7.0049 13.9999 7.21079L14 13.0001C14 13.0001 14 13.4143 13.7071 13.7072C13.7071 13.7072 13.4142 14.0001 13 14.0001H3C3 14.0001 2.58579 14.0001 2.29289 13.7072C2.29289 13.7072 2 13.4143 2 13.0001V7.21888C2 7.21888 2.0034 7.0049 2.08644 6.81646C2.08644 6.81646 2.16948 6.62802 2.31919 6.48662L7.32623 1.93635C7.32623 1.93635 7.61158 1.67529 8 1.67529C8 1.67529 8.38842 1.67529 8.675 1.93748L13.6734 6.47978L13.6738 6.4801L13.6808 6.48662ZM12.9953 7.21475L8 2.67529L7.99985 2.67543L7.99877 2.67642L3.00581 7.21364C3.00581 7.21364 3.00305 7.21625 3.00152 7.21971C3.00152 7.21971 3.00081 7.22133 3.00043 7.22126C3.00043 7.22126 3 7.22118 3 7.21893V13.0001H13L13.0001 7.22697C13.0001 7.22697 13 7.22319 12.9985 7.21971C12.9985 7.21971 12.9973 7.21696 12.9953 7.21475Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default HouseSimpleIcon;
