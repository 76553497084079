import {
  FormControl,
  Button,
  styled,
  Typography,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';

export const CustomButton = styled(Button)(({ theme }) => ({
  alignSelf: 'stretch',
  borderRadius: '4px',
  fontWeight: 500,
  lineHeight: '40px',
  background: theme.palette.primary.main,
  textTransform: 'none',
  fontFamily: 'Inter',
  textDecoration: 'none',
  '&:hover': {
    background: theme.palette.primary.main,
  }
}));

export const CustomLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

export const ErrorMessage = styled(Typography)`
  color: '#ff0000';
  font-size: 12px;
  text-decoration: none;
  padding: 0;
`;

export const ThemeForm = styled(FormControl)`
  width: 100%;
  padding: 20px;
  flex-direction: column;
  gap: 12px;
`;

export const CustomAddButton = styled(Button)`
  display: inline-flex;
  text-decoration: none;
  text-transform: none;
  height: 28px;
  padding: var(--gap-4, 4px) var(--gap-8, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--gap-4, 4px);
  flex-shrink: 0;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  float: right;

  &:hover {
    background: rgba(10, 10, 10, 1);
  }
`;

export const CustomCheckBox = styled(Box)`
  border: 1px solid black;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const CommonTypography = styled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  line-height: normal;
`;

export const Typography12px600 = styled(CommonTypography)`
  font-size: 12px;
  font-weight: 600;
`;

export const Typography14px600 = styled(CommonTypography)`
  font-size: 12px;
  font-weight: 600;
`;

export const Typography14px500 = styled(CommonTypography)`
  font-size: 14px;
  font-weight: 500;
`;

export const Typography19px600 = styled(CommonTypography)`
  font-size: 19px;
  font-weight: 600;
`;

export const Typography18px500 = styled(CommonTypography)`
  font-size: 18px;
  font-weight: 500;
`;

export const Typography12px400 = styled(CommonTypography)`
  font-size: 12px;
  font-weight: 400;
`;

export const Typography13px400 = styled(CommonTypography)`
  font-size: 13px;
  font-weight: 400;
`;

export const Typography12px500 = styled(CommonTypography)`
  font-size: 12px;
  font-weight: 500;
`;

export const Typography14px400 = styled(CommonTypography)`
  font-size: 14px;
  font-weight: 400;
`;

export const Typography10px400 = styled(CommonTypography)`
  font-size: 10px;
  font-weight: 400;
`;
