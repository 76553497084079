import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { EvaluationTextAnswersResponse } from '../type';

export interface EvaluationAnswersPayload {
  evaluation_id: string;
  question_category_id: string;
}

export interface EvaluationTextAnswersReducer {
  status: RequestStatus;
  evaluationTextAnswersResponse: EvaluationTextAnswersResponse | null;
}

const initialState: EvaluationTextAnswersReducer = {
  status: RequestStatus.Init,
  evaluationTextAnswersResponse: null,
};

const postEvaluationTextAnswersSlice = createSlice({
  name: 'evaluation/postEvaluationTextAnswers',
  initialState,
  reducers: {
    resetEvalutaionTextAnswersState: (state) => {
      state.evaluationTextAnswersResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postGetEvaluationTextAnswers.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postGetEvaluationTextAnswers.fulfilled, (state, action) => {
      state.evaluationTextAnswersResponse = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(postGetEvaluationTextAnswers.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const postGetEvaluationTextAnswers = createAsyncThunk<
EvaluationTextAnswersResponse,
  EvaluationAnswersPayload
>(
  'postEvaluationTextAnswers',
  async (
    evaluationPayload: EvaluationAnswersPayload,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<EvaluationTextAnswersResponse> =
        await axiosInstance.post<EvaluationTextAnswersResponse>(
          EndPoints.POST_EVALUATION_RESULT_TEXT_ANSWERS,
          evaluationPayload,
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { resetEvalutaionTextAnswersState } =
  postEvaluationTextAnswersSlice.actions;

export default postEvaluationTextAnswersSlice.reducer;
