import React from 'react';
import { Box, Typography } from '@mui/material';
import { CategoryScoreQuestionDetails } from '../../../type';
import { Typography12px400 } from '../../../../../styles/Global.styles';

interface SurveySummaryProps {
    questionDetails: CategoryScoreQuestionDetails;
}

const TextResponseDisplay: React.FC<SurveySummaryProps> = ({
    questionDetails
}) => {
    const { free_responses } = questionDetails;

    return (
        <Box sx={{ margin: '20px 1.5em 0px 1.5em' }}>
            <Typography
                sx={{
                    color: '#053D58',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    fontWeight: 400,
                    lineHeight: '15px',
                    letterSpacing: '-0.36px',
                }}
            >
                All answers for this company:
            </Typography>
            <Box
                sx={{
                    maxHeight: '350px',
                    overflowY: 'auto',
                    backgroundColor: '#f0f5fb',
                    padding: '12px 16px',
                }}
            >
                {free_responses?.map((answer, index) => (
                    <React.Fragment key={index}>
                        <Typography12px400
                            sx={{
                                padding: '4px 8px',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                gap: '10px',
                                color: '#053D58',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'italic',
                                fontWeight: 400,
                                lineHeight: '15px',
                                letterSpacing: '-0.36px',

                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                marginBottom: '8px', // Space between text and line
                            }}
                        >
                            {answer}
                        </Typography12px400>
                        {index < free_responses.length - 1 && <hr style={{ border: '0.5px solid #ccc', marginBottom: '8px' }} />} {/* Add line only between answers */}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
};

export default TextResponseDisplay;
