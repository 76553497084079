import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export interface evaluationResultAnalysisRequest {
  params: { evaluation_id: string; question_category_id: string };
  payload: EvaluationResultPayload;
}

export interface EvaluationResultPayload {
  root_causes: string;
  strengths: string;
  possible_offerings?: string[];
  challenges_threats?: string[];
  go_forward_plan: string;
  offerings?: string;
  challenges?: string;
  key_priorities?: string;
  development_opportunities?: string;
  implementation_details_and_timing?: string;
}
const initialState = {
  status: RequestStatus.Init,
};

const putEvaluationResultAnalysisSlice = createSlice({
  name: 'evaluation/putEvaluationResultAnalysis',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(putEvaluationResultAnalysisThunk.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(putEvaluationResultAnalysisThunk.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      // toast.success('Evaluation Result Analysis Updated Successfully');
    });
    builder.addCase(
      putEvaluationResultAnalysisThunk.rejected,
      (state, action) => {
        state.status = RequestStatus.Error;
        const errorString = (action.payload as AxiosError).response?.data as {
          error: string;
        };
        toast.error(
          errorString.error || "Evaluation  Result Analysis Couldn't Update",
        );
      },
    );
  },
});

export const putEvaluationResultAnalysisThunk = createAsyncThunk<
  string,
  evaluationResultAnalysisRequest
>(
  'updateEvaluationResultAnalysis',
  async (
    evaluationResultRequest: evaluationResultAnalysisRequest,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<string> = await axiosInstance.put(
        `${EndPoints.PUT_EVALUATION_RESULT_ANALYSIS}`,
        evaluationResultRequest.payload,
        { params: evaluationResultRequest.params },
      );
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default putEvaluationResultAnalysisSlice.reducer;
