import { TextField, styled } from '@mui/material';

export const SearchTextField = styled(TextField)`
  background: '#F1F1F1';
  float: right;
  margin-top: 10px;

  & .MuiOutlinedInput-root {
    font-family: 'Poppins';
    font-size: 12px;
    max-height: 30px;
  }
`;
