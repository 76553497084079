import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getSupplierInfo } from '../reducers/getSuppliers';

interface actionStateProps {
  loading: boolean;
  error: unknown;
  getSuppliers: () => void;
}

const useGetSuppliers = (): actionStateProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const getSuppliers = useCallback(async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(getSupplierInfo());
      if (getSupplierInfo.fulfilled.match(resultAction)) {
        setLoading(false);
      } else {
        setLoading(false);
        setError(resultAction.payload);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [dispatch]);

  return { loading, error, getSuppliers };
};

export default useGetSuppliers;
