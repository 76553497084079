import React, { useState } from 'react';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { MoreHorizRounded } from '@mui/icons-material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AssessmentListDetail } from '../../type';

interface AssessmentCardProps {
  assessmentDetails: AssessmentListDetail;
  deleteAssessment: (id: number) => void;
}

export default function AssessmentCard(prop: {
  assessmentDetails: AssessmentListDetail;
  deleteAssessment: (id: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { assessmentDetails, deleteAssessment } = prop;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      columnSpacing={2}
      sx={{
        borderRadius: '10px',
        background: '#FFF',
        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
        minHeight: '200px',
        margin: '10px 0',
        padding: '20px',
        width: '100%',
      }}
    >
      <Grid item sm={2.5} xs={8} order={{ xs: 1, sm: 1 }} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
        <Typography
          sx={{
            color: '#000',
            fontWeight: 600,
            fontSize: '14px',
            marginBottom: '4px',
          }}
        >
          {assessmentDetails.name}
        </Typography>
        <Typography
          sx={{
            color: '#B5B5C3',
            fontSize: '14px',
          }}
        >
          {assessmentDetails.account.name}
        </Typography>
      </Grid>

      <Grid item sm={9} xs={12} order={{ xs: 3, sm: 2 }}>
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}
        >
          <Grid
            item
            sm={5}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="body2" sx={{ color: '#757575' }}>
                Type
              </Typography>
              <Tooltip
                title={
                  assessmentDetails.assessment_definition.name
                }
              >
                <Typography variant="body1">
                  {assessmentDetails.assessment_definition.name}
                </Typography>
              </Tooltip>
            </Box>

            <Box sx={{ marginTop: 'auto' }}>
              <Typography variant="body2" sx={{ color: '#757575' }}>
                Start Date
              </Typography>
              <Tooltip
                title={
                  assessmentDetails.start_date
                    ? moment(assessmentDetails.start_date).format('MMM DD, YYYY')
                    : 'None'
                }
              >
                <Typography variant="body1">
                  {assessmentDetails.start_date
                    ? moment(assessmentDetails.start_date).format('MMM DD, YYYY')
                    : 'None'}
                </Typography>
              </Tooltip>
            </Box>
          </Grid>

          <Grid
            item
            sm={4}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >

            <Box>
              <Typography variant="body2" sx={{ color: '#757575' }}>
                Executive Contact
              </Typography>
              <Typography variant="body1">
                {assessmentDetails.account.account_executives[0]?.fullname ||
                  'N/A'}
              </Typography>
            </Box>

            <Box sx={{ marginTop: 'auto' }}>
              <Typography variant="body2" sx={{ color: '#757575' }}>
                Close Date
              </Typography>
              <Tooltip
                title={
                  assessmentDetails.end_date
                    ? moment(assessmentDetails.end_date).format('MMM DD, YYYY')
                    : 'None'
                }
              >
                <Typography variant="body1">
                  {assessmentDetails.end_date
                    ? moment(assessmentDetails.end_date).format('MMM DD, YYYY')
                    : 'None'}
                </Typography>
              </Tooltip>
            </Box>
          </Grid>

          <Grid
            item
            sm={3}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >

            <Box>
              <AssessmentStatusChip
                status={assessmentDetails.readable_status}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        sm={0.5}
        xs={4}
        order={{ xs: 2, sm: 3 }}
        sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}
      >
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'basic-menu' : undefined}
        >
          <MoreHorizRounded
            sx={{
              fontSize: 30,
              color: 'rgba(28, 28, 28, 0.20)',
              cursor: 'pointer',
            }}
          />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={(event) => {
              handleClose(event);
              navigate(`/evaluation-assessment-detail/${assessmentDetails.uuid}`);
            }}
          >
            View
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              handleClose(event);
              navigate(`/edit-evaluation-assessment/${assessmentDetails.uuid}`);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              handleClose(event);
              deleteAssessment(assessmentDetails.uuid);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}

const AssessmentStatusChip = ({ status }: { status: string }) => {
  return (
    <Chip
      label={status}
      sx={{
        borderRadius: '6px',
        minWidth: '5em',
        fontSize: 16,
        backgroundColor:
          status === 'Closed'
            ? 'rgba(0, 219, 153, 0.20)'
            : status === 'In Progress'
              ? 'rgba(240, 187, 0, 0.40)'
              : status === 'Results Review'
                ? 'rgba (120,120,120,1)'
                : 'rgba(18, 40, 242, 0.40)',
        fontWeight: '600',
      }}
    />
  );
};
