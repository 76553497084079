import React from 'react';
import { Box, TextField } from '@mui/material';
import { Controller, Control, UseFormRegister, FieldErrors } from 'react-hook-form';
import { Typography10px400, Typography12px400 } from '../../../../styles/Global.styles';

const placeholderText = {
  color: '#B5B5C3',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '5px',
};

const innerText = {
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '5px',
};

interface CategoryAnalysisNotesFieldProps {
  name: string;
  control: Control<any>;
  translationLabel: string;
  translationSubtext: string;
  placeholder: string;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  rows?: number;
  minRows: number;
  maxRows: number;
  minLength: number;
  multiline?: boolean;
  useController?: boolean;
}

const CategoryAnalysisNotesField: React.FC<CategoryAnalysisNotesFieldProps> = ({
  name,
  control,
  translationLabel,
  translationSubtext,
  placeholder,
  register,
  errors,
  rows = 3,
  minRows,
  maxRows,
  minLength,
  multiline = true,
  useController = false,
}) => (
  <Box sx={{ margin: '20px 10px', marginTop: '10px', gap: '4px' }}>
    <Typography12px400 sx={{
      fontFamily: "Inter", fontSize: "16px", fontStyle: "Normal",
      fontWeight: 600, lineHeight: "normal", color: "#2E3645"
    }}>
      {translationLabel}
    </Typography12px400>

    <Typography10px400 sx={{
      color: '#2E3645', flexShrink: 0, fontFamily: "inter", fontSize: "12px", fontStyle: "normal",
      fontWeight: 400, lineHeight: "150%"
    }}>
      {translationSubtext}
    </Typography10px400>

    {useController ? (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            placeholder={placeholder}
            id="outlined-basic"
            sx={{ width: '100%', marginTop: '10px', backgroundColor: '#FAFCFF' }}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            InputLabelProps={{ style: placeholderText }}
            InputProps={{ style: innerText }}
            {...field}
          />
        )}
      />
    ) : (
      <TextField
        placeholder={placeholder}
        id="outlined-basic"
        sx={{ width: '100%', marginTop: '10px', backgroundColor: '#FAFCFF' }}
        multiline={multiline}
        rows={rows}
        InputLabelProps={{ style: placeholderText }}
        InputProps={{ style: innerText }}
        {...register(name, {
          minLength: minLength && {
            value: minLength,
            message: `${translationLabel} must be at least ${minLength} characters long`,
          },
        })}
      />
    )}
  </Box>
);

export default CategoryAnalysisNotesField;