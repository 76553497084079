import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { postEvaluationLink } from '../reducers/postEvaluationLink';
import { PostEvaluationLinkPayload } from '../type';
import { useState } from 'react';

const usePostEvaluationLink = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);

  const postSurveyLink = async (requestPayload: PostEvaluationLinkPayload) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(postEvaluationLink(requestPayload));
      if (postEvaluationLink.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }
  };

  return { postSurveyLink, loading, error };
};

export default usePostEvaluationLink;
