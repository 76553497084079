import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssessmentNote, AssessmentNotesReducer } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: AssessmentNotesReducer = {
    assessment_notes: null,
    status: RequestStatus.Init,
};

export const getAssessmentNotes = createAsyncThunk(
    'assessment/getAssessmentNotes',
    async (assessmentId: string, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<AssessmentNote>(
                `${EndPoints.ASSESSMENT_NOTES}/${assessmentId}`,
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getAssessmentNotesSlice = createSlice({
    name: 'getAssessmentNotes',
    initialState,
    reducers: {
        clearAssessmentNotes: (state) => {
            state.assessment_notes = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssessmentNotes.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getAssessmentNotes.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.assessment_notes = action.payload;
            })
            .addCase(getAssessmentNotes.rejected, (state) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearAssessmentNotes } = getAssessmentNotesSlice.actions;

export default getAssessmentNotesSlice.reducer;
