import { useState } from "react";
import { useAppDispatch } from "../../../redux/store";
import { deleteAccountThunk } from "../reducers/deleteAccount";

interface deleteAccountProp {
    error:unknown,
    loading:boolean,
    deleteAccountById:(accountId:string)=>void,
}

const useDeleteAccount = ():deleteAccountProp => {
  const dispatch = useAppDispatch();
  const [error,setError]= useState<unknown>();
  const [loading,setLoading]= useState<boolean>(false);

  const deleteAccountById = async (accountId:string) =>{
    try{
      setLoading(true)
      const resultAction = await dispatch(deleteAccountThunk(accountId));
      if(deleteAccountThunk.fulfilled.match(resultAction)){
        setLoading(false)
      }
    }catch (error){
      setError(error)
    }finally{
      setLoading(false)
    }
  }
  return { loading, error,deleteAccountById}
}

export default useDeleteAccount