import { Box, Button, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          height: '70vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            height: '600px',
            width: '100%',
            objectFit: 'contain',
            filter: 'inherit',
          }}
          src={require('../assets/error-404-page.jpg')}
          alt=""
        />
      </Box>
      <Button
        sx={{
          position: 'absolute',
          left: '50%',
          bottom: '20%',
          backgroundColor: theme.palette.primary.main,
          transform: 'translateX(-50%)',
          color: '#fff',
          width: '200px',
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          },
        }}
        onClick={() => {
          navigate('/');
        }}
      >
        Go to Home page
      </Button>
    </Box>
  );
};

export default PageNotFound;
