import React from 'react';
import { FormControl, TextField, Typography } from '@mui/material';
import { AssessmentQuestion, UnitOfMeasureType } from '../../../Assessments/type';
import { SingleQuestionResponse } from '../../type';

interface NumericEntryInputProps {
  question: AssessmentQuestion;
  formData: SingleQuestionResponse[];
  onChange: (questionId: number, selectedAnswerId?: number, answerText?: string) => void;
}

export default function NumericEntryInput({
  question,
  formData,
  onChange,
}: NumericEntryInputProps) {
  const currentAnswer = formData.find(answer => answer.assessment_question_id === question.id);

  // Handle change using the centralized onChange method from the parent
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // Use a regex to allow only numbers and decimals
    const value = ev.target.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      // Only trigger onChange if the input is valid (numbers and decimals only)
      onChange(question.id, undefined, value);
    }
  };

  const getUnitLabel = (unit: UnitOfMeasureType | null) => {
    switch (unit) {
      case UnitOfMeasureType.PERCENT:
        return '%';
      case UnitOfMeasureType.MINUTE:
        return 'Minutes';
      case UnitOfMeasureType.HOUR:
        return 'Hours';
      case UnitOfMeasureType.DAY:
        return 'Days';
      case UnitOfMeasureType.WEEK:
        return 'Weeks';
      case UnitOfMeasureType.MONTH:
        return 'Months';
      case UnitOfMeasureType.YEAR:
        return 'Years';
      case UnitOfMeasureType.EMPLOYEES:
        return 'Employees';
      case UnitOfMeasureType.CUSTOMER:
        return 'Customers';
      default:
        return '';
    }
  };

  const unit = question.question_definition.response_unit_of_measure as UnitOfMeasureType;
  const unitLabel = getUnitLabel(unit);

  return (
    <FormControl sx={{
      marginTop: '20px',
      marginLeft: '40px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}>

      {unit === UnitOfMeasureType.DOLLAR && (
        <Typography
          sx={{
            marginRight: '8px',
            // fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          $
        </Typography>
      )}

      <TextField
        id={`numeric-field-${question.id}`}
        variant="standard"
        sx={{
          width: '6em',
        }}
        placeholder="Unanswered"
        value={currentAnswer?.answer || ''}
        onChange={handleChange}
        inputProps={{
          inputMode: 'decimal', // Allows numeric and decimal input
          pattern: '-?[0-9]*\\.?[0-9]*', // Restricts characters to numbers and decimal
          style: { textAlign: 'center' },
        }}
      />

      {unit !== UnitOfMeasureType.DOLLAR && unitLabel && (
        <Typography
          sx={{
            marginLeft: '8px',
            // fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          {unitLabel}
        </Typography>
      )}

    </FormControl>
  );
}
