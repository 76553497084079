import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {getPublicAssessmentDefinitions} from '../reducers/getPublicAssessmentDefinitions';

interface useGetPublicAssessmentDefinitionsProps {
    loading: boolean;
    error: AxiosError | null;
    getDefinitions: () => Promise<void>;
}

const useGetPublicAssessmentDefinitions = (): useGetPublicAssessmentDefinitionsProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<AxiosError | null>(null);

    useEffect(() => {
        getDefinitions();
    }, [dispatch]);

        const getDefinitions = async () => {
            try {
                const resultAction = await dispatch(getPublicAssessmentDefinitions());
                if (getPublicAssessmentDefinitions.fulfilled.match(resultAction)) {
                    setLoading(false);
                } else if (getPublicAssessmentDefinitions.rejected.match(resultAction)) {
                    setLoading(false);
                    setError(resultAction.payload as AxiosError);
                }
            } catch (err) {
                setLoading(false);
                setError(err as AxiosError);
            }
        };

    return { getDefinitions, error, loading };
};

export default useGetPublicAssessmentDefinitions;
