import { useState } from 'react';
import { getSingleClient } from '../reducers/getClient';
import { useAppDispatch } from '../../../redux/store';

interface useGetClientProps {
  loading: boolean;
  error: unknown;
  getAccountById: (id: string) => void;
}

const useGetClient = (): useGetClientProps => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const getAccountById = async (id: string) => {
    try {
      const resultAction = await dispatch(getSingleClient(id));
      if (getSingleClient.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, getAccountById };
};

export default useGetClient;
