import { useTheme, createTheme } from '@mui/material/styles';

export const useFormLabelsTheme = () => {
  const currentTheme = useTheme();

  const formLabelsTheme = createTheme(currentTheme, {
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 500,
            color: '#181C32',
          },
          asterisk: {
            color: '#db3131',
            '&$error': {
              color: '#db3131',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 400,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 400,
          },
        },
      },
    },
  });

  return formLabelsTheme;
};