import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutes } from '../../constants/routes';
import './NavBar.css';
import { Button, Avatar, Box, Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import useLogout from '../../modules/Auth/hooks/useLogout';
import { NavigationLink } from './NavBar.styles';
import { RootStoreType } from '../../redux/store.type';
import { DecodedTokenDetails } from '../../modules/Auth/Interfaces/Interfaces';
import { decodeToken } from 'react-jwt';
import { useBranding } from '../../modules/Branding/reducers/getBranding';

export default function NavBar() {
  const Routes = useRoutes();
  const { postLogoutUser } = useLogout();
  const navigate = useNavigate();
  const [activeSubMenu, setActiveSubMenu] = useState(-1);
  const branding = useBranding();

  const handleSubMenuClick = (index: number) => {
    setActiveSubMenu(index === activeSubMenu ? -1 : index);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userToken = useSelector(
    //eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (state: RootStoreType) => state.authReducers.user.currentUser?.access,
  );
  //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const loggedUserDetails: DecodedTokenDetails | null = userToken ? decodeToken(userToken) : null;

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column',
        overflowY: 'auto',
        padding: '20px',
        boxShadow: 'none',
        top: 0,
        left: 0,
        zIndex: 100,
        '::-webkit-scrollbar': {
          width: '4px',
          background: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#ddd',
          borderRadius: '10px',
          width: '10px',
          height: '10px',
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
        }}
      >
        <div>
          <img
            style={{
              width: '95%',
              height: 'auto',
              margin: '20px 0',
              objectFit: 'contain',
            }}
            src={branding?.company_logo_primary}
            alt=""
          />
        </div>
      </Box>
      <Box sx={{ flex: 4 }}>
        {Routes.map((route, index: number) => (
          <Box key={index}>
            {route.submenus ? (
              <NavigationLink
                to={`/${route.url}`}
                className={activeSubMenu === index ? 'active' : ''}
              >
                <Button
                  sx={{
                    color: 'black',
                    fontWeight: activeSubMenu === index ? 'bold' : 'normal',
                  }}
                  startIcon={
                    typeof route.icon === 'string' ? (
                      <Avatar
                        sx={{
                          width: '24px',
                          height: '24px',
                        }}
                        src={route.icon}
                      />
                    ) : (
                      route.icon ? (
                        <Box
                          sx={{
                            width: 24, // using the `sx` prop for the Box component
                            height: 24,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {React.createElement(route.icon)}
                        </Box>
                      ) : null
                    )
                  }
                  onClick={() => handleSubMenuClick(index)}
                >
                  {route.name}
                </Button>
              </NavigationLink>
            ) : (
              <NavigationLink
                className={activeSubMenu === index ? 'active' : ''}
                to={`/${route.url}`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  sx={{
                    color: 'black',
                    fontWeight: activeSubMenu === index ? 'bold' : 'normal',
                  }}
                  startIcon={
                    typeof route.icon === 'string' ? (
                      <Avatar
                        sx={{
                          width: '24px',
                          height: '24px',
                        }}
                        src={route.icon}
                      />
                    ) : (
                      route.icon ? (
                        <Box
                          sx={{
                            width: 24, // using the `sx` prop for the Box component
                            height: 24,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {React.createElement(route.icon)}
                        </Box>
                      ) : null
                    )
                  }
                  onClick={() => handleSubMenuClick(index)}
                >
                  {route.name}
                </Button>
              </NavigationLink>
            )}
            {route.submenus && activeSubMenu === index && (
              <Box>
                {/* {route.submenus.map((submenu, subIndex) => (
                  <NavigationLink
                    className="submenu"
                    to={`/${submenu.url}`}
                    key={subIndex}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      sx={{
                        display: 'block',
                        textAlign: 'left',
                        color: 'black',
                      }}
                      
                    >
                      {submenu.name}
                    </Button>
                  </NavigationLink>
                ))} */}
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Box>
        <Box className="admin-name">
          <Button
            sx={{
              color: 'black',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#F2F2F2',
                color: 'black',
              },
            }}
            onClick={handleClick}
            aria-controls={open ? 'basic-menu' : undefined}
          >
            <Avatar>{loggedUserDetails?.fullname.charAt[1]}</Avatar>
            <span style={{ marginLeft: '10px' }}>
              {loggedUserDetails?.fullname}
            </span>
          </Button>
        </Box>
        <Menu
          sx={{
            '& .MuiPaper-root': {
              boxShadow: '0px 2px 3px 0px rgb(225 213 213 / 25%)',
              border: '1px solid #E0E0E0',
            },
            left: '50px',
          }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              postLogoutUser();
              navigate('/');
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}
