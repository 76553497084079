import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { PublicGetAssessmentErrorResponse } from '../type'
import { getPublicEmployerOfChoiceAssessment } from '../reducers/getPublicEmployerOfChoiceAssessment';

interface useGetPublicAssessmentsProps {
    loading: boolean;
    error: string | null;
    getPublicAssessmentsByAccountId: (accountId: string, groupName: string) => Promise<any>;
}

// Returns the EOC Survey
export const useGetPublicAssessments = (): useGetPublicAssessmentsProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const getPublicAssessmentsByAccountId = async (accountId: string, groupName: string) => {
        try {
            setLoading(true);
            setError(null);
            const resultAction = await dispatch(getPublicEmployerOfChoiceAssessment({accountId, groupName}));
            if (getPublicEmployerOfChoiceAssessment.fulfilled.match(resultAction)) {
                setLoading(false);
                return resultAction.payload;
            } else if (getPublicEmployerOfChoiceAssessment.rejected.match(resultAction)) {
                setLoading(false);
                const errorResponse = resultAction.payload as PublicGetAssessmentErrorResponse;
                if (errorResponse && errorResponse.error) {
                    setError(errorResponse.error); // Display the custom error message
                } else {
                    setError('An unknown error occurred');
                }
            }

        } catch (err) {
            setLoading(false);
            setError('An unexpected error occurred');
        }
    };

    return { error, loading, getPublicAssessmentsByAccountId };
};

export default useGetPublicAssessments;