import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AssessmentNotesPayload } from '../type';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';

const initialState = {
    status: RequestStatus.Init,
};

const putAssessmentNotesSlice = createSlice({
    name: 'assessment/note',
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(putAssessmentNotesThunk.pending, (state) => {
            state.status = RequestStatus.Loading;
        });
        builder.addCase(putAssessmentNotesThunk.fulfilled, (state) => {
            state.status = RequestStatus.Done;
        });
        builder.addCase(putAssessmentNotesThunk.rejected, (state) => {
            state.status = RequestStatus.Error;
        });
    },
});

export const putAssessmentNotesThunk = createAsyncThunk<
    string,
    AssessmentNotesPayload>(
    'updateAssessmentNotes',
    async (notePayload: AssessmentNotesPayload, { rejectWithValue }) => {
        try {
            const result: AxiosResponse<string> = await axiosV2Instance.put(
                `${EndPoints.ASSESSMENT_NOTES}/${notePayload.assessment_id}`,
                notePayload,
            );
            return result.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
    )

export default putAssessmentNotesSlice
