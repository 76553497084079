import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface EvaluationDetail {
  id: string;
  account_id: string;
  account_name: string;
  evaluation_category: string;
  evaluation_category_id: string;
  evaluation_version: number | null;
  evaluation_name: string;
  evaluation_status: string;
  closed_date: string;
  shareable_link: string;
  created_at: Date;
  created_by: string;
  target_responses: number;
  recipients_list: string[];
  encoded_evaluation_id: string;
  responses_received: number;
  report_link: string;
  is_auto_email_reminder_active: boolean;
  days_before_close_date: number | null;
}

export interface EvaluationDetailResponse {
  count: number;
  next: string;
  previous: string;
  results: EvaluationDetail[];
}

export interface EvaluationDetailInitialState {
  status: string;
  evaluationDetail: EvaluationDetailResponse | null;
}

const initialState: EvaluationDetailInitialState = {
  status: '',
  evaluationDetail: null,
};

export const getEvaluationDetail = createAsyncThunk<
  EvaluationDetailResponse,
  string
>('evaluationDetail/getEvaluationDetail', async (id: string) => {
  const response: AxiosResponse<EvaluationDetailResponse> =
    await axiosInstance.post(`${EndPoints.EVALUATION_DETAIL}`, { id });
  return response.data;
});

const EvaluationDetailSlice = createSlice({
  name: 'evaluationDetail',
  initialState,
  reducers: {
    clearEvaluationDetail: (state) => {
      state.evaluationDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvaluationDetail.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(
      getEvaluationDetail.fulfilled,
      (state, action: PayloadAction<EvaluationDetailResponse>) => {
        state.evaluationDetail = action.payload;
        state.status = 'done';
      },
    );
    builder.addCase(getEvaluationDetail.rejected, (state) => {
      state.status = 'error';
    });
  },
});

export const { clearEvaluationDetail } = EvaluationDetailSlice.actions;

export default EvaluationDetailSlice.reducer;
