import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  ContentCard,
  FormContainer,
  LogoImg,
  Headline,
  Paragraph
} from './Hero.style';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../redux/store.type';
import { Navigate } from 'react-router-dom';
import { useBranding } from '../../modules/Branding/reducers/getBranding';
import { useTextTheme } from '../../hooks/usetextTheme';

const Hero = ({ component }) => {
  const textTheme = useTextTheme();
  const branding = useBranding();
  const { currentUser } = useSelector(
    (state: RootStoreType) => state.authReducers?.user,
  );
  if (currentUser) {
    return <Navigate to="/account-list" replace />;
  }
  if (branding == null) {
    return (<Box></Box>);
  }

  return (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center" bgcolor="#fafafa">
      <Box
        sx={{
          backgroundColor: 'white',
          boxShadow: 3,
          borderRadius: 2,
          padding: 3,
          width: '90%', // Ensures the box doesn't take full width of the screen
          maxWidth: '1200px', // Limits the maximum width of the box
        }}
      >
        <Grid container alignItems="center" justifyContent="center" spacing={6}>
          <ThemeProvider theme={textTheme}>
            <Grid item xs={12} sm={7} display="flex" justifyContent="flex-end">
              <Box textAlign="left" maxWidth="600px"> {/* Set a max-width to ensure content stays within bounds */}
                <LogoImg
                  src={branding?.company_logo_secondary}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '400px',
                    height: 'auto',
                    width: 'auto',
                    objectFit: 'contain'
                  }}
                />
                <Headline className="headline-text" mt={8} color={branding.primary_color}>
                  {branding.login_headline?.toUpperCase()}
                </Headline>
                <Paragraph className="paragraph-text" mt={4}>
                  {branding.login_paragraph}
                </Paragraph>
                <Box mt={11} fontSize={"13px"}>
                  Smart Assessments Powered by <img src='https://res.cloudinary.com/hhskej7iz/image/upload/v1718061456/Vessel_Logo_zu8a9g.png' alt="Vessel" width="65px" height="auto" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} display="flex" justifyContent="flex-start" alignItems="center">
              <Box width="100%" maxWidth="400px" ml={4}>
                <FormContainer>{component}</FormContainer>
              </Box>
            </Grid>
          </ThemeProvider>
        </Grid>
      </Box>
    </Box>
  );
};

export default Hero;
