import React from 'react';
import {
    Box,
    IconButton,
    Typography,
    Grid
} from '@mui/material';
import moment from 'moment';
import { PieChart, Pie, Cell } from 'recharts';
import { LightAssessmentOverviewDetails } from '../../../../Assessments/type';
import ArrowBendRightUpIcon from '../../../../../assets/svg-components/ArrowBendRightUpIcon';
import { FieldName, FieldValue, GridItemFieldName, GridItemFieldValue } from '../EvaluationDetails.style';

const AssessmentResponsesGraphCard = ({ lightInfo }: { lightInfo: LightAssessmentOverviewDetails }) => {
    // Avoid Divide by Zero by changing target of 0 to 1 in chart calculations
    const targetResponseAmount = lightInfo.target_responses <= 0 ? 1 : lightInfo.target_responses;
    const percentResponseRate = Math.round(lightInfo.response_count / targetResponseAmount * 100);

    const data = [
        { name: 'Target', value: 100 - percentResponseRate },
        { name: 'Received', value: percentResponseRate },
    ];
    const COLORS = ['#95A4FC', '#BAEDBD'];

    return (
        <Box
            sx={{
                width: '100%',
                paddingBottom: '20px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                }}
                variant="h5"
                component="h2"
                gutterBottom
            >
                Responses
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Grid item xs={12} sm={6} md={6} lg={6} position={'relative'}>
                    <Box
                        sx={{
                            height: '210px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                    >
                        <PieChart
                            width={200}
                            height={200}
                        >
                            <Pie
                                data={data}
                                cx={"50%"}
                                cy={"50%"}
                                startAngle={0}
                                endAngle={360}
                                cornerRadius={50}
                                innerRadius={40}
                                outerRadius={70}
                                stroke="#fff"
                                strokeWidth={5}
                                fill="#8884d8"
                                paddingAngle={-15}
                                dataKey="value"
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                        </PieChart>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            position: 'absolute',
                            bottom: '-25px',
                            left: '30%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'row',
                                marginBottom: '0px',
                            }}
                        >
                            <Typography variant="h6" component="h6" gutterBottom>
                                {percentResponseRate}%
                            </Typography>
                            <IconButton
                                disabled
                                sx={{
                                    position: 'relative',
                                    top: '-10px',
                                    left: '-5px',
                                }}
                            >
                                <ArrowBendRightUpIcon width={25} height={25} />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Grid container spacing={2}>
                        <GridItemFieldName item xs={6} sm={6} sx={{ marginTop: '10px' }}>
                            <Box
                                sx={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    backgroundColor: '#BAEDBD',
                                    float: 'left',
                                    marginRight: '5px',
                                    position: 'relative',
                                }}
                            ></Box>
                            <FieldName>Received</FieldName>
                        </GridItemFieldName>
                        <GridItemFieldValue item xs={6} sm={6}>
                            <FieldValue> {lightInfo.response_count}</FieldValue>
                        </GridItemFieldValue>
                        <GridItemFieldName item xs={6} sm={6}>
                            <Box
                                sx={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    backgroundColor: '#C6C7F8',
                                    float: 'left',
                                    marginRight: '5px',
                                    position: 'relative',
                                }}
                            ></Box>
                            <FieldName>Target</FieldName>
                        </GridItemFieldName>
                        <GridItemFieldValue item xs={6} sm={6}>
                            <FieldValue> {lightInfo.target_responses} </FieldValue>
                        </GridItemFieldValue>
                        <GridItemFieldName item xs={6} sm={6}>
                            <Box
                                sx={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    backgroundColor: '#000',
                                    float: 'left',
                                    marginRight: '5px',
                                    position: 'relative',
                                }}
                            ></Box>
                            <FieldName>Close Date</FieldName>
                        </GridItemFieldName>
                        <GridItemFieldValue item xs={6} sm={6}>
                            <FieldValue>
                                {lightInfo?.end_date
                                    ? moment(lightInfo?.end_date).format('MMM DD, YYYY')
                                    : 'None'}
                            </FieldValue>
                        </GridItemFieldValue>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AssessmentResponsesGraphCard;
