import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axios, { AxiosError } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface EvaluationNameResponse {
  evaluation_name: string;
  evaluation_paragraph: string; // New field
}

export interface EvaluationNameReducer {
  evaluationName: EvaluationNameResponse | null;
  status: RequestStatus;
}

const initialState: EvaluationNameReducer = {
  evaluationName: null,
  status: RequestStatus.Init,
};

type GetEvaluationNameArg = string;

export const getEvaluationName = createAsyncThunk<
  EvaluationNameResponse, // Type of the return value (payload)
  GetEvaluationNameArg, // Type of the argument
  {
    rejectValue: AxiosError // Type for rejectWithValue
  }
>(
  'evaluationName/getEvaluationName',
  async (shareableLinkId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<EvaluationNameResponse>(
        `${EndPoints.GET_EVALUATION_NAME}${shareableLinkId}/`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Ensure that the rejection is of the type AxiosError
        return rejectWithValue(error);
      }
      throw error; // Throw error if it's not an AxiosError
    }
  }
);

const getEvaluationNameSlice = createSlice({
  name: 'getEvaluationName',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvaluationName.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationName.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.evaluationName = action.payload;
    });
    builder.addCase(getEvaluationName.rejected, (state) => {
      state.status = RequestStatus.Error;
      state.evaluationName = null;
    });
  },
});

export default getEvaluationNameSlice.reducer;
