import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';

import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { EvaluationResultAnalysisResponse } from '../type';

export interface EvaluationCategoryAnalysisResultPayload {
  evaluation_id: string;
  question_category_id: string;
}

export interface EvaluationCategoryAnalysisResultsResponseReducer {
  status: RequestStatus;
  evaluationResultResponses: { [categoryId: string]: EvaluationResultAnalysisResponse };
}

const initialState: EvaluationCategoryAnalysisResultsResponseReducer = {
  status: RequestStatus.Init,
  evaluationResultResponses: {},
};

const getEvaluationCategoryAnalysisResultsSlice = createSlice({
  name: '/getEvaluationCategoryAnalysisResultsResponse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEvaluationCategoryAnalysisResults.pending, (state) => {
        state.status = RequestStatus.Loading;
      })
      .addCase(getEvaluationCategoryAnalysisResults.fulfilled, (state, action) => {
        action.payload.forEach(item => {
          state.evaluationResultResponses[item.categoryId] = item.response;
        })
        state.status = RequestStatus.Done;
      })
      .addCase(getEvaluationCategoryAnalysisResults.rejected, (state) => {
        state.status = RequestStatus.Error;
      });
  },
});

export const getEvaluationCategoryAnalysisResults = createAsyncThunk<
  { categoryId: string; response: EvaluationResultAnalysisResponse }[],
  EvaluationCategoryAnalysisResultPayload[]
>(
  '/getEvaluationCategoryResults',
  async (evaluationPayloads, { rejectWithValue }) => {
    try {
      const responses = await Promise.all(
        evaluationPayloads.map(async (payload) => {
          const url = `${EndPoints.PUT_EVALUATION_RESULT_ANALYSIS}?evaluation_id=${encodeURIComponent(payload.evaluation_id)}&question_category_id=${encodeURIComponent(payload.question_category_id)}`;

          const response = await axiosInstance.get<EvaluationResultAnalysisResponse>(url);
          return {
            categoryId: payload.question_category_id,
            response: response.data,
          };
        })
      );
      return responses;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default getEvaluationCategoryAnalysisResultsSlice.reducer;
