import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';

export interface AssignAccountRolePayload {
  account_id: string;
  role: string;
  user_id: string | number;
}

interface AssignAccountRoleInitialState {
  status: RequestStatus;
}

const initialState: AssignAccountRoleInitialState = {
  status: RequestStatus.Init,
};

const assignAccountRoleSlice = createSlice({
  name: 'assignAccountRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(assignAccountRole.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(assignAccountRole.fulfilled, (state) => {
      state.status = RequestStatus.Done;

      // toast.success('Assigned Role Successfully!');
    });
    builder.addCase(assignAccountRole.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      state.status = RequestStatus.Error;
      const errorResponses = (action.payload as AxiosError).response?.data as {
        error: string;
      };

      toast.error(errorResponses.error);
    });
  },
});

export const assignAccountRoleSliceReducer = assignAccountRoleSlice.reducer;

export const assignAccountRole = createAsyncThunk<
  string,
  AssignAccountRolePayload[]
>('assignAccountRole/post', async (payload, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<string> = await postAssignAccountRole(
      payload,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const postAssignAccountRole = async (
  payload: AssignAccountRolePayload[],
): Promise<AxiosResponse<string>> => {
  return axiosInstance.post(`${EndPoints.ACCOUNT_SETTING}`, payload);
};
