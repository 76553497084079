import React, { useEffect, useState } from 'react';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Grid,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import IntakeFormMethodology from './components/IntakeFormMethodology';
import IntakeFormDemographics from './components/IntakeFormDemographics';
import IntakeFormOverView from './components/IntakeFormOverView';
import { useBranding } from '../../modules/Branding/reducers/getBranding';
import usePostAccountAndContact from '../../modules/Accounts/hooks/usePostAccount';
import {
  PostAccountRequest,
  PostAccountResponse,
} from '../../modules/Accounts/reducers/postAccountAndContact';
import {
  AssessmentCreation,
  AssessmentDefinition,
  AssessmentDetails,
  AssessmentStatus,
  PublicAssessmentOverviewDetails,
} from '../../modules/Assessments/type';
import { useAppSelector } from '../../redux/store';
import usePostAssessment from '../../modules/Assessments/hooks/usePostAssessment';
import usePostGroup from '../../modules/Assessments/hooks/usePostGroup';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useGetPublicAssessmentDefinitions from '../../modules/Assessments/hooks/useGetPublicAssessmentDefinitions';
import {
  PostGroupRequest,
  PostGroupResponse,
} from '../../modules/Assessments/reducers/postGroup';
import { group } from 'console';
import IntakeFormFailureModal from './components/IntakeFormFailureModal';
import { mapToAssessmentCreation } from './helpers/Helpers';
import { AccountDetails } from '../../modules/Accounts/types';
import { useGetPublicAssessments } from '../../modules/Assessments/hooks/useGetPublicAssessments';
import SurveryCompletionGraphic from '../../assets/svg-components/SurveryCompletionGraphic';

const IntakeFormMain = () => {
  const branding = useBranding();
  const [loading, setLoading] = useState(false); // Add loading state
  const postAccountAndContact = usePostAccountAndContact();
  const getAssessmentDefinitions = useGetPublicAssessmentDefinitions();
  const { getPublicAssessmentsByAccountId } = useGetPublicAssessments();
  const postGroup = usePostGroup();
  const postAssessment = usePostAssessment();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[] | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [createdAssessmentId, setCreatedAssessmentId] = useState<string>('');
  const [company, setCompany] = useState<AccountDetails | null>(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    companyName: '',
    companySize: '',
    industryType: [],
    regionalManufacturersAssoc: [],
    address2: '',
    phoneNumber: '',
    city: '',
    state: '',
    zipCode: '',
    county: '',
    participationAgreement: '',
  });
  let displayCompletionForm = false;


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleFormChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
      | SelectChangeEvent<string[]>,
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // const isFormComplete = Object.values(formData).every((value) =>
  //   Array.isArray(value) ? value.length > 0 : value.trim() !== '',
  // );

  const isFormComplete = (): boolean => {
    if (company) {
      // Skip specific fields if "company" is defined
      const fieldsToValidate = [
        'companySize',
        'industryType',
        'regionalManufacturersAssoc',
        'county',
        'participationAgreement',
      ];

      return fieldsToValidate.every((field) =>
        Array.isArray(formData[field as keyof typeof formData])
          ? (formData[field as keyof typeof formData] as string[]).length > 0
          : (formData[field as keyof typeof formData] as string).trim() !== '',
      );
    }

    // Validate all fields if "company" is not defined
    return Object.values(formData).every((value) =>
      Array.isArray(value) ? value.length > 0 : value.trim() !== '',
    );
  };

  const assessmentDefinitions: AssessmentDefinition[] | null = useAppSelector(
    (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.assessmentReducers.getAssessmentDefinitionsReducer
        .assessment_definitions;
    },
  );

  const postedAssessment: AssessmentDetails | null = useAppSelector((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.assessmentReducers.getCreatedAssessmentReducer
      .assessment_details;
  });

  const onNextClick = async () => {
    if (isFormComplete()) {
      setLoading(true);
      const today = new Date();
      const year = today.getFullYear();
      let companyName = "";
      let companyId = "";
      let eocAssessment: PublicAssessmentOverviewDetails | null = null;

      // If we have selected a company from the list, see if Assessment exists
      if (company) {
        const groupName = `${year} ${company.name}`;

        eocAssessment = await getPublicAssessmentsByAccountId(
          company?.id as string,
          groupName,
        ) as PublicAssessmentOverviewDetails;

        if (!eocAssessment) {
          companyName = company.name;
          companyId = company.id as string;
        }

        const accountToUpdate: PostAccountRequest = updateAccountRequest(formData, company);

        const accountAndContacts =
          await postAccountAndContact.postAccountAndContacts(accountToUpdate);
        if (!accountAndContacts || !accountAndContacts.data) {
          const errorJsonString =
            accountAndContacts.response.data.error.toString();
          const matches = [...errorJsonString.matchAll(/string='(.*?)'/g)].map(
            (match) => match[1] as string,
          );
          setErrorMessage(matches);
          setIsModalOpen(true);
          return;
        }

      } else {
        // Create New Contact and New Account
        const accountToCreate: PostAccountRequest = createAccountRequest(formData);
        const accountAndContacts =
          await postAccountAndContact.postAccountAndContacts(accountToCreate);
        if (!accountAndContacts || !accountAndContacts.data) {
          const errorJsonString =
            accountAndContacts.response.data.error.toString();
          const matches = [...errorJsonString.matchAll(/string='(.*?)'/g)].map(
            (match) => match[1] as string,
          );
          setErrorMessage(matches);
          setIsModalOpen(true);
          return;
        }
        const accountData = accountAndContacts.data as PostAccountResponse;

        companyName = accountData.name;
        companyId = accountData.id as string;
      }

      // If Assessment doesn't exist and we have a companyName and companyId, create new Group and Assessments
      if (!eocAssessment && companyName && companyId) {
        // Create Group
        const groupRequest: PostGroupRequest = {
          name: `${year} ${companyName}`,
          account_id: companyId,
        };

        const assessmentGroup = await postGroup.postGroups(groupRequest) as PostGroupResponse;

        // Create Assessments
        const dateNow = today.toISOString().split('T')[0];
        const futureDate = new Date();
        futureDate.setDate(today.getDate() + 14);
        const dateNowPlus14Days = futureDate.toISOString().split('T')[0];
        const eocTargetResponses = 1;
        const eesTargetResponses = 5;

        // create assessments with newly created group and account data
        const employerOfChoiceSurveyDefinition = assessmentDefinitions!.find(
          (assessmentDef) =>
            assessmentDef.name.includes('Employer of Choice Survey'),
        );

        const employerOfChoiceSurvey = mapToAssessmentCreation(
          companyId,
          employerOfChoiceSurveyDefinition!.id,
          companyName + ' ' + employerOfChoiceSurveyDefinition!.name,
          dateNow,
          dateNowPlus14Days,
          eocTargetResponses,
          assessmentGroup.id,
          false,
          true
        );

        const employeeEngagementSurveyDefinition = assessmentDefinitions!.find(
          (assessmentDef) =>
            assessmentDef.name.includes('Employee Engagement Survey'),
        );

        const employeeEngagementSurvey = mapToAssessmentCreation(
          companyId,
          employeeEngagementSurveyDefinition!.id,
          companyName + ' ' + employeeEngagementSurveyDefinition!.name,
          dateNow,
          dateNowPlus14Days,
          eesTargetResponses,
          assessmentGroup.id,
          true,
          false
        );

        eocAssessment = await postAssessment.postAssessment(
          employerOfChoiceSurvey) as PublicAssessmentOverviewDetails;
        const eesAssessment = await postAssessment.postAssessment(
          employeeEngagementSurvey,
        );
      }
      if (eocAssessment) {
        setCreatedAssessmentId(eocAssessment.uuid);
      } else { displayCompletionForm = true }
    }
  };

  useEffect(() => {
    if (createdAssessmentId) {
      navigate(`/evaluate/${createdAssessmentId}`);
    }
  }, [createdAssessmentId, navigate]);

  const handleRetry = () => {
    setIsModalOpen(false);
    navigate(`/intake-form`);
    setLoading(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(`/`);
  };

  if (displayCompletionForm && (!loading &&
    branding)) {
    return (
      <Grid container
        spacing={2}
        sx={{
          padding: 2,
          marginX: '5%',
          marginTop: '3em',
          display: 'flex',
          justifyContent: 'center',
        }}>
        {/* Main Thank You Message Section */}
        <Grid item xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              // padding: '20px',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '85%',
              gap: 5
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{
                  color: branding?.primary_color || '#053D58',
                  fontFamily: 'Inter',
                  fontSize: '48px',
                  fontWeight: 700,
                }}
              >
                Thank you for completing the intake form!
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: '#6A6A6A',
                  fontFamily: 'Inter',
                  fontSize: '24px',
                  fontWeight: 500,
                  mt: '5%',
                  mb: '5%',
                }}
              >
                Your feedback is greatly appreciated.
              </Typography>
            </Box>
            <SurveryCompletionGraphic />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container sx={{ maxWidth: '100%', minWidth: '100%' }}>
      <IntakeFormFailureModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onRetry={handleRetry}
        errorMessage={errorMessage}
      />
      {loading ||
        !branding ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <>
          <AppBar
            position="static"
            sx={{
              backgroundColor: 'white',
              boxShadow: 'none',
              flexDirection: 'row',
            }}
          >
            <Grid sx={{ width: '5%' }} item>
              <img
                style={{
                  width: '95%',
                  height: 'auto',
                  margin: '20px 0',
                  objectFit: 'contain',
                }}
                src="/employerimg.png"
                alt="EmployerLogo"
              />
            </Grid>
            <Grid sx={{ width: '85%', marginY: 'auto' }} item>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab
                  label="Introduction / Overview"
                  sx={{
                    borderBottom: tabIndex >= 0 ? '2px solid #3787bc' : 'none',
                  }}
                />
                <Tab
                  label="Methodology"
                  sx={{
                    borderBottom: tabIndex >= 1 ? '2px solid #3787bc' : 'none',
                  }}
                />
                <Tab
                  label="Demographic Information"
                  sx={{
                    borderBottom: tabIndex >= 2 ? '2px solid #3787bc' : 'none',
                  }}
                />
              </Tabs>
            </Grid>
          </AppBar>

          <Box mt={4}>
            {tabIndex === 0 && <IntakeFormOverView />}
            {tabIndex === 1 && <IntakeFormMethodology />}
            {tabIndex === 2 && (
              <IntakeFormDemographics
                formData={formData}
                onFormChange={handleFormChange}
                onNextClick={onNextClick}
                onStepChange={setTabIndex}
                branding={branding}
                assessmentDefinitions={assessmentDefinitions}
                selectedCompany={company}
                selectedCompanyChange={setCompany}
              />
            )}
          </Box>

          <Container>
            <Grid
              item
              sx={{ display: 'flex', marginTop: '3%' }}
              justifyContent={tabIndex == 0 ? 'flex-end' : 'space-between'}
            >
              {tabIndex != 2 && tabIndex != 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setTabIndex((prev) => prev - 1)}
                >
                  <ArrowBackIcon sx={{ marginRight: '8%' }} /> Back
                </Button>
              )}
              {tabIndex != 2 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setTabIndex((prev) => prev + 1)}
                >
                  Continue <ArrowForwardIcon sx={{ marginLeft: '8%' }} />
                </Button>
              )}
            </Grid>
          </Container>
        </>
      )}
    </Container>
  );
};

export default IntakeFormMain;

function createAccountRequest(formData: {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  companyName: string;
  companySize: string;
  industryType: never[];
  regionalManufacturersAssoc: never[];
  address2: string;
  phoneNumber: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  participationAgreement: string;
}): PostAccountRequest {
  return {
    name: formData.companyName,
    email: formData.email,
    website: null,
    address1: formData.address2,
    address2: null,
    country: null,
    city: formData.city,
    state: formData.state,
    zipcode: formData.zipCode,
    is_active: true,
    account_contacts: [
      {
        first_name: `${formData.firstName}`,
        last_name: `${formData.lastName}`,
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        phone: formData.phoneNumber,
        is_primary_contact: true,
        role: formData.jobTitle,
        created_at: new Date().toISOString(),
        created_by: null,
      },
    ],
    naics_codes: formData.industryType,
    rmAssociations: formData.regionalManufacturersAssoc,
    companySize: formData.companySize,
    county: formData.county,
  };

}

function updateAccountRequest(formData: {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  companyName: string;
  companySize: string;
  industryType: never[];
  regionalManufacturersAssoc: never[];
  address2: string;
  phoneNumber: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  participationAgreement: string;
},
  company: AccountDetails
): PostAccountRequest {
  return {
    id: company.id as string,
    naics_codes: formData.industryType,
    rmAssociations: formData.regionalManufacturersAssoc,
    companySize: formData.companySize,
    county: formData.county,
  };
}
