import React from 'react';

function ArrowBendRightUpIcon({ width = 20, height = 20, fill = 'black' }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1827 6.69162L15.1831 6.69194C15.3003 6.80915 15.4592 6.875 15.625 6.875C15.7908 6.875 15.9497 6.80915 16.0669 6.69194C16.1842 6.57473 16.25 6.41576 16.25 6.25C16.25 6.08424 16.1842 5.92527 16.0669 5.80806L12.3169 2.05806C12.1997 1.94085 12.0408 1.875 11.875 1.875C11.7092 1.875 11.5503 1.94085 11.4331 2.05806L7.68329 5.80782L7.68306 5.80806C7.56585 5.92527 7.5 6.08424 7.5 6.25C7.5 6.26001 7.50024 6.27002 7.50072 6.28002C7.50819 6.43522 7.57319 6.58207 7.68306 6.69194C7.80027 6.80915 7.95924 6.875 8.125 6.875C8.29076 6.875 8.44973 6.80915 8.56694 6.69194L8.56718 6.69171L11.875 3.38388L15.1827 6.69162Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M12.5 10V2.5C12.5 2.15482 12.2202 1.875 11.875 1.875C11.5298 1.875 11.25 2.15482 11.25 2.5V10C11.25 11.3675 10.7267 12.6309 10.7267 12.6309C10.2033 13.8944 9.23636 14.8614 9.23636 14.8614C8.26937 15.8283 7.00595 16.3517 7.00595 16.3517C5.74252 16.875 4.375 16.875 4.375 16.875C4.02982 16.875 3.75 17.1548 3.75 17.5C3.75 17.8452 4.02982 18.125 4.375 18.125C5.99116 18.125 7.4843 17.5065 7.4843 17.5065C8.97744 16.888 10.1202 15.7452 10.1202 15.7452C11.263 14.6024 11.8815 13.1093 11.8815 13.1093C12.5 11.6162 12.5 10 12.5 10Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default ArrowBendRightUpIcon;
