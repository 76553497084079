import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

//shared styles
const sharedTypographyStyles = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  lineHeight: 'normal',
};

export const TitleTypography = styled(Typography)({
  ...sharedTypographyStyles,
  color: '#464E5F',
  fontWeight: 600,
});

export const ValueTypography = styled(TitleTypography)({
  color: '#000',
  fontWeight: 400,
  overflow: 'none',
});

export const Goto = styled(Link)({
  color: '#000',
  fontWeight: 400,
  overflow: 'auto',
  textDecoration: 'none',
});
