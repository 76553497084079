import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
  PersistConfig,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducers from '../modules/Auth/reducers';
import allClientReducer from '../modules/Accounts/reducers/index';
import allEvaluationReducers from '../modules/Evaluations/reducers/index';
import scoinReducers from '../modules/Scoin/reducers/index';
import cloudinaryReducers from '../modules/Cloudinary/reducers/index';
import assessmentReducers from '../modules/Assessments/reducers/index';
import { RootStoreType } from './store.type';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const persistConfig: PersistConfig<RootStoreType> = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['authReducers'],
};

const rootReducer = combineReducers<RootStoreType>({
  authReducers,
  allClientReducer,
  allEvaluationReducers,
  scoinReducers,
  cloudinaryReducers,
  assessmentReducers
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootStoreType> = useSelector;
