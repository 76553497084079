import React from 'react';
import translation from '../../../locales/en/translation.json';

export const evaluationFields = [
  {
    name: 'strengths',
    translationLabel: translation.strengths,
    translationSubtext: translation.evaluation_score_analysis_strengths_label_subtext,
    placeholder: translation.evaluation_score_analysis_strengths_placeholder,
  },
  {
    name: 'challenges',
    translationLabel: translation.challenges_threats,
    translationSubtext: translation.evaluation_score_analysis_challenges_threats_label_subtext,
    placeholder: translation.evaluation_score_analysis_challenges_threats_placeholder,
    useController: true,
    minRows: 6,
    maxRows: 8,
  },
  {
    name: 'root_causes',
    translationLabel: translation.root_cause,
    translationSubtext: translation.evaluation_score_analysis_root_cause_label_subtext,
    placeholder: translation.evaluation_score_analysis_root_causes_placeholder,
  },

  {
    name: 'offerings',
    translationLabel: translation.possible_offerings,
    translationSubtext: translation.evaluation_score_analysis_offerings_label_subtext,
    placeholder: translation.evaluation_score_analysis_offerings_placeholder,
    useController: true,
    minRows: 6,
    maxRows: 8,
  },
  {
    name: 'go_forward_plan',
    translationLabel: translation.go_forward_plan,
    translationSubtext: translation.evaluation_score_analysis_go_forward_plan_cause_label_subtext,
    placeholder: translation.evaluation_score_analysis_go_forward_plan_placeholder,
  },
  {
    name: 'key_priorities',
    translationLabel: translation.key_priorities,
    translationSubtext: translation.evaluation_score_analysis_key_priorities_cause_label_subtext,
    placeholder: translation.evaluation_score_analysis_key_priorities_placeholder,
    minLength: 0,
    displayFor: 'hawaii', // Hawaii only for now TODO: Fix this when new report is done
  },
  {
    name: 'development_opportunities',
    translationLabel: translation.development_opportunities,
    translationSubtext: translation.evaluation_score_analysis_development_opportunities_cause_label_subtext,
    placeholder: translation.evaluation_score_analysis_development_opportunities_placeholder,
    minLength: 0,
    displayFor: 'hawaii', // Hawaii only for now TODO: Fix this when new report is done
  },
  {
    name: 'implementation_details_and_timing',
    translationLabel: translation.implementation_details_and_timing,
    translationSubtext: translation.evaluation_score_analysis_implementation_details_and_timing_cause_label_subtext,
    placeholder: translation.evaluation_score_analysis_implementation_details_and_timing_placeholder,
    minLength: 0,
    displayFor: 'hawaii', // Hawaii only for now TODO: Fix this when new report is done
  },
];
