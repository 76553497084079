import React from 'react';

function BarcodeIcon({ width = 16, height = 16, fill = '#1C1C1C' }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 3.5V5.5C13.5 5.77614 13.7239 6 14 6C14.2761 6 14.5 5.77614 14.5 5.5V3C14.5 2.72386 14.2761 2.5 14 2.5H11.5C11.2239 2.5 11 2.72386 11 3C11 3.27614 11.2239 3.5 11.5 3.5H13.5Z"
        fill="#1C1C1C"
        fillOpacity="0.1"
      />
      <path
        d="M2.5 12.5V10.5C2.5 10.2239 2.27614 10 2 10C1.72386 10 1.5 10.2239 1.5 10.5V13C1.5 13.2761 1.72386 13.5 2 13.5H4.5C4.77614 13.5 5 13.2761 5 13C5 12.7239 4.77614 12.5 4.5 12.5H2.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M13.5 12.5H11.5C11.2239 12.5 11 12.7239 11 13C11 13.2761 11.2239 13.5 11.5 13.5H14C14.2761 13.5 14.5 13.2761 14.5 13V10.5C14.5 10.2239 14.2761 10 14 10C13.7239 10 13.5 10.2239 13.5 10.5V12.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M2.5 3.5H4.5C4.77614 3.5 5 3.27614 5 3C5 2.72386 4.77614 2.5 4.5 2.5H2C1.72386 2.5 1.5 2.72386 1.5 3V5.5C1.5 5.77614 1.72386 6 2 6C2.27614 6 2.5 5.77614 2.5 5.5V3.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M4.5 5.5V10.5C4.5 10.7761 4.72386 11 5 11C5.27614 11 5.5 10.7761 5.5 10.5V5.5C5.5 5.22386 5.27614 5 5 5C4.72386 5 4.5 5.22386 4.5 5.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M10.5 5.5V10.5C10.5 10.7761 10.7239 11 11 11C11.2761 11 11.5 10.7761 11.5 10.5V5.5C11.5 5.22386 11.2761 5 11 5C10.7239 5 10.5 5.22386 10.5 5.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M8.5 5.5V10.5C8.5 10.7761 8.72386 11 9 11C9.27614 11 9.5 10.7761 9.5 10.5V5.5C9.5 5.22386 9.27614 5 9 5C8.72386 5 8.5 5.22386 8.5 5.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M6.5 5.5V10.5C6.5 10.7761 6.72386 11 7 11C7.27614 11 7.5 10.7761 7.5 10.5V5.5C7.5 5.22386 7.27614 5 7 5C6.72386 5 6.5 5.22386 6.5 5.5Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default BarcodeIcon;
