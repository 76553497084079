// import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { AppDispatch } from '../../../redux/store';
// import { getShareableLink } from '../reducers/getShareableLink';

// interface shareableLinkProps {
//   loading: boolean;
//   error: unknown;
// }

const useGetShareableLink = (): string => {
  // const dispatch = useDispatch<AppDispatch>();
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState<unknown>();

  // const getAllEvaluationCategories = async () => {
  //   try {
  //     const resultAction = await dispatch(getShareableLink());
  //     if (getShareableLink.fulfilled.match(resultAction)) {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setError(error);
  //   }
  // };

  // useEffect(() => {
  //   getAllEvaluationCategories();
  // }, []);

  function generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }

  const uuid = generateUUID();

  return uuid;
};

export default useGetShareableLink;
