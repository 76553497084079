import React from 'react';
import {
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
export default function RowRadioButtonsGroup({
  question,
  options,
  formData,
  setFormData,
}: {
  question?: any;
  options?: any;
  formData?: any;
  setFormData?: any;
}) {
  const handleChange = (event: React.ChangeEvent, v: string) => {
    setFormData({ ...formData, [question.id]: v });
  };

  return (
    <FormControl sx={{ marginTop: '1%',  width: "25vw"}}>
      <RadioGroup
        value={formData[question.id] || ''}
        onChange={handleChange}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <Typography variant="subtitle2" sx={{color:"#3787bc", marginLeft: '10%'}}>Choose one option</Typography>
        {options?.map((option, i: number) => (
          <FormControlLabel
            sx={{outline: formData[question.id] === option.option ? "2px solid #3787bc" : "1px solid gray", marginTop:"3%", marginBottom:"2%", marginLeft: '10%'}}
            key={i}
            value={option.option}
            control={<Radio />}
            label={option.option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
