import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ReviewsIcon from '@mui/icons-material/Reviews';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const CardContentNoPadding = styled(CardContent) (`
  padding: 10;
  &: last-child {
    padding-bottom: 0;
  }
`);

const ClientMetricBox = (prop: {
  metricTitle: string;
  metricValue: number;
  metricIcon: string;
}) => {
  return (
    <Card sx={{ background: '#E3e4e4', boxShadow: 'none' }}>
      <CardContentNoPadding sx={{ color: '#0e274c' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: 16,
            marginBottom: '10px',
          }}
        >
          {prop.metricIcon === 'Assess' ? <AssessmentOutlinedIcon sx={{color: '#93241B', fontSize: 18}}></AssessmentOutlinedIcon> :
            prop.metricIcon === 'Recommend' ? <ReviewsIcon sx={{color: '#93241B', fontSize: 18}}></ReviewsIcon> :
              prop.metricIcon === 'Complete' ? <WorkspacePremiumIcon sx={{color: '#93241B', fontSize: 18}}></WorkspacePremiumIcon> : ''} {prop.metricTitle}
        </Typography>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: 'center',
            fontSize: 32,
            paddingBottom: '12px'
          }}
        >
          {prop.metricValue}
        </Typography>
      </CardContentNoPadding>
    </Card>
  );
};

export default ClientMetricBox;
