import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import translation from '../../../../locales/en/translation.json';
import { CancelButton, SaveButton } from '../../../Accounts/CreateClient/CreateClient.styles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #6A6A6A',
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

interface AssessmentConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const AssessmentConfirmationModal: React.FC<AssessmentConfirmationModalProps> = ({
  open,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ textAlign: "center" }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            id="assessment-confirmation-modal-title"
            variant="h6"
            component="h2"
            sx={{
              marginBottom: '2em',
              color: "#6A6A6A",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "159%",
            }}
          >
            Are you sure you want to skip the final category and submit this assessment?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '10px',
              marginTop: '10px',
            }}
          >
            <SaveButton onClick={handleConfirm}
              sx={{
                padding: "0.5em 1em",
                color: "#FFF",

                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "1em",
                fontStyle: "normal",
                fontWeight: 700,
              }}>
              Yes, Submit
            </SaveButton>
            <CancelButton onClick={handleClose}
              sx={{
                padding: "0.5em 1em",

                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "1em",
                fontStyle: "normal",
                fontWeight: 700,
              }}>
              {translation.cancel}
            </CancelButton>
          </Box>
        </Box>
      </Fade>
    </Modal >
  );
};

export default AssessmentConfirmationModal;
