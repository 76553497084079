import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { GetAllUsersReducer, ApplicationUser } from '../types';
import { storeKey } from '../constants';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: GetAllUsersReducer = {
  status: RequestStatus.Init,
  users: null,
};

const getAllUsersSlice = createSlice({
  name: 'getAllUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllApplicationUsers.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getAllApplicationUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(getAllApplicationUsers.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const getAllUsersReducer = getAllUsersSlice.reducer;

export const getAllApplicationUsers = createAsyncThunk<ApplicationUser[], void>(
  `${storeKey}/users/getAllUsers`,
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<ApplicationUser[]> = await getAllUsers();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllUsers = async (): Promise<
  AxiosResponse<ApplicationUser[]>
> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return axiosInstance.get(`${EndPoints.GET_ALL_USERS}`);
};
