import { useTheme, createTheme } from '@mui/material/styles';

export const useTextTheme = () => {
  const currentTheme = useTheme();

  const textTheme = createTheme(currentTheme, {
    typography: {
      fontFamily: `'Poppins'`,
    },
  });

  return textTheme;
};