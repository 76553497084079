import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
const MultipleChoiceSelect = ({
  question,
  formData,
  setFormData,
  options,
}: {
  question?: any;
  options?: any;
  formData?: any;
  setFormData?: any;
}) => {
  const handleChange = (ev: string) => {
    setFormData({ ...formData, [question.id]: ev });
  };
  return (
    <Select
      sx={{
        marginTop: '20px',
        marginLeft: '40px',
        width: '300px',
      }}
      size="small"
      value={formData[question.id] || 'Select an option'}
      onChange={(e: SelectChangeEvent) => handleChange(e.target.value)}
    >
      <MenuItem disabled selected value="Select an option">
        Select an option
      </MenuItem>
      {options?.map((option: any, index: number) => (
        <MenuItem key={index} value={option.option}>
          {option.option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultipleChoiceSelect;
