import { Box, Paper, styled, Typography } from '@mui/material';

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const BackgroundCoverImg = styled('img')`
  width: 100%;
  height: 99vh;
  object-fit: cover;
`;

export const ContentCard = styled(Box)`
  position: absolute;
  top: 5%;
  margin-inline: 5%;
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    top: 25%;
  }
`;

export const PrimaryText = styled(Typography)`
  text-transform: uppercase;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  margin-top: 10%;
`;

export const BusinessText = styled(Typography)`
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  line-height: 35px;
`;

export const PrimaryColorBoldText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
}));

export const LogoImg = styled('img')`
  width: 100%;
  height: auto;
`;

export const FormContainer = styled(Box)`
  margin-top: 10%;
  width: 100%;
  min-width: 330px;
  display: block;
`;

export const Headline = styled(Typography)`
  font-size: 27px;
  font-weight: 600;
  text-align: left;
  line-height: 50px;
`;

export const Paragraph = styled(Typography)`
  font-size: 19px;
  font-weight: 400;
  text-align: left;
  line-height: 25px;
  color: #00314b;
  width: 650px;
  min-width: 330px;
`;