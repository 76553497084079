import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { getAssessments } from '../reducers/getAssessments';

interface assessmentsListProp {
  loading: boolean;
  error: unknown;
  getAllAssessments: () => void;
}

const useGetAssessments = (): assessmentsListProp => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const getAllAssessments = async () => {
    try {
      const resultAction = await dispatch(
        getAssessments(),
      );
      if (getAssessments.fulfilled.match(resultAction)) {
        setLoading(false);
      }
      return resultAction;
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, getAllAssessments: getAllAssessments };
};

export default useGetAssessments;
