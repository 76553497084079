import React from 'react';
import { Box } from '@mui/material';
import Overview from '../../../components/Accounts/Overview/Overview';
import { AccountDetails } from '../types';

const ClientOverview = (prop: { id: AccountDetails }) => {
  return (
    <Box>
      <Overview accountDetails={prop.id} />
    </Box>
  );
};

export default ClientOverview;
