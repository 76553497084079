/* eslint-disable indent */
import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../../redux/store.type';
import { CustomAccountBranding } from '../../Branding/type';
import { useNavigate } from 'react-router-dom';
import useUserSummary from '../hooks/useUserSummary';
import useDebounce from '../../../hooks/useDebounce';
import MetricBox from '../../../components/DataChart/MetricBox';
import { useBranding } from '../../Branding/reducers/getBranding';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface AeDashboardV2Props {
    branding: CustomAccountBranding | null;
  }

const AeDashboardV2: React.FC<AeDashboardV2Props> = ({ branding }) => {
    const { getSummary } = useUserSummary();

    useEffect(() => {
        getSummary();
    }, []);

    const userSummary = useSelector((state: RootStoreType) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return state.authReducers.userSummary.userSummary;
    });

    const gridRowSpace = 4;

    const mockObject = [
        { name: 'January 2023', Pillar1: 4, Pillar2: 11, Pillar3: 6 },
        { name: 'June 2023', Pillar1: 14, Pillar2: 23, Pillar3: 31 },
        { name: 'January 2024', Pillar1: 23, Pillar2: 23, Pillar3: 33 },
        { name: 'June 2024', Pillar1: 45, Pillar2: 31, Pillar3: 44 },
    ];

    return (
        <Box sx={{ maxWidth: '1540px' }}>
            {userSummary ? (
                <Grid container spacing={2} sx={{ mb: 5 }}>
                    <Grid item xs={12} md={9}>
                        <Box
                            sx={{
                                marginBottom: 5,
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                sx={{ mb: 0, alignItems: 'center', justifyContent: 'center' }}
                            >
                                {branding?.company_logo_secondary && (
                                    <Grid
                                        item
                                        xs={4}
                                        style={{ display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <img
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                src={require('../../../assets/Impact_Mep_Region3.png')}
                                                alt="Company Profile Logo"
                                            />
                                        </Box>
                                    </Grid>
                                )}
                                {/* {primaryContact?.primary_contact_photo && ( */}
                                <Grid
                                    item
                                    xs={5}
                                    style={{ display: 'flex', justifyContent: 'right' }}
                                >
                                    <Box
                                        sx={{
                                            width: '110px',
                                            height: '110px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            overflow: 'hidden',
                                            borderRadius: '50%',
                                        }}
                                    >
                                        <img
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'cover',
                                            }}
                                            src={require('../../../assets/users/user2.png')}
                                            alt="Account Manager"
                                        />
                                    </Box>
                                </Grid>
                                {/* )} */}
                                <Grid item xs={3}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                            color: '#0e274c',
                                        }}
                                    >
                                        Hello Anna
                                    </Typography>

                                    {/* {primaryContact?.name && ( */}
                                    <Typography variant="body2">
                                        Account Manager
                                        {/* {primaryContact.name} */}
                                    </Typography>
                                    {/* )} */}

                                    {/* {primaryContact?.phone && ( */}
                                    <Typography variant="body2">
                                        (000)000.000
                                        {/* {primaryContact.phone} */}
                                    </Typography>
                                    {/* )} */}

                                    {/* {primaryContact?.email && ( */}
                                    <Typography variant="body2">
                                        Anna@IMR3.com
                                        {/* {primaryContact.email} */}
                                    </Typography>
                                    {/* )} */}
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid container sx={{ mb: 3 }}>
                            <Grid item>
                                <Typography
                                    variant="h2"
                                    component="div"
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: '#0e274c',
                                    }}
                                >
                                    Performance Summary
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container columnGap={1.5} sx={{ mb: 3 }}>
                            <Grid container columnGap={1.1} xs={12}>
                                <Grid
                                    item
                                    xs={3.9}
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#0e274c',
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        Clients
                                    </Typography>
                                    <img
                                        style={{
                                            paddingBottom: 2,
                                            maxWidth: '45px',
                                            // height: '30px',
                                            justifyContent: 'right',
                                        }}
                                        src={require('../../../assets/salesforce-logo.png')}
                                        alt=""
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3.9}
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#0e274c',
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        Assessments
                                    </Typography>
                                    <img
                                        style={{
                                            paddingBottom: 2,
                                            maxWidth: '45px',
                                            // height: '30px',
                                            justifyContent: 'right',
                                        }}
                                        src={require('../../../assets/vessel-logo.png')}
                                        alt=""
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3.9}
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#0e274c',
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        Projects
                                    </Typography>
                                    {/* <img
                    style={{
                      paddingBottom: 2,
                      maxWidth: '45px',
                      //height: '30px',
                      justifyContent: 'right',
                    }}
                    src={require('../../../assets/quickbooks-logo.png')}
                    alt=""
                  /> */}
                                </Grid>
                            </Grid>

                            <Grid container spacing={0.3} xs={3.9}>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="Total" metricValue={21} percent="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="Active" metricValue={6} percent="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="Leads" metricValue={12} percent="" />
                                </Grid>
                            </Grid>

                            <Grid container spacing={0.3} xs={3.9}>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="Total" metricValue={210} percent="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="YTD" metricValue={17} percent="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="Active" metricValue={3} percent="" />
                                </Grid>
                            </Grid>

                            <Grid container spacing={0.3} xs={3.9}>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="Total" metricValue={43} percent="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="YTD" metricValue={9} percent="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="Active" metricValue={3} percent="" />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            style={{
                                border: '1px solid #CDCDCD',
                                borderRadius: '16px',
                                padding: '20px 8px 10px 8px',
                                marginBottom: '24px',
                            }}
                        >
                            <Typography
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    margin: '0 0 15px 0',
                                    color: '#0e274c'
                                }}
                            >
                                Performance Over Time: Business Assessment (V1.1)
                            </Typography>

                            <Grid item xs={8}>
                                <Typography
                                    component="div"
                                    sx={{ fontSize: 12 }}
                                    style={{
                                        color: '#B4B4B4',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    0 - 20 Deeper Understanding &nbsp; &nbsp; 21 - 40 Needs
                                    Improvement &nbsp; &nbsp; 41 - 50 Meets Expectations
                                </Typography>
                                <LineChart
                                    width={580}
                                    height={250}
                                    data={mockObject}
                                    margin={{
                                        top: 10,
                                        right: 10,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="name"
                                        padding={{ left: 30, right: 30 }}
                                        style={{ fontFamily: 'Roboto', fontSize: 14 }}
                                    />
                                    <YAxis style={{ fontFamily: 'Roboto', fontSize: 14 }} />
                                    <Tooltip />

                                    <Line
                                        type="monotone"
                                        dataKey="Pillar1"
                                        stroke="#3787bc"
                                        activeDot={{ r: 8 }}
                                    />
                                    <Line type="monotone" dataKey="Pillar2" stroke="#93241b" />
                                    <Line type="monotone" dataKey="Pillar3" stroke="#0e274c" />
                                </LineChart>
                            </Grid>
                            <Grid item xs={4}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ color: '#3787bc', fontSize: '24px' }}>
                                                {'\u25A0'}
                                            </TableCell>
                                            <TableCell sx={{ padding: '5px', color: '#0e274c' }}>
                                                Business Development & Strategy
                                            </TableCell>
                                            <TableCell sx={{ color: '#0e274c' }}>36%</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ color: '#93241b', fontSize: '24px' }}>
                                                {'\u25A0'}
                                            </TableCell>
                                            <TableCell sx={{ padding: '5px', color: '#0e274c' }}>
                                                Operational Excellence & Efficiency
                                            </TableCell>
                                            <TableCell sx={{ color: '#0e274c' }}>26%</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ color: '#0e274c', fontSize: '24px' }}>
                                                {'\u25A0'}
                                            </TableCell>
                                            <TableCell sx={{ padding: '5px', color: '#0e274c' }}>
                                                Technology Integration & Workforce Development
                                            </TableCell>
                                            <TableCell sx={{ color: '#0e274c' }}>46%</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    color: 'green',
                                                    fontSize: '30px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {'\u21EA'}
                                            </TableCell>
                                            <TableCell sx={{ padding: '5px' }}>
                                                Overall Improvement
                                            </TableCell>
                                            <TableCell>26%</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>

                        <Grid container columnGap={1.5} sx={{ mb: 3 }}>
                            <Grid container columnGap={1.1} xs={12}>
                                <Grid item xs={3.9}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#0e274c',
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        Assessment to Project Conversion
                                    </Typography>
                                </Grid>
                                <Grid item xs={3.9}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#0e274c',
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        Usage
                                    </Typography>
                                </Grid>
                                <Grid item xs={3.9}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#0e274c',
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        Response & Completion
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={0.3} xs={3.9}>
                                <Grid item xs={4}>
                                    <MetricBox metricTitle="Total" metricValue={46} percent="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <MetricBox
                                        metricTitle="Conversion"
                                        metricValue={71}
                                        percent="%"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MetricBox
                                        metricTitle="Potential"
                                        metricValue={3}
                                        percent=""
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={0.3} xs={3.9}>
                                <Grid item xs={6}>
                                    <MetricBox
                                        metricTitle="Min to Take"
                                        metricValue={14}
                                        percent=""
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MetricBox
                                        metricTitle="Days to Close"
                                        metricValue={13}
                                        percent=""
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={0.3} xs={3.9}>
                                <Grid item xs={6}>
                                    <MetricBox metricTitle="Total" metricValue={62} percent="%" />
                                </Grid>
                                <Grid item xs={6}>
                                    <MetricBox metricTitle="Team" metricValue={86} percent="%" />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container columnGap={1.5} mb={3}>
                            <Grid
                                container
                                xs={5.9}
                                sx={{
                                    background: '#E3e4e4',
                                    borderRadius: '5px',
                                    padding: '10px',
                                }}
                            >
                                <Grid item sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#0e274c',
                                            fontSize: 18,
                                        }}
                                    >
                                        All Assessments
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontSize: '12px', color: '#0e274c' }}
                                    >
                                        Comprehensive view of individual assessments, categorized by
                                        type. Each assessment is clearly marked to reflect its
                                        current status, ensuring you can easily track progress and
                                        completion. Dive into specific details by selecting an
                                        assessment type.
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    columnGap={1.5}
                                    justifyContent={'center'}
                                    xs={12}
                                    sx={{
                                        background: '#fff',
                                        border: '1px solid #959696',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <Grid item xs={3} alignContent={'center'}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                background: '#D6D7D7',
                                                borderRadius: '5px',
                                                padding: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            In Progress
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '14px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Business Assessment (V 1.1) Third Taken
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Start to Close: Dec 15, 2024 - Jan 01, 2025 (Duration 17
                                            Days)
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnGap={1.5}
                                    justifyContent={'center'}
                                    xs={12}
                                    sx={{
                                        marginBottom: '5px',
                                        background: '#fff',
                                        border: '1px solid #959696',
                                        borderRadius: '5px',
                                        padding: '10px',
                                    }}
                                >
                                    <Grid item xs={3} alignContent={'center'}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                background: '#93c47d',
                                                borderRadius: '5px',
                                                padding: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                marginBottom: '10px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Complete
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            sx={{ fontSize: '11px', color: '#0e274c' }}
                                        >
                                            Download Report
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '14px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Business Assessment (V 1.1) Third Taken (Duration 146
                                            Days)
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Start to Close: Dec 15, 2024 - Jan 01, 2025 (Duration 17
                                            Days)
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            13 Responses
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                xs={5.9}
                                sx={{
                                    background: '#E3e4e4',
                                    borderRadius: '5px',
                                    padding: '10px',
                                }}
                            >
                                <Grid item sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#0e274c',
                                            fontSize: 18
                                        }}
                                    >
                                        Projects
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontSize: '12px', color: '#0e274c' }}
                                    >
                                        High level view of current project status and stages.
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    columnGap={1.5}
                                    justifyContent={'center'}
                                    xs={12}
                                    sx={{
                                        background: '#fff',
                                        border: '1px solid #959696',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <Grid item xs={3} alignContent={'center'}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                background: '#D6D7D7',
                                                borderRadius: '5px',
                                                padding: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            In Progress
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            variant="body2"
                                            sx={{ fontSize: '14px', color: '#0e274c' }}
                                        >
                                            Learn Six Sigma Course
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ fontSize: '12px', color: '#0e274c' }}
                                        >
                                            Start: May 25, 2025 Est Close: June 01, 2025
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnGap={1.5}
                                    justifyContent={'center'}
                                    xs={12}
                                    sx={{
                                        marginBottom: '5px',
                                        background: '#fff',
                                        border: '1px solid #959696',
                                        borderRadius: '5px',
                                        padding: '10px',
                                    }}
                                >
                                    <Grid item xs={3} alignContent={'center'}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                background: '#93c47d',
                                                borderRadius: '5px',
                                                padding: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                color: '#0e274c',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Complete
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ fontSize: '11px', color: '#0e274c' }}
                                        >
                                            Download Report
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '14px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Workforce Funding
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Start: May 25, 2025 Est Close: June 01, 2025
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnGap={1.5}
                                    justifyContent={'center'}
                                    xs={12}
                                    sx={{
                                        marginBottom: '5px',
                                        background: '#fff',
                                        border: '1px solid #959696',
                                        borderRadius: '5px',
                                        padding: '10px',
                                    }}
                                >
                                    <Grid item xs={3} alignContent={'center'}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                background: '#93c47d',
                                                borderRadius: '5px',
                                                padding: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                color: '#0e274c',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Complete
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ fontSize: '11px', color: '#0e274c' }}
                                        >
                                            Download Report
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '14px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Cybersecurity Project
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                marginBottom: '3px',
                                                color: '#0e274c',
                                            }}
                                        >
                                            Start: May 25, 2025 Close: June 01, 2025
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*end main content*/}
                    {/* Start sidebar content */}
                    <Grid item xs={12} md={3}>
                        <Box
                            sx={{
                                backgroundColor: '#e5ecf6',
                                padding: '30px 20px',
                                borderRadius: '16px',
                                color: '#0e274c',
                            }}
                        >
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#0e274c',
                                    fontSize: 16,
                                }}
                            >
                                About MEP Center
                            </Typography>
                            <Grid
                                container
                                spacing={2}
                                sx={{ mb: 0 }}
                                justifyContent="center"
                            >
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <img
                                        style={{
                                            width: '80%',
                                            height: 'auto',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                            objectFit: 'contain',
                                            display: 'inline-block',
                                        }}
                                        src={require('../../../assets/Impact_MEP_Logo.png')}
                                        alt=""
                                    />
                                </Grid>
                            </Grid>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    fontSize: 14,
                                    mb: '25px',
                                }}
                            // dangerouslySetInnerHTML={{ __html: branding?.dashboard_mep_description || '' }}
                            >
                                We are here to support small and medium-sized companies across
                                all industries who are committed to investing time, money, and
                                resources to develop and enhance their business operations. Our
                                goal is to help you discover and maximize financial
                                opportunities. Acting as a general consultant, we offer a wealth
                                of knowledge and cater to various industry-specific needs.
                            </Typography>
                            <Grid container rowGap={2} sx={{ mb: '25px' }}>
                                <Grid container xs={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            fontSize: '11px'
                                        }}
                                    >
                                        Director
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: 'flex', justifyContent: 'space-around' }}
                                    >
                                        <img
                                            style={{
                                                width: '65px',
                                                height: '65px',
                                                borderRadius: '50%',
                                            }}
                                            src={require('../../../assets/users/user3.png')}
                                            alt="AE Expert (Primary)"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container xs={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            fontSize: '11px'
                                        }}
                                    >
                                        AE Expert
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: 'flex', justifyContent: 'space-around' }}
                                    >
                                        <img
                                            style={{
                                                width: '65px',
                                                height: '65px',
                                                borderRadius: '50%',
                                            }}
                                            src={require('../../../assets/users/user4.png')}
                                            alt="AE Expert (Primary)"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container xs={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            fontSize: '11px'
                                        }}
                                    >
                                        AE Expert
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: 'flex', justifyContent: 'space-around' }}
                                    >
                                        <img
                                            style={{
                                                width: '65px',
                                                height: '65px',
                                                borderRadius: '50%',
                                            }}
                                            src={require('../../../assets/users/user2.png')}
                                            alt="AE Expert (Primary)"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container xs={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            fontSize: '11px'
                                        }}
                                    >
                                        AE Expert
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: 'flex', justifyContent: 'space-around' }}
                                    >
                                        <img
                                            style={{
                                                width: '65px',
                                                height: '65px',
                                                borderRadius: '50%',
                                            }}
                                            src={require('../../../assets/users/user1.png')}
                                            alt="AE Expert (Primary)"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container xs={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            fontSize: '11px'
                                        }}
                                    >
                                        AE Expert
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: 'flex', justifyContent: 'space-around' }}
                                    >
                                        <img
                                            style={{
                                                width: '65px',
                                                height: '65px',
                                                borderRadius: '50%',
                                            }}
                                            src={require('../../../assets/users/user5.png')}
                                            alt="AE Expert (Primary)"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container sx={{ mb: 0, fontFamily: 'helvetica' }}>
                                <Grid item xs={12}>
                                    <h5>Other MEP Assessment Options:</h5>
                                    <ul style={{ fontSize: '14px', marginBottom: '15px' }}>
                                        <li>General</li>
                                        <li>Workforce</li>
                                        <li>Cyber-Security</li>
                                        <li>Industry 4.0</li>
                                    </ul>
                                    <h5>MEP Services</h5>
                                    <ul style={{ fontSize: '14px', marginBottom: '15px' }}>
                                        <li>Partner & Funding Opportunities</li>
                                        <li>Education Opportunities</li>
                                        <li>Workshops</li>
                                        <li>Process Improvement Training</li>
                                    </ul>
                                    <h5>Upcoming Events</h5>
                                    <ul style={{ fontSize: '14px', marginBottom: '15px' }}>
                                        <li>Manufacturing B2B Tradeshow</li>
                                        <li>Innovation & Technology Summit</li>
                                        <li>American Food Manufacturing Summit</li>
                                        <li>Advanced Technology Workshop</li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {/* end sidebar content */}
                </Grid>
            ) : (
                <Box sx={{ margin: '25px 35%', fontWeight: '600', color: '#A3A3A3' }}>
                    {'No Evaluations available'}
                </Box>
            )}
        </Box>
    );
};

export default AeDashboardV2;