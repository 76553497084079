import { useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import { deleteEvaluationThunk } from '../reducers/deleteEvaluation';

const useDeleteEvaluation = (): {
  deleteEvaluationById: (id: string) => void;
  error: unknown;
  loading: boolean;
  success: boolean;
} => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const [success, setSuccess] = useState(false);

  const deleteEvaluationById = async (id: string) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(deleteEvaluationThunk(id));
      if (deleteEvaluationThunk.fulfilled.match(resultAction)) {
        setSuccess(true);
        setLoading(false);
      } else {
        setSuccess(false);
      }
    } catch (error) {
      setError(error);
      setSuccess(false);
    }finally{
      setLoading(false)
    }
  };
  return { deleteEvaluationById, loading, error, success };
};

export default useDeleteEvaluation;
