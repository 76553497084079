import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { AssessmentQuestion } from '../../../Assessments/type';
import { SingleQuestionResponse } from '../../type';

interface TextEntryInputProps {
  question: AssessmentQuestion;
  formData: SingleQuestionResponse[];
  onChange: (questionId: number, selectedAnswerId?: number, answerText?: string) => void;
}

export default function TextEntryInput({
  question,
  formData,
  onChange,
}: TextEntryInputProps) {
  const currentAnswer = formData.find(answer => answer.assessment_question_id === question.id);

  // Handle change using the centralized onChange method from the parent
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange(question.id, undefined, ev.target.value);
  };

  return (
    <FormControl sx={{ marginTop: '20px', marginLeft: '40px' }}>
      <TextField
        id={`text-field-${question.id}`}
        variant="standard"
        multiline
        sx={{
          width: '800px',
        }}
        placeholder="Type your answer here"
        value={currentAnswer?.answer || ''}
        onChange={handleChange}
      />
    </FormControl>
  );
}
