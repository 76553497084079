import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import {
  assignAccountRole,
  AssignAccountRolePayload,
} from '../reducers/assignAccountRole';
import { useState } from 'react';

interface PostAccountRoleProps {
  loading: boolean;
  error: unknown;
  postAccountRole: (requestPayload: AssignAccountRolePayload[]) => void;
}

const usePostAccountRole = (): PostAccountRoleProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const postAccountRole = async (
    requestPayload: AssignAccountRolePayload[],
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(assignAccountRole(requestPayload));
      if (assignAccountRole.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };
  return { postAccountRole, loading, error };
};

export default usePostAccountRole;
