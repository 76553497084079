import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, Cell } from 'recharts';
import usePostEvaluationResponseBreakdown from '../../hooks/usePostEvaluationResponseBreakdown';
import { EvaluationQuestionsResponses } from '../../type';
import { useAppSelector } from '../../../../redux/store';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

// V2 counterpart needs to be made for this
// -postResponseBreakdown

interface IndividualResponseModal {
  open: boolean;
  handleClose: () => void;
  evaluationId: string;
  questionCategoryId: string;
  questionCategory: string;
}

interface ChartData {
  name: string;
  responses: number;
  color: string;
}

// This function maps the scores to the labels for the bar chart
const scoreToLabelMap: { [key: number]: string } = {
  1: 'Strongly Disagree',
  2: 'Disagree',
  3: 'Neutral',
  4: 'Agree',
  5: 'Strongly Agree',
};

const barColors: { [key: string]: string } = {
  'Strongly Disagree': '#FF285C',
  'Disagree': '#F0BB00',
  'Neutral': '#000',
  'Agree': '#C6C7F8',
  'Strongly Agree': '#BAEDBD'
};

const ResponseModal: React.FC<IndividualResponseModal & { currentIndex: number, setCurrentIndex: React.Dispatch<React.SetStateAction<number>> }> =  ({
  open, handleClose, evaluationId, questionCategoryId, questionCategory, currentIndex, setCurrentIndex
}) => {

  const initialChartData: ChartData[] = Object.entries(scoreToLabelMap).map(([score, label]) => ({
    name: label,
    responses: 0,
    color: "#d32f2f"
  }));

  // State to hold the processed data for the chart
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const { postResponseBreakdown } = usePostEvaluationResponseBreakdown();

  const evaluationResponseBreakdown: EvaluationQuestionsResponses | null =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationResponseBreakdownReducer
        .evaluationQuestionsResponses;
    });

  const goToNextResponse = () => {
    if (evaluationResponseBreakdown) {
      setCurrentIndex(prevIndex => {
        const newIndex = prevIndex + 1;
        return newIndex < evaluationResponseBreakdown.evaluation_questions_responses.length ? newIndex : prevIndex;
      });
    }
  };

  const goToPreviousResponse = () => {
    setCurrentIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : 0);
  };

  React.useEffect(() => {
    if (open) {
      postResponseBreakdown({
        evaluation_id: evaluationId,
        question_category_id: questionCategoryId,
      });
    }
  }, [open, evaluationId, questionCategoryId]);

  React.useEffect(() => {
    if (open && evaluationResponseBreakdown && evaluationResponseBreakdown.evaluation_questions_responses.length > currentIndex) {
      const individualAnswers = evaluationResponseBreakdown.evaluation_questions_responses[currentIndex].individual_answers;

      const newChartData = initialChartData.map(data => ({
        ...data,
        color: barColors[data.name],
      }));

      // Tally the responses
      individualAnswers.forEach((answer) => {
        const index = answer - 1;
        if (index >= 0 && index < newChartData.length) {
          newChartData[index].responses += 1;
        }
      });

      setChartData(newChartData);
      setCurrentQuestion(evaluationResponseBreakdown.evaluation_questions_responses[currentIndex].question);
      setCurrentQuestionNumber(currentIndex + 1)

    }
  }, [open, evaluationResponseBreakdown, currentIndex]);

  const maxYAxisValue = evaluationResponseBreakdown?.evaluation_questions_responses[currentIndex]?.individual_answers.length || 20;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="chart-modal-title"
      aria-describedby="chart-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          outline: 0,
          backgroundColor: 'white',
          padding: 4,
          borderRadius: 2,
          boxShadow: 24,
          width: '100%',
          maxWidth: '80vw',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Typography
          id="chart-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {questionCategory}
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis label={{
              value: "Total Responses",
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle', fontWeight: 'bold' },
            }}
            domain={[0, maxYAxisValue]}
            />
            <Tooltip />
            <Bar dataKey="responses">
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Button onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0, color: "grey" }}>
          Close X
        </Button>
        <Typography
          id="chart-modal-title"
          variant="h6"
          component="h2"
          sx={{
            textAlign: 'start',
          }}
        >
          <strong>Q:{currentQuestionNumber}.</strong>
        </Typography>
        <Typography
          id="chart-modal-title"
          variant="h6"
          component="h2"
          sx={{
            textAlign: 'start',
          }}
        >
          {currentQuestion}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 2,
          }}
        >
          <Button
            onClick={goToPreviousResponse}
            disabled={currentIndex === 0 || !evaluationResponseBreakdown}
            sx={{
              marginX: 1,
            }}
          >
            <ArrowBackIosNew />
            Previous
          </Button>
          <Button
            onClick={goToNextResponse}
            disabled={!evaluationResponseBreakdown || currentIndex === evaluationResponseBreakdown?.evaluation_questions_responses.length - 1}
            sx={{
              marginX: 1,
            }}
          >
            Next
            <ArrowForwardIos />
          </Button>
        </Box>

      </Box>
    </Modal>
  );
};

export default ResponseModal;
