import React, { CSSProperties } from 'react';
import { AssessmentCreation } from '../../../modules/Assessments/type';


export const mapToAssessmentCreation = (
  accountId: string,
  assessmentDefinitionId: number,
  assessmentName: string,
  startDate: string,
  endDate: string,
  targetResponses: number,
  groupId: number,
  isChildAssessment?: boolean,
  end_assessment_when_target_responses?: boolean,

): AssessmentCreation => {
  return {
    name: assessmentName,
    start_date: startDate,
    end_date: endDate,
    target_responses: targetResponses,
    status: 'IP',
    send_email_reminders: false,
    account_id: accountId,
    assessment_definition_id: assessmentDefinitionId,
    assessment_group: groupId,
    is_child_assessment: isChildAssessment ?? false,
    end_assessment_when_target_responses: end_assessment_when_target_responses ?? false,
  };
};

const MenuHelper = {
  PaperProps: {
    style: {
      maxHeight: 48 * 8, // 48px is the default height of a MenuItem, showing 8 items
      overflowY: 'auto' as CSSProperties['overflowY'], // Enables scrolling for items beyond 8
    }
  }
};

export default MenuHelper;
