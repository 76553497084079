import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { EvaluationMCAnswersResponse } from '../type';

export interface EvaluationAnswersPayload {
  evaluation_id: string;
  question_category_id: string;
}

export interface EvaluationMCAnswersReducer {
  status: RequestStatus;
  evaluationMCAnswersResponse: EvaluationMCAnswersResponse | null;
}

const initialState: EvaluationMCAnswersReducer = {
  status: RequestStatus.Init,
  evaluationMCAnswersResponse: null,
};

const postEvaluationMCAnswersSlice = createSlice({
  name: 'evaluation/postEvaluationMCAnswers',
  initialState,
  reducers: {
    resetEvalutaionMCAnswersState: (state) => {
      state.evaluationMCAnswersResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postGetEvaluationMCAnswers.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postGetEvaluationMCAnswers.fulfilled, (state, action) => {
      state.evaluationMCAnswersResponse = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(postGetEvaluationMCAnswers.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const postGetEvaluationMCAnswers = createAsyncThunk<
EvaluationMCAnswersResponse,
  EvaluationAnswersPayload
>(
  'postEvaluationMCAnswers',
  async (
    evaluationPayload: EvaluationAnswersPayload,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<EvaluationMCAnswersResponse> =
        await axiosInstance.post<EvaluationMCAnswersResponse>(
          EndPoints.POST_EVALUATION_RESULT_MC_ANSWERS,
          evaluationPayload,
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { resetEvalutaionMCAnswersState } =
  postEvaluationMCAnswersSlice.actions;

export default postEvaluationMCAnswersSlice.reducer;
