import { useAppDispatch } from '../../../redux/store';
import { useState } from 'react';
import {
  putEvaluationNotesThunk,
  evaluationNotesRequest,
} from '../reducers/putEvaluationNotes';

type evaluationNotesProp = {
  loading: boolean;
  error: unknown;
  updateEvaluationNotes: (
    evaluationNotesRequest: evaluationNotesRequest,
  ) => void;
};

const usePutEvaluationNotes = (): evaluationNotesProp => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);

  const updateEvaluationNotes = async (
    evaluationNotesRequest: evaluationNotesRequest,
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        putEvaluationNotesThunk(evaluationNotesRequest),
      );
      if (putEvaluationNotesThunk.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };
  return { error, loading, updateEvaluationNotes };
};

export default usePutEvaluationNotes;
