import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { OverallScores } from '../../type';

type ScoresByCategoryId = {
  [category: string]: OverallScores
};

const colors = ["#3a6da0", "#308139", "#e29562", "#cc66c0", "#6ac291", "#c2a966", "#6a92cc"];

interface CategoryDetails {
  overall_scores: OverallScores;
  individual_answer_score: Array<{
    question: string;
    score: number;
  }>;
  average_evaluation_score: number;
  target_responses: number;
  responses_received: number;
}

interface DataStructure {
  [category: string]: CategoryDetails;
}

interface EvaluationAggregateDetails {
  evaluationQuestionCategoryId: string;
  overall_scores: OverallScores;
}

interface EvaluationAggregates {
  evaluationCategoryId: string;
  evaluationQuestionCategories: EvaluationAggregateDetails[];
}

export default function DistributionGraph({ data, evaluationAggregates }: { data: DataStructure, evaluationAggregates: EvaluationAggregates }) {
  const categories = Object.keys(data);

  const categoryStyles = categories.reduce((acc, category, index) => {
    acc[category] = { color: colors[index % colors.length], stroke: 2 };
    return acc;
  }, {});

  const scoresByCategoryId: ScoresByCategoryId = evaluationAggregates.evaluationQuestionCategories.reduce((acc: Record<string, OverallScores>, category) => {
    acc[category.evaluationQuestionCategoryId] = category.overall_scores;
    return acc;
  }, {});

  const sentimentLevelsOrder = [
    "Strongly Disagree",
    "Disagree",
    "Neutral",
    "Agree",
    "Strongly Agree"
  ];

  if (Object.keys(scoresByCategoryId).length === 0) {
    return <div>Insufficient data</div>;
  }

  const chartData = sentimentLevelsOrder.map((level, index) => {
    const sentimentData = {
      name: (index + 1).toString(),
      value: (index + 1).toString()
    };

    // Populate sentiment data for each category based on the defined order
    Object.keys(scoresByCategoryId).forEach(categoryId => {
      sentimentData[categoryId] = scoresByCategoryId[categoryId][level];
    });

    return sentimentData;
  });

  // TODO: Currently rounds the score so it can plot your Dot. Will need to be updated once we update the distributionGraph and the data that goes into it
  const CustomDot = ({ cx, cy, stroke, payload, score }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const roundedScore = Math.round(score);
    const roundedPayloadValue = Math.round(Number(payload.value));

    if (roundedPayloadValue === roundedScore) {
      return <circle cx={cx} cy={cy} r={4} stroke={stroke} strokeWidth={2} fill={stroke} />;
    }
    return null;
  };

  type ChartDataItem = {
    name: string;
    value: string;
    [key: string]: number | string | null | undefined;
  };

  const getMaxRoundedEvenValue = (data: ChartDataItem[]) => {
    const maxValues: number[] = data.map(item => {
      const numericValues = Object.entries(item)
        .filter(([key, value]) => key !== 'name' && key !== 'value' && typeof value === 'number')
        .map(([_, value]) => value as number);

      return Math.max(...numericValues, 0);
    });

    const maxValue = Math.max(...maxValues, 0);
    return Math.ceil(maxValue / 2) * 2;
  };

  const maxDomainValue = getMaxRoundedEvenValue(chartData);
  const tickInterval = maxDomainValue / 8;
  const ticks = Array.from({ length: 9 }, (_, i) => i * tickInterval);

  // Adjust the maxDomainValue to be the last tick value to avoid having an additional number
  const adjustedMaxDomainValue = ticks[ticks.length - 1];

  return (
    <AreaChart
      key={JSON.stringify(chartData)}
      width={900}
      height={450}
      data={chartData}
      margin={{
        top: 5,
        right: 5,
        left: 5,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        label={{
          value: 'Score',
          position: 'bottom',
          offset: 0,
          style: { fontWeight: 'bold' }
        }}
      />
      {
        maxDomainValue > 0 ? (
          <YAxis
            type="number"
            domain={['dataMin', adjustedMaxDomainValue]}
            allowDataOverflow={false}
            ticks={ticks}
            tickFormatter={(value: number) => Math.round(value).toString()}
            label={{
              value: 'Count',
              angle: -90,
              position: 'center',
              style: { fontWeight: 'bold' },
              dx: -15,
              dy: 0,
            }}
          />
        ) : (
          <YAxis
            type="number"
            domain={[0, 1]}
            allowDataOverflow={false}
            ticks={[0]}
            tickFormatter={(value: number) => Math.round(value).toString()}
            label={{
              value: 'Count',
              angle: -90,
              position: 'center',
              style: { fontWeight: 'bold' },
              dx: -15,
              dy: 0,
            }}
          />
        )
      }

      <Tooltip />
      <Legend iconType="circle" wrapperStyle={{ bottom: -20 }} />

      {categories.map(category => (
        <Area
          key={category}
          type="monotone"
          dataKey={category}
          stroke={categoryStyles[category].color}
          strokeWidth={categoryStyles[category].stroke}
          fillOpacity={0}
          fill={categoryStyles[category].color}
          dot={(props) => <CustomDot {...props} score={data[category].average_evaluation_score} />}
        />
      ))}
    </AreaChart>
  );
}