import { useDispatch } from 'react-redux';
import { getPublicAssessmentDetails } from '../reducers/getPublicAssessmentDetails';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { PublicGetAssessmentErrorResponse } from '../type'

interface useGetPublicAssessmentDetailsProps {
    loading: boolean;
    error: string | null;
    getPublicDetails: (id: string) => void;
}

const useGetPublicAssessmentDetails = (): useGetPublicAssessmentDetailsProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const getPublicDetails = async (id: string) => {
        try {
            setLoading(true);
            setError(null);

            const resultAction = await dispatch(getPublicAssessmentDetails(id));

            if (getPublicAssessmentDetails.fulfilled.match(resultAction)) {
                setLoading(false);
            } else if (getPublicAssessmentDetails.rejected.match(resultAction)) {
                setLoading(false);

                const errorResponse = resultAction.payload as PublicGetAssessmentErrorResponse;

                if (errorResponse && errorResponse.error) {
                    setError(errorResponse.error); // Display the custom error message
                } else {
                    setError('An unknown error occurred');
                }
            }
        } catch (err) {
            setLoading(false);
            setError('An unexpected error occurred');
        }
    };

    return { error, loading, getPublicDetails };
};

export default useGetPublicAssessmentDetails;