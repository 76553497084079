import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { CreateEvaluationType } from '../type';
import { postEvaluation } from '../reducers/postEvaluation';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const usePostEvaluation = (): { postEvaluationDetails: (evaluationPayload: CreateEvaluationType) => void, loading: boolean, error: unknown } => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>()
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const postEvaluationDetails = async (
    evaluationPayload: CreateEvaluationType,
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(postEvaluation(evaluationPayload));
      if (postEvaluation.fulfilled.match(resultAction)) {
        navigate('/evaluation-assessment-list');
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  };

  return { postEvaluationDetails, loading, error };
};

export default usePostEvaluation;
