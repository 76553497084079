import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import {
  postEvaluationAssessorScore
} from '../reducers/postEvaluationAssessorScore';
import { EvaluationAssessorScoreData } from '../type';
import { toast } from 'react-toastify';

const usePostEvaluationAssessorScore = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const postEvaluationAssessorScores = async (
    requestPayload: EvaluationAssessorScoreData[],
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(postEvaluationAssessorScore({assessor_score_data: requestPayload}));
      if (postEvaluationAssessorScore.fulfilled.match(resultAction)) {
        setLoading(false);
        // toast.success('Evaluation Assessor Scores Updated Successfully');
      }
      if (postEvaluationAssessorScore.rejected.match(resultAction)) {
        toast.error("Evaluation Assessor Scores Couldn't Update");
      }
    } catch (error) {
      setError(error);
      toast.error("Evaluation Assessor Scores Couldn't Update");
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, postEvaluationAssessorScores };
};

export default usePostEvaluationAssessorScore;
