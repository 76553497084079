import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';

export interface DeleteAccountRoleInitialState {
  account_setting_id: string;
}

const initialState: { status: RequestStatus } = {
  status: RequestStatus.Init,
};

const deleteAccountSettingsRoleSlice = createSlice({
  name: 'deleteAccountSettingsRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteAccountSettingsRole.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(deleteAccountSettingsRole.fulfilled, (state) => {
      state.status = RequestStatus.Done;

      // toast.success('Removed Role Successfully!');
    });
    builder.addCase(deleteAccountSettingsRole.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      state.status = RequestStatus.Error;
      const errorResponses = (action.payload as AxiosError).response?.data as {
        error: string;
      };

      toast.error(errorResponses.error);
    });
  },
});

export const deleteAccountSettingsRoleSliceReducer =
  deleteAccountSettingsRoleSlice.reducer;

export const deleteAccountSettingsRole = createAsyncThunk<
  string,
  DeleteAccountRoleInitialState
>('deleteAccountSettingsRole/delete', async (payload, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<string> = await deleteAccountRole(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const deleteAccountRole = async (
  payload: DeleteAccountRoleInitialState,
): Promise<AxiosResponse<string>> => {
  return axiosInstance.delete(`${EndPoints.ACCOUNT_SETTING}`, {
    data: payload,
  });
};
