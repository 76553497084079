import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getUserSummary } from '../reducers/getUserSummary';
import { UserSummary } from '../type';


interface actionStateProps {
  loading: boolean;
  error: unknown;
  getSummary: ()=> Promise<UserSummary | undefined>;
}



const useUserSummary = (): actionStateProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const getSummary = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(getUserSummary());
      if (getUserSummary.fulfilled.match(resultAction)) {
        setLoading(false);
        return resultAction.payload;
      }
    } catch (error) {
      setError(error);
    }
  };

  return { loading, error, getSummary };
};

export default useUserSummary;
