import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useBranding } from '../Branding/reducers/getBranding';
import { useAppSelector } from '../../redux/store';
import useGetSuppliers from './hooks/useGetSuppliers';
import useGetNaicsCodes from './hooks/useGetNaicsCodes';
import MapChart from './Components/MapChart';
import { useNavigate } from 'react-router-dom';
import {FeatureFlags} from '../Branding/type'

const Scoin = () => {
  const navigate = useNavigate();
  const branding = useBranding();
  const scoinAccess = branding?.active_feature_flags?.[FeatureFlags.SCOIN] === true;
  const { loading, error, getSuppliers } = useGetSuppliers();
  const { getNaicsCodes } = useGetNaicsCodes();

  useEffect(() => {
    if (!scoinAccess) {
      navigate('/account-list');
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const suppliers = useAppSelector((state) => state.scoinReducers.getSuppliersReducer.scoinSuppliers);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const naicsCodes = useAppSelector((state) => state.scoinReducers.getNaicsCodesReducer.scoinNaicsCodes);

  useEffect(() => {
    if (scoinAccess) {
      getSuppliers();
      getNaicsCodes();
    }
  }, [scoinAccess, getSuppliers, getNaicsCodes]);

  const handleSupplierChange = () => {
    if (scoinAccess) {
      getSuppliers();
    }
  };

  if (!scoinAccess) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'calc(100vh - 140px)',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {error || loading || !suppliers || !naicsCodes ? (
        <CircularProgress sx={{ margin: '20vh 48%' }} />
      ) : (
        <MapChart suppliers={suppliers.suppliers} naicsCodes={naicsCodes?.naics_codes} onSupplierChange={handleSupplierChange} />
      )}
    </Box>
  );
};

export default Scoin;
