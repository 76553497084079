import { store, useAppDispatch } from '../../../redux/store';
import { clearClientList } from '../../Accounts/reducers/getClientList';
import { clearAssessmentDetails } from '../reducers/getAssessmentDetails';
import { putAssessmentThunk } from '../reducers/putAssessment';
import { UpsertAssessmentPayload } from '../type';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type putAssessmentProp = {
    loading: boolean;
    error: unknown;
    updateAssessment: (assessmentPayload: UpsertAssessmentPayload) => void;
};

const usePutAssessment = (): putAssessmentProp => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState<unknown>();
    const [loading, setLoading] = useState<boolean>(false);

    const updateAssessment = async (assessmentPayload: UpsertAssessmentPayload) => {
        try {
            setLoading(true);
            const resultAction = await dispatch(
                putAssessmentThunk(assessmentPayload),
            );
            if (putAssessmentThunk.fulfilled.match(resultAction)) {
                setLoading(false);
                navigate('/evaluation-assessment-list');
                store.dispatch(clearAssessmentDetails());
                store.dispatch(clearClientList());
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    };
    return { error, loading, updateAssessment };
};

export default usePutAssessment;
