import { useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import {
  putAssessmentStatusThunk,
} from '../reducers/putAssessmentStatus';
import { AssessmentStatus } from '../type';

interface actionStateProps {
  loading: boolean;
  error: unknown;
  editAssessmentStatus: (id: string, status: AssessmentStatus) => void;
}

const usePutAssessmentStatus = (): actionStateProps => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const editAssessmentStatus = async (id: string, status: AssessmentStatus) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(putAssessmentStatusThunk({ id, status }));

      if (putAssessmentStatusThunk.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, editAssessmentStatus };
};

export default usePutAssessmentStatus;
