/* eslint-disable indent */
import React from 'react';
import {
    Box,
    IconButton,
    Typography,
    Grid,
    Tooltip
} from '@mui/material';
import {
    FieldName,
    FieldValue,
    GridItemFieldName,
    GridItemFieldValue,
} from '../../../Assessments/AssessmentDetails/components/EvaluationDetails.style';
import { EvaluationDetail } from '../../reducers/getEvaluationDetail';
import BarcodeIcon from '../../../../assets/svg-components/BarcodeIcon';
import InfoIcon from '../../../../assets/svg-components/InfoIcon';
import IdentificationBadgeIcon from '../../../../assets/svg-components/IdentificationBadgeIcon';
import HouseSimpleIcon from '../../../../assets/svg-components/HouseSimpleIcon';
import MapPinIcon from '../../../../assets/svg-components/MapPinIcon';
import PhoneIcon from '../../../../assets/svg-components/PhoneIcon';
import AtIcon from '../../../../assets/svg-components/AtIcon';
import { AccountInformation } from '../../../Assessments/reducers/getAccount';
import { AccountContact } from '../../../Accounts/types';
import { AccountSettingsUser } from '../../../Accounts/AccountSettings/AccountSettings';

const GeneralInfoCard = ({
    info,
    accountInfo,
    accountUserData
}: {
    info: EvaluationDetail;
    accountInfo: AccountInformation;
    accountUserData: AccountSettingsUser[];
}) => {

    const primaryContact = accountInfo?.account_contacts?.find(
        (contact: AccountContact) => contact.is_primary_contact,
    );

    return (
        <Box
            sx={{
                marginLeft: '10px',
                backgroundColor: '#F7F9FB',
                padding: '30px 20px',
                borderRadius: '16px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                }}
                variant="h5"
                component="h2"
                gutterBottom
            >
                General Info
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    <GridItemFieldName item xs={6} sm={6}>
                        <IconButton disabled>
                            <BarcodeIcon width={20} height={20} />
                        </IconButton>
                        <FieldName> ID </FieldName>
                    </GridItemFieldName>
                    <GridItemFieldValue item xs={6} sm={6}>
                        <FieldValue> {info?.encoded_evaluation_id || 'N/A'} </FieldValue>
                    </GridItemFieldValue>
                    <GridItemFieldName item xs={6} sm={6}>
                        <IconButton disabled>
                            <InfoIcon width={20} height={20} />
                        </IconButton>
                        <FieldName> Version </FieldName>
                    </GridItemFieldName>
                    <GridItemFieldValue item xs={6} sm={6}>
                        <FieldValue> {info?.evaluation_version || 'N/A'} </FieldValue>
                    </GridItemFieldValue>
                    <GridItemFieldName item xs={6} sm={6}>
                        <IconButton disabled>
                            <IdentificationBadgeIcon width={20} height={20} />
                        </IconButton>
                        <FieldName> Contact </FieldName>
                    </GridItemFieldName>
                    <GridItemFieldValue item xs={6} sm={6}>
                        <FieldValue>
                            {primaryContact && primaryContact.first_name && primaryContact.last_name
                                ? `${primaryContact.first_name as string} ${primaryContact.last_name as string}`
                                : primaryContact && primaryContact.name ? primaryContact.name : 'N/A'}
                        </FieldValue>
                    </GridItemFieldValue>
                    <GridItemFieldName item xs={6} sm={6}>
                        <IconButton disabled>
                            <HouseSimpleIcon width={20} height={20} />
                        </IconButton>
                        <FieldName> Company </FieldName>
                    </GridItemFieldName>
                    <GridItemFieldValue item xs={6} sm={6}>
                        <FieldValue> {accountInfo?.name} </FieldValue>
                    </GridItemFieldValue>
                    <GridItemFieldName item xs={6} sm={6}>
                        <IconButton disabled>
                            <MapPinIcon width={20} height={20} />
                        </IconButton>
                        <FieldName> Location </FieldName>
                    </GridItemFieldName>
                    <GridItemFieldValue item xs={6} sm={6}>
                        <Tooltip title={accountInfo.address1} placement="top-start">
                            <FieldValue
                                sx={{
                                    maxWidth: '200px',
                                }}
                            >
                                {accountInfo.address1}
                            </FieldValue>
                        </Tooltip>
                    </GridItemFieldValue>
                    <GridItemFieldName item xs={6} sm={6}>
                        <IconButton disabled>
                            <PhoneIcon width={20} height={20} />
                        </IconButton>
                        <FieldName> Phone </FieldName>
                    </GridItemFieldName>
                    <GridItemFieldValue item xs={6} sm={6}>
                        <FieldValue>
                            {accountInfo?.account_contacts?.find(
                                (contact: AccountContact) => contact.is_primary_contact,
                            )?.phone || 'N/A'}
                        </FieldValue>
                    </GridItemFieldValue>
                    <GridItemFieldName item xs={6} sm={6}>
                        <IconButton disabled>
                            <AtIcon width={20} height={20} />
                        </IconButton>
                        <FieldName> Email </FieldName>
                    </GridItemFieldName>
                    <GridItemFieldValue item xs={6} sm={6}>
                        <FieldValue>{accountInfo.email}</FieldValue>
                    </GridItemFieldValue>
                    <GridItemFieldName item xs={6} sm={6}>
                        <IconButton disabled>
                            <IdentificationBadgeIcon width={20} height={20} />
                        </IconButton>
                        <FieldName> Manager of Client Services </FieldName>
                    </GridItemFieldName>
                    <GridItemFieldValue item xs={6} sm={6}>
                        <FieldValue>
                            {' '}
                            {accountUserData?.find(
                                (contact: AccountSettingsUser) => contact.role === "account_executive",
                            )?.fullname || 'N/A'}
                        </FieldValue>
                    </GridItemFieldValue>
                </Grid>
            </Box>
        </Box>
    );
};

export default GeneralInfoCard;