export enum ClientStatus {
  OPEN = 'Open',
  INPROGRESS = 'InProgress',
  CLOSED = 'Closed',
}

export const AccountConstants = {
  primary: 'primary',
  alternate: 'alternate',
  all_clients: 'allClient',
  active: 'active',
  inactive: 'inactive',
  default_rows_per_page: 10,
  default_page: 0,
};

export const accountFormDefaultValues = {
  name: '',
  email: '',
  website: '',
  address1: '',
  address2: '',
  country: '',
  city: '',
  state: '',
  zipcode: '',
  is_active: true,
  date_of_establishment: null,
  company_logo: undefined,
  account_contacts: [
    {
      name: '',
      email: '',
      phone: '',
      role: '',
      location: '',
      is_primary_contact: true,
    },
  ],
};

export const storeKey = 'accounts';
