import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import {
  postAssessmentAssessorScore as postAssessmentAssessorScore
} from '../reducers/postAssessmentAssessorScore';
import { UpdateAssessorScoreData } from '../type';
import { toast } from 'react-toastify';

const usePostAssessmentAssessorScore = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const postAssessmentAssessorScores = async (
    requestPayload: UpdateAssessorScoreData,
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(postAssessmentAssessorScore(requestPayload));
      if (postAssessmentAssessorScore.fulfilled.match(resultAction)) {
        setLoading(false);
        // toast.success('Assessment Assessor Score Updated Successfully');
      }
      if (postAssessmentAssessorScore.rejected.match(resultAction)) {
        // toast.error("Assessment Assessor Score Couldn't Update");
      }
    } catch (error) {
      setError(error);
      toast.error("Assessment Assessor Score Couldn't Update");
    } finally {
      setLoading(false)
    }
  };

  return { loading, error, postAssessmentAssessorScores };
};

export default usePostAssessmentAssessorScore;
