import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const CardContentNoPadding = styled(CardContent) (`
  padding: 10;
  &: last-child {
    padding-bottom: 0;
  }
`);

const MetricBox = (prop: {
  metricTitle: string;
  metricValue: number;
  percent: string;
}) => {
  return (
    <Card sx={{ background: '#E3e4e4', boxShadow: 'none' }}>
      <CardContentNoPadding sx={{ textAlign: 'center', color: '#0e274c' }}>
        <Typography
          sx={{
            fontSize: 14,
            marginBottom: '10px',
          }}
        >
          {prop.metricTitle}
        </Typography>
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontWeight: 'bold',
            fontSize: 24,
            paddingBottom: '12px'
          }}
        >
          {prop.metricValue}
          {prop.percent}
        </Typography>
      </CardContentNoPadding>
    </Card>
  );
};

export default MetricBox;
