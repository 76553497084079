/* eslint-disable indent */
import React, { useState } from 'react';
import './FillInNumberResponseChart.css';
import ResultsTable from '../ResultsTable/ResultsTable';
import { Modal, Box, Typography, Button, Tooltip as MuiTooltip } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, LabelList } from 'recharts';
import { FillInNumberResponseChartData } from '../../type';

type FillInNumberResponseChartProps = {
  data: FillInNumberResponseChartData;
};

const CustomBarShape = (props: any) => {
  const { x, y, width, height, fill } = props;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        rx={2}
        ry={2}
      />
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {

    let employeeRange = '';

    switch (label) {
      case 'Small Business':
        employeeRange = '0-50 Employees';
        break;
      case 'Medium Business':
        employeeRange = '51-500 Employees';
        break;
      case 'Large Business':
        employeeRange = '501+ Employees';
        break;
      default:
        employeeRange = '';
    }

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
        <div>
          ${(payload[0].value as number).toLocaleString()}
        </div>
        <p className="label">{`${label as string}: ${employeeRange}`}</p>
      </div>
    );
  }

  return null;
};

const FillInNumberResponseChart: React.FC<FillInNumberResponseChartProps> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const { dashboard_component, answer_averages_by_company_size } = data;
  const { title, description, unit_of_measure, total_answer_value } = dashboard_component;

  // Prepare data for the chart
  const chartData = [
    { name: 'Small Business', value: answer_averages_by_company_size.small, color: "#63C394" },
    { name: 'Medium Business', value: answer_averages_by_company_size.medium, color: "#EBB63B" },
    { name: 'Large Business', value: answer_averages_by_company_size.large, color: "#6293CF" },
  ];

  const formatLabel = (value: number) => {
    return unit_of_measure === 'dollar' ? `$${value.toLocaleString()}` : `${value}%`;
  };

  const formatTick = (value: number) => {
    return unit_of_measure === 'dollar' ? `${value / 1000}k` : `${value}%`;
  };

  return (
    <div className="survey-results">
      <div className="table-title-row">
        {/* Bar Graph Icon */}
        <div className="table-title-section">
          <span className="svg-icon">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 16V10C7 9.44772 6.55228 9 6 9H2C1.44772 9 1 9.44772 1 10V16C1 16.5523 1.44772 17 2 17M7 16C7 16.5523 6.55228 17 6 17H2M7 16C7 16.5523 7.44772 17 8 17H12C12.5523 17 13 16.5523 13 16M7 16V6C7 5.44772 7.44772 5 8 5H12C12.5523 5 13 5.44772 13 6V16M2 17H16M13 16C13 16.5523 13.4477 17 14 17H18C18.5523 17 19 16.5523 19 16V2C19 1.44772 18.5523 1 18 1H14C13.4477 1 13 1.44772 13 2V16Z" stroke="#6D7BFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>

          <Typography className="table-title-text">
            {title}
          </Typography>
        </div>

        <MuiTooltip
          title="Click to View Individual Responses"
          arrow
        >
          <span
            role="button"
            tabIndex={0}
            onClick={handleOpenModal}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleOpenModal();
              }
            }}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              fontWeight: 600,
              color: "#2E3645",
              fontSize: "16px",
              fontStyle: "normal",
              lineHeight: "150%",
            }}
          >
            View all
          </span>
        </MuiTooltip>
      </div>

      <span className="component-description">{description}</span>
      {
        total_answer_value && (
          <div>
            <h3 className="optional-answer-value">{formatLabel(total_answer_value)}</h3>
            <div className="chart-title-label">
              Averages by Company Size:
            </div>
          </div>
        )
      }

      <ResponsiveContainer width="100%" height={270}>
        <BarChart
          layout="vertical"
          data={chartData}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
          barSize={24}
        >
          <CartesianGrid strokeDasharray="2 2" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tickFormatter={formatTick}
          />
          <YAxis type="category" dataKey="name" hide={true} />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
          <Bar dataKey="value" shape={<CustomBarShape />}>
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
            {/* Custom Label List to show labels above each bar */}
            <LabelList dataKey="name" position="top" content={(props) => {
              const { x, y, width, value } = props;
              return (
                <text
                  x={1}
                  y={(y as number) - 10}
                  fill="#606060"
                  textAnchor="start"
                  dominantBaseline="middle"
                  fontFamily="Inter"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  height="14px"
                >
                  {value}
                </text>
              );
            }} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <div className="custom-legend">
        <div className="price-display">
          <div className="price-display__label">
            <span className="price-display__dot" style={{ backgroundColor: "#63C394" }}></span>
            <span>{`Small B`}</span>
          </div>
          <div className="price-display__value">
            {unit_of_measure === 'dollar'
              ? `$ ${data.answer_averages_by_company_size.small.toLocaleString()}`
              : `${data.answer_averages_by_company_size.small.toLocaleString()}%`}
          </div>
        </div>
        <div className="price-display">
          <div className="price-display__label">
            <span className="price-display__dot" style={{ backgroundColor: "#EBB63B" }}></span>
            <span>{`Medium B`}</span>
          </div>
          <div className="price-display__value">
            {unit_of_measure === 'dollar'
              ? `$ ${data.answer_averages_by_company_size.medium.toLocaleString()}`
              : `${data.answer_averages_by_company_size.medium.toLocaleString()}%`}
          </div>
        </div>
        <div className="price-display">
          <div className="price-display__label">
            <span className="price-display__dot" style={{ backgroundColor: "#6293CF" }}></span>
            <span>{`Large B`}</span>
          </div>
          <div className="price-display__value">
            {unit_of_measure === 'dollar'
              ? `$ ${data.answer_averages_by_company_size.large.toLocaleString()}`
              : `${data.answer_averages_by_company_size.large.toLocaleString()}%`}
          </div>
        </div>
      </div>

      <ResultsTable open={isModalOpen} onClose={handleCloseModal} data={data.answers} />
    </div >
  );
};

export default FillInNumberResponseChart;
