import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import {
  deleteAccountSettingsRole,
  DeleteAccountRoleInitialState,
} from '../reducers/postDeleteUser';
import { useState } from 'react';

interface DeleteAccountRoleProps {
  loading: boolean;
  error: unknown;
  deleteAccountRole: (requestPayload: DeleteAccountRoleInitialState) => void;
}

const useDeleteRole = (): DeleteAccountRoleProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const deleteAccountRole = async (
    requestPayload: DeleteAccountRoleInitialState,
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        deleteAccountSettingsRole(requestPayload),
      );
      if (deleteAccountSettingsRole.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };
  return { deleteAccountRole, loading, error };
};

export default useDeleteRole;
