import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PublicAssessmentDetailsReducer, PublicAssessmentOverviewDetails } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: PublicAssessmentDetailsReducer = {
    assessment_details: null,
    status: RequestStatus.Init,
};

export const getPublicEmployerOfChoiceAssessment = createAsyncThunk(
    'assessment/getPublicAssessments',
    async ({ accountId, groupName }: { accountId: string; groupName: string }, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<PublicAssessmentOverviewDetails>(
                `${EndPoints.PUBLIC_ACCOUNT}/${accountId}/group/${EndPoints.ASSESSMENT}?group=${groupName}`,
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getPublicEmployerOfChoiceAssessmentDetailsSlice = createSlice({
    name: 'getPublicAssessmentDetails',
    initialState,
    reducers: {
        clearAssessmentDetails: (state) => {
            state.assessment_details = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPublicEmployerOfChoiceAssessment.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getPublicEmployerOfChoiceAssessment.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                // state.assessment_details = action.payload;
            })
            .addCase(getPublicEmployerOfChoiceAssessment.rejected, (state) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearAssessmentDetails: clearPublicAssessmentDetails } = getPublicEmployerOfChoiceAssessmentDetailsSlice.actions;

export default getPublicEmployerOfChoiceAssessmentDetailsSlice.reducer;
