import { useAppDispatch } from '../../../redux/store';
import { useState } from 'react';
import {
  putEvaluationResultAnalysisThunk,
  evaluationResultAnalysisRequest,
} from '../reducers/putEvaluationResultAnalysis';

type evaluationAnalysisProp = {
  loading: boolean;
  error: unknown;
  updateEvaluationResultAnalysis: (
    evaluationRequest: evaluationResultAnalysisRequest,
  ) => void;
};

const usePutEvaluationResultAnalysis = (): evaluationAnalysisProp => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);

  const updateEvaluationResultAnalysis = async (
    evaluationRequest: evaluationResultAnalysisRequest,
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        putEvaluationResultAnalysisThunk(evaluationRequest),
      );
      if (putEvaluationResultAnalysisThunk.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };
  return { error, loading, updateEvaluationResultAnalysis };
};

export default usePutEvaluationResultAnalysis;
