import { useDispatch } from 'react-redux';
import { getCloudinaryImageUrl } from '../reducers/getCloudinaryImageUrl';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { AxiosError } from 'axios';


const useGetCloudinaryImageUrl = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();

  const getImageDetails = async (
    CloudinaryIdRequestPayload: string,
  ) => {
    const resultAction = await dispatch(
      getCloudinaryImageUrl(CloudinaryIdRequestPayload),
    ).then((res) => {
      if (getCloudinaryImageUrl.fulfilled.match(res)) {
        setLoading(false);
      }
      if (getCloudinaryImageUrl.rejected.match(res)) {
        setLoading(false);
        setError(res.payload as AxiosError);
      }
    });
  };

  return { cloudinaryImageUrlError: error, cloudinaryImageUrlLoading: loading, getImageDetails };
};

export default useGetCloudinaryImageUrl;
