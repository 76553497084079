import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';

export interface CloudinarySignatureResponse {
  signature: string;
  timestamp: number;
}

export const getCloudinarySignature = createAsyncThunk<
  CloudinarySignatureResponse
>('cloudinary/getSignature',
  async (_, { rejectWithValue }) => {
    const response: AxiosResponse<CloudinarySignatureResponse> =
      await axiosInstance.post(`${EndPoints.GET_CLOUDINARY_SIGNATURE}`);
    return response.data;
  });