import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import { getAccountSetting } from '../reducers/getAccountSetting';

interface actionStateProps {
  loading: boolean;
  error: unknown;
  getAccountSettingsUsers: (id: string) => void;
}

const useAccountSettingsUsers = (): actionStateProps => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const getAccountSettingsUsers = async (id: string) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(getAccountSetting(id));
      if (getAccountSetting.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return { loading, error, getAccountSettingsUsers };
};

export default useAccountSettingsUsers;
