import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { CloudinaryImageUrlResponse } from '../../Cloudinary/type';
import { CloudinaryImageUrlReducer as CloudinaryImageUrlReducer } from './getCloudinaryImageUrl';

export interface CloudinaryImageUrlPayload {
  evaluation_id: string;
  img_url: string;
}

const initialState: CloudinaryImageUrlReducer = {
  cloudinaryImageDetails: null,
  status: RequestStatus.Init,
};

const postClouadinaryImageUrlSlice = createSlice({
  name: 'cloudinaryImageUrl/postCloudinaryImageUrl',
  initialState,
  reducers: {
    resetCloudinaryImageDetailsState: (state) => {
      state.cloudinaryImageDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCloudinaryImageUrl.pending, (state) => {
        state.status = RequestStatus.Loading;
      })
      .addCase(postCloudinaryImageUrl.fulfilled, (state, action: PayloadAction<CloudinaryImageUrlResponse>) => {
        state.status = RequestStatus.Done;
        state.cloudinaryImageDetails = action.payload;
      })
      .addCase(postCloudinaryImageUrl.rejected, (state) => {
        state.status = RequestStatus.Error;
      });
  },
});

export const postCloudinaryImageUrl = createAsyncThunk<
  CloudinaryImageUrlResponse,
  CloudinaryImageUrlPayload
>(
  'postCloudinaryImageUrl',
  async (
    cloudinaryImageDetailsPayload: CloudinaryImageUrlPayload,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<CloudinaryImageUrlResponse> =
        await axiosInstance.post<CloudinaryImageUrlResponse>(
          EndPoints.POST_EVALUATION_IMAGE_URL,
          cloudinaryImageDetailsPayload,
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { resetCloudinaryImageDetailsState } =
  postClouadinaryImageUrlSlice.actions;

export default postClouadinaryImageUrlSlice.reducer;