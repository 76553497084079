import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {
  Container,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material';

interface IntakeFormCountyIntakeProps {
  formData: { participationAgreement: string };
  onFormChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => void;
}

const IntakeFormLegalStatement: FunctionComponent<
  IntakeFormCountyIntakeProps
> = ({ formData, onFormChange }) => {
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" color="primary">
            Legal Statement
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '2%' }}>
          <Typography variant="body1" paragraph>
            Thank you for agreeing to participate in the Employer of Choice
            Survey.
            <br />
            Your participation is voluntary, and all responses will be kept
            confidential.
            <br />
            The information provided will be used exclusively for research
            purposes to evaluate and improve workplace practices and employee
            satisfaction.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Consent to Participate:</strong> <br />
            By proceeding with this survey, you affirm that you have been
            informed about the purpose of the survey, the anticipated duration,
            and the nature of the participation required.
          </Typography>
          <Typography variant="body1" paragraph>
            You understand that you have the right to withdraw at any time
            without penalty.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Confidentiality:</strong> <br />
            All data collected from this survey will be stored securely and will
            only be reported in an aggregated format, ensuring that individual
            responses cannot be identified.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Data Use:</strong> <br />
            The information from this survey may be used in organizational
            assessments, reports, and publications. However, no personally
            identifiable information will be included in such reports.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Contact Information:</strong> <br />
            If you have any questions about the survey or your rights as a
            participant, please contact <br />
            talent.development@floridamakes.com
          </Typography>
          <Typography variant="body1" paragraph>
            By proceeding with the survey, you indicate your consent to the use
            of the data you provide as described above and affirm that you are
            participating of your own free will.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            value={formData.participationAgreement}
            onChange={onFormChange}
            name="participationAgreement"
          >
            <FormControlLabel
              sx={{
                outline:
                  formData.participationAgreement === 'I Agree'
                    ? '2px solid #3787bc'
                    : '1px solid gray',
                marginTop: '3%',
                width: '100%',
              }}
              value="I Agree"
              control={<Radio />}
              label="I Agree"
            />
            <FormControlLabel
              sx={{
                outline:
                  formData.participationAgreement === 'I Disagree'
                    ? '2px solid #3787bc'
                    : '1px solid gray',
                marginTop: '3%',
                marginBottom: '2%',
                width: '100%',
              }}
              value="I Disagree"
              control={<Radio />}
              label="I Disagree"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntakeFormLegalStatement;
