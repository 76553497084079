import React from 'react';
import moment from 'moment';
import {
  Grid,
  Paper,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme, 
} from '@mui/material';
import { EvaluationDetail } from '../../../reducers/getEvaluationDetail';
import { EvaluationStatus, EvaluationActions } from '../../../type';
import {
  Typography12px600,
  Typography12px400,
} from '../../../../../styles/Global.styles';

const EvaluationDetailCard = (prop: {
  evaluationDetail: EvaluationDetail;
  updateStatus: (status: string) => void;
  reRender?: boolean;
}) => {
  const theme = useTheme();
  const { evaluationDetail } = prop;
  const [status, setStatus] = React.useState<string>(
    evaluationDetail.evaluation_status,
  );

  React.useEffect(() => {
    setStatus(evaluationDetail.evaluation_status);
  }, [evaluationDetail, prop.reRender]);

  const draftStateMenus = [
    {
      value: EvaluationStatus.DRAFT,
      label: EvaluationStatus.DRAFT,
    },
    { value: EvaluationActions.PUBLISH, label: 'Publish' },
    { value: EvaluationActions.DELETE, label: 'Delete' },
  ];

  const progressStateMenus = [
    {
      value: EvaluationStatus.IN_PROGRESS,
      label: EvaluationStatus.IN_PROGRESS,
    },
    { value: EvaluationActions.UN_PUBLISH, label: 'Un-publish' },
    { value: EvaluationActions.REVIEW, label: 'Review' },
  ];

  const reviewStateMenus = [
    {
      value: EvaluationStatus.RESULTS_REVIEW,
      label: EvaluationStatus.RESULTS_REVIEW,
    },
    { value: EvaluationActions.RE_OPEN, label: 'Re-Open' },
    { value: EvaluationActions.CLOSE, label: 'Close' },
  ];

  const closedStateMenus = [
    {
      value: EvaluationStatus.CLOSED,
      label: EvaluationStatus.CLOSED,
    },
    { value: EvaluationActions.RE_OPEN, label: 'Re-Open' },
    { value: EvaluationActions.REVIEW, label: 'Review' },
    { value: EvaluationActions.DELETE, label: 'Delete' },
  ];

  return (
    <Paper
      elevation={2}
      sx={{
        marginBottom: '20px',
        padding: '10px',
        border: 'none',
        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
      }}
    >
      <Grid container spacing={2} padding={'20px'}>
        <Grid item xs={12} sm={3}>
          <Typography12px600 sx={{ color: '#464E5F', paddingBottom: '2px' }}>
            Title
          </Typography12px600>
          <Typography12px400>
            {evaluationDetail?.evaluation_name}
          </Typography12px400>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography12px600 sx={{ color: '#464E5F', paddingBottom: '2px' }}>
            Type
          </Typography12px600>
          <Typography12px400>
            {evaluationDetail?.evaluation_category}
          </Typography12px400>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography12px600 sx={{ color: '#464E5F', paddingBottom: '2px' }}>
            Close Date
          </Typography12px600>
          <Typography12px400>
            {moment(evaluationDetail?.closed_date).format('MMM DD, YYYY')}
          </Typography12px400>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            displayEmpty
            value={status}
            size="small"
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={(event: SelectChangeEvent) => {
              setStatus(event.target.value);
              prop.updateStatus(event.target.value);
            }}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
              minWidth: '125px',
              '& .MuiSelect-iconOutlined': {
                color: '#fff',
              },
              '&:hover': {
                '&& fieldset': {
                  border: '1px solid' + theme.palette.primary.main,
                },
              },
            }}
          >
            {evaluationDetail.evaluation_status === EvaluationStatus.DRAFT &&
              draftStateMenus.map((item, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    display: index === 0 ? 'none' : 'block',
                  }}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))}

            {evaluationDetail.evaluation_status ===
              EvaluationStatus.IN_PROGRESS &&
              progressStateMenus.map((item, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    display: index === 0 ? 'none' : 'block',
                  }}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))}

            {evaluationDetail.evaluation_status ===
              EvaluationStatus.RESULTS_REVIEW &&
              reviewStateMenus.map((item, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    display: index === 0 ? 'none' : 'block',
                  }}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))}

            {evaluationDetail.evaluation_status === EvaluationStatus.CLOSED &&
              closedStateMenus.map((item, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    display: index === 0 ? 'none' : 'block',
                  }}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EvaluationDetailCard;
