import { store, useAppDispatch } from '../../../redux/store';
import { clearClientList } from '../../Accounts/reducers/getClientList';
import { clearEvaluationDetail } from '../reducers/getEvaluationDetail';
import { putEvaluationThunk } from '../reducers/putEvaluation';
import { UpdateEvaluationType } from '../type';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type putEvaluationProp = {
  loading: boolean;
  error: unknown;
  updateEvaluation: (evaluationPayload: UpdateEvaluationType) => void;
};

const usePutEvaluation = (): putEvaluationProp => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);

  const updateEvaluation = async (evaluationPayload: UpdateEvaluationType) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        putEvaluationThunk(evaluationPayload),
      );
      if (putEvaluationThunk.fulfilled.match(resultAction)) {
        setLoading(false);
        navigate('/evaluation-assessment-list');
        store.dispatch(clearEvaluationDetail());
        store.dispatch(clearClientList());
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };
  return { error, loading, updateEvaluation };
};

export default usePutEvaluation;
