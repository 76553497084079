import { store, useAppDispatch } from '../../../redux/store';
import { clearClientList } from '../../Accounts/reducers/getClientList';
import { clearAssessmentDetails } from '../reducers/getAssessmentDetails';
import { putCatagoryAnalysisNoteThunk } from '../reducers/putCategoryAnalysisNote';
import { CategoryAnalysisNotePayload } from '../type';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

type putCategoryAnalysisNoteProp = {
    loading: boolean;
    error: unknown;
    updateCategoryAnalysisNote: (notePayload: CategoryAnalysisNotePayload) => void;
};

const usePutCategoryAnalysisNote = (): putCategoryAnalysisNoteProp => {
    const dispatch = useAppDispatch();
    // const navigate = useNavigate();
    const [error, setError] = useState<unknown>();
    const [loading, setLoading] = useState<boolean>(false);

    const updateCategoryAnalysisNote = async (notePayload: CategoryAnalysisNotePayload) => {
        try {
            setLoading(true);
            const resultAction = await dispatch(
                putCatagoryAnalysisNoteThunk(notePayload),
            );
            if (putCatagoryAnalysisNoteThunk.fulfilled.match(resultAction)) {
                setLoading(false);
                // store.dispatch(clearAssessmentDetails());
                // store.dispatch(clearClientList());
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    };
    return { error, loading, updateCategoryAnalysisNote };
};

export default usePutCategoryAnalysisNote;
