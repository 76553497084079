import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';

import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { GetShareableLinkReducer } from '../type';

const initialState: GetShareableLinkReducer = {
  status: RequestStatus.Init,
  link: { uuid: '' },
};

const getShareableLinkSlice = createSlice({
  name: '/getShareableLink',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShareableLink.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getShareableLink.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.link = action.payload;
    });
    builder.addCase(getShareableLink.rejected, (state) => {
      state.status = RequestStatus.Done;
    });
  },
});

export const getShareableLink = createAsyncThunk<{ uuid: string }>(
  '/getLink',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<{ uuid: string }, void> = await getLink();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const getLink = async (): Promise<AxiosResponse<{ uuid: string }>> => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return await axiosInstance.get(`${EndPoints.GET_SHAREABLE_LINK}`);
};

export default getShareableLinkSlice.reducer;
