import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import { RequestStatus } from '../../../types/request';
import { toast } from 'react-toastify';

export interface LogoutSliceType {
  status: RequestStatus;
  response: { success: string } | null;
}

const initialState: LogoutSliceType = {
  status: RequestStatus.Init,
  response: null,
};

const logoutSlice = createSlice({
  name: '/auth/logout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutFromApplication.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(logoutFromApplication.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      toast.success(action.payload.success);
    });
    builder.addCase(logoutFromApplication.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      const errorMessage =
        (
          (action.payload as AxiosError).response?.data as {
            error: string;
          }
        ).error ?? '';
      toast.error(errorMessage);
    });
  },
});

export const logoutFromApplication = createAsyncThunk<
  { success: string },
  void
>('/auth/logout-from-application', async (_, { rejectWithValue }) => {
  try {
    const result: AxiosResponse<{ success: string }> = await axiosInstance.post(
      EndPoints.POST_LOGOUT,
    );
    return result.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export default logoutSlice.reducer;
