import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { EvaluationScoreHistoryResponse as EvaluationScoreHistoryResponse } from '../type';

export interface EvaluationScoreHistoryPayload {
  account_id?: string;
}

export interface EvaluationScoreHistoryReducer {
  status: RequestStatus;
  evaluationScoreHistoryResponse: EvaluationScoreHistoryResponse | null;
}

const initialState: EvaluationScoreHistoryReducer = {
  status: RequestStatus.Init,
  evaluationScoreHistoryResponse: null,
};

const postEvaluationScoreHistorySlice = createSlice({
  name: 'evaluation/postEvaluationScoreHistory',
  initialState,
  reducers: {
    updateResultAnalysis: (state) => {
      state.evaluationScoreHistoryResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvaluationScoreHistory.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationScoreHistory.fulfilled, (state, action) => {
      state.evaluationScoreHistoryResponse = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(getEvaluationScoreHistory.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const getEvaluationScoreHistory = createAsyncThunk<
EvaluationScoreHistoryResponse,
EvaluationScoreHistoryPayload
>(
  'postEvaluationScoreHistory',
  async (
    evaluationPayload: EvaluationScoreHistoryPayload,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<EvaluationScoreHistoryResponse> =
        await axiosInstance.post<EvaluationScoreHistoryResponse>(
          EndPoints.POST_EVALUATION_SCORE_HISTORY,
          evaluationPayload,
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { updateResultAnalysis } =
  postEvaluationScoreHistorySlice.actions;

export default postEvaluationScoreHistorySlice.reducer;
