import React from 'react';
import { Link } from 'react-router-dom';
import AccountListTable from '../../../components/Accounts/ClientList/ClientListTable';
import { CustomAddButton } from '../../../styles/Global.styles';
import { SearchTextField } from './ClientList.styles';
import { debounce } from '@mui/material';

const ClientList = () => {
  const [searchText, setSearchText] = React.useState('');

  return (
    <>
      <Link to={'/create-account'}>
        <CustomAddButton sx={{ margin: '10px 20px' }}>
          Add Account +
        </CustomAddButton>
      </Link>
      <SearchTextField
        size={'small'}
        variant="outlined"
        placeholder={'Search'}
        value={searchText}
        onChange={(event) => {
          setSearchText(event.target.value);
        }}
      />
      <AccountListTable searchText={searchText} />
    </>
  );
};
export default ClientList;
