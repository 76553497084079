import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { EvaluationQuestionsResponses } from '../type';

export interface EvaluationResponseBreakdownPayload {
  evaluation_id: string;
  question_category_id: string;
}

export interface EvaluationResponseBreakdownReducer {
  status: RequestStatus;
  evaluationQuestionsResponses: EvaluationQuestionsResponses | null;
}

const initialState: EvaluationResponseBreakdownReducer = {
  status: RequestStatus.Init,
  evaluationQuestionsResponses: null,
};

const postEvaluationResponseBreakdownSlice = createSlice({
  name: 'evaluation/postEvaluationResponseBreakdownByCategory',
  initialState,
  reducers: {
    updateResultAnalysis: (state) => {
      state.evaluationQuestionsResponses = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvaluationResponseBreakdown.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationResponseBreakdown.fulfilled, (state, action) => {
      state.evaluationQuestionsResponses = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(getEvaluationResponseBreakdown.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const getEvaluationResponseBreakdown = createAsyncThunk<
EvaluationQuestionsResponses,
  EvaluationResponseBreakdownPayload
>(
  'postEvaluationResponseBreakdown',
  async (
    evaluationPayload: EvaluationResponseBreakdownPayload,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<EvaluationQuestionsResponses> =
        await axiosInstance.post<EvaluationQuestionsResponses>(
          EndPoints.POST_EVALUATION_RESPONSE_BREAKDOWN,
          evaluationPayload,
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { updateResultAnalysis } =
  postEvaluationResponseBreakdownSlice.actions;

export default postEvaluationResponseBreakdownSlice.reducer;
