import React from 'react';
import {  Outlet } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import './AppRouter.css';
import Header from './components/Header/Header';


const AppRouter = () => {
  return (
    <div className="base-container">
      <div className="sidebar">
        <NavBar />
      </div>
      <div className="main-content">
        <Header />
        <Outlet />
      </div>
    </div>
  );
};

export default AppRouter;
