import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import {
  fetchEvaluationCategoriesResultAnalysis,
  EvaluationCategoriesResultAnalysisPayload,
} from '../reducers/postEvaluationCategoriesResultAnalysis';

const usePostEvaluationCategoriesResultAnalysis = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const postEvaluationCategoriesResult = async (
    requestPayload: EvaluationCategoriesResultAnalysisPayload,
  ) => {
    try {
      const resultAction = await dispatch(fetchEvaluationCategoriesResultAnalysis(requestPayload));
      if (fetchEvaluationCategoriesResultAnalysis.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, postEvaluationCategoriesResult };
};

export default usePostEvaluationCategoriesResultAnalysis;
