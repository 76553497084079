import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import './AccountExecutiveDashboard.css';
import { RootStoreType } from '../../../redux/store.type';
import { useNavigate } from 'react-router-dom';
import { useBranding } from '../../Branding/reducers/getBranding';
import AeDashboardV2 from '../components/AeDashboardV2';
import BinaryResponseChart from '../components/BinaryResponseChart/BinaryResponseChart';
import FillInNumberResponseChart from '../components/FillInNumberResponseChart/FillInNumberResponseChart';
import MultipleChoiceResponseOptionsChart from '../components/MultipleChoiceRepsonseCharts/5ResponseOptionsChart/5ResponseOptionsChart';
import ResponseDistributionChart from '../components/MultipleChoiceRepsonseCharts/ResponseDistributionChart/ResponseDistributionChart';
import { BinaryResponseChartData, FillInNumberResponseChartData, MultipleChoiceResponseOptionsChartData, MultipleChoiceDistributionChartData } from '../type';
import {FeatureFlags} from '../../Branding/type'

const AccountExecutiveDashboard = () => {
  const navigate = useNavigate();
  const branding = useBranding();

  const dashboardAccess = branding?.active_feature_flags?.[FeatureFlags.DASHBOARD] === true;
  const showLegacyDashboard = branding?.active_feature_flags?.[FeatureFlags.LEGACY_DASHBOARD] === true;

  const loggedInUserType = useSelector((state: RootStoreType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.authReducers.user.currentUser?.user_type;
  });

  useEffect(() => {
    if (!dashboardAccess) {
      navigate('/account-list');
    }
  }, [dashboardAccess, navigate]);

  useEffect(() => {
    // Assuming loggedInUserType is an array of strings representing user roles
    if (
      !loggedInUserType?.includes('admin') &&
      !loggedInUserType?.includes('account_executive')
    ) {
      navigate('/client-dashboard'); // Redirect user to an 'unauthorized' page or any other page
      return;
    }
  }, [loggedInUserType, navigate]);

  const binaryResponseDataQ10: BinaryResponseChartData = {
    question_id: "123456798",
    question_text: "Does your company provide training and development programs to support employee skill enhancement and professional growth?",
    total_responses: 176,
    binary_response_0_count: {
      response_name: "no",
      response_count: 76
    },
    binary_response_1_count: {
      response_name: "yes",
      response_count: 100
    },
    answers: [
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: "yes"
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: "yes"
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: "no"
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: "no"
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: "yes"
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: "yes"
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: "yes"
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "medium",
        answer: "no"
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "medium",
        answer: "no"
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "small",
        answer: "yes"
      }
    ]
  }

  const binaryResponseDataQ33: BinaryResponseChartData = {
    question_id: "87654321",
    question_text: "Does your company provide workers with a meaningful voice, allowing them to contribute input to improve processes or the workplace?",
    total_responses: 176,
    binary_response_0_count: {
      response_name: "no",
      response_count: 132
    },
    binary_response_1_count: {
      response_name: "yes",
      response_count: 44
    },
    answers: [
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: "no"
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: "yes"
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: "no"
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: "no"
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: "yes"
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: "no"
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "large",
        answer: "no"
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: "no"
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: "no"
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "small",
        answer: "yes"
      }
    ]
  }

  const FillInNumberResponseChartDataQ11: FillInNumberResponseChartData = {
    question_id: "87654322",
    question_text: "What was the total amount invested in learning and development per employee by the company in the past year?",
    dashboard_component: {
      title: "Learning and Development",
      description: "Total invested in learning and development per employee",
      unit_of_measure: "dollar",
      total_answer_value: 120000
    },
    total_responses: 176,
    answer_averages_by_company_size: {
      small: 3800,
      medium: 2500,
      large: 3900
    },
    answers: [
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: 3750
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: 2600
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: 3800
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: 3600
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: 2500
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: 3900
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "large",
        answer: 3800
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: 4000
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: 3900
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "small",
        answer: 3850
      }
    ]
  }

  const FillInNumberResponseChartDataQ9: FillInNumberResponseChartData = {
    question_id: "87654323",
    question_text: "What was your company’s Revenue per Employee (RPE) for the past year?",
    dashboard_component: {
      title: "Revenue Per Employee (RPE)",
      description: "Each bar represents the average RPE by company size",
      unit_of_measure: "dollar"
    },
    total_responses: 176,
    answer_averages_by_company_size: {
      small: 16000,
      medium: 32000,
      large: 56000
    },
    answers: [
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: 16000
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: 32000
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: 56000
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: 15000
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: 33000
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: 57000
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "large",
        answer: 55000
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: 56000
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: 16500
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "small",
        answer: 17000
      }
    ]
  }

  const FillInNumberResponseChartDataQ6: FillInNumberResponseChartData = {
    question_id: "87654324",
    question_text: "What percentage of employees voluntarily left the organization within the first 90 days of hire?",
    dashboard_component: {
      title: "Employee Turnover Rate in the First 90 Days",
      description: "Each bar represents the average turnover by company size",
      unit_of_measure: "percent"
    },
    total_responses: 176,
    answer_averages_by_company_size: {
      small: 10,
      medium: 21,
      large: 19
    },
    answers: [
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: 10
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: 21
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: 19
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: 9
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: 21
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: 20
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "large",
        answer: 19
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: 18
      },
      {
        account_id: "952dace6-0af2-4793-b2ce-173d19878e39",
        company_name: "Create Test",
        company_size: "small",
        answer: 10
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "small",
        answer: 11
      }
    ]
  }

  const MultipleChoiceResponseOptionsChartDataQ4: MultipleChoiceResponseOptionsChartData = {
    question_id: "87654326",
    question_text: "Please estimate the percentage of employees retained for at least three years after going through your company's onboarding process?",
    dashboard_component: {
      title: "Employees Retained",
      description: "Average percentage of employees retained for at least three years after going through a company’s onboarding process",
    },
    total_responses: 176,
    answers: {
      "Less than 25%": [
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "Less than 25%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "Less than 25%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "Less than 25%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "Less than 25%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "Less than 25%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "Less than 25%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "Less than 25%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "Less than 25%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "Less than 25%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "Less than 25%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "Less than 25%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "Less than 25%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "Less than 25%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "Less than 25%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "Less than 25%"
        }
      ],
      "25%-50%": [
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "25%-50%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "25%-50%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "25%-50%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "25%-50%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "25%-50%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "25%-50%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "25%-50%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "25%-50%"
        }
      ],
      "51%-58%": [
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "51%-58%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "51%-58%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "51%-58%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "51%-58%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "51%-58%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "51%-58%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "51%-58%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "51%-58%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "51%-58%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "51%-58%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "51%-58%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "51%-58%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "51%-58%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "51%-58%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "51%-58%"
        }
      ],
      "59%-75%": [
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "59%-75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "59%-75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "59%-75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "59%-75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "59%-75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "59%-75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "59%-75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "59%-75%"
        },
      ],
      "More than 75%": [
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "More than 75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "More than 75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "More than 75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "More than 75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "More than 75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "More than 75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "More than 75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "More than 75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "More than 75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "More than 75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "small",
          answer: "More than 75%"
        },
        {
          account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
          company_name: "Contact Logo Creation",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
          company_name: "Jacobs - Bogan",
          company_size: "large",
          answer: "More than 75%"
        },
        {
          account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
          company_name: "Not So Cool Company",
          company_size: "medium",
          answer: "More than 75%"
        },
        {
          account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
          company_name: "Derick's World",
          company_size: "large",
          answer: "More than 75%"
        }
      ]
    }
  }

  const MultipleChoiceResponseOptionsChartDataQ2: MultipleChoiceDistributionChartData = {
    question_id: "87654327",
    question_text: "How would you describe your company's onboarding process for new hires?",
    total_responses: 176,
    response_distribution: [
      {
        response_text: "We do not have a formal onboarding process.",
        response_distribution_percent: 12,
        total_responses: 20
      },
      {
        response_text: "We have an informal onboarding process, but it is not consistently applied to all new hires.",
        response_distribution_percent: 35,
        total_responses: 62
      },
      {
        response_text: "We have a structured onboarding program that introduces new hires to our company culture, expectations, and their specific roles.",
        response_distribution_percent: 31,
        total_responses: 55
      },
      {
        response_text: "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years.",
        response_distribution_percent: 22,
        total_responses: 39
      },

    ],
    answers: [
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: "We do not have a formal onboarding process."
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: "We do not have a formal onboarding process."
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: "We do not have a formal onboarding process."
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: "We do not have a formal onboarding process."
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: "We have an informal onboarding process, but it is not consistently applied to all new hires."
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: "We have an informal onboarding process, but it is not consistently applied to all new hires."
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: "We have an informal onboarding process, but it is not consistently applied to all new hires."
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: "We have an informal onboarding process, but it is not consistently applied to all new hires."
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: "We have an informal onboarding process, but it is not consistently applied to all new hires."
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: "We have an informal onboarding process, but it is not consistently applied to all new hires."
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: "We have a structured onboarding program that introduces new hires to our company culture, expectations, and their specific roles."
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: "We have a structured onboarding program that introduces new hires to our company culture, expectations, and their specific roles."
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: "We have a structured onboarding program that introduces new hires to our company culture, expectations, and their specific roles."
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years."
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "small",
        answer: "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years."
      },
      {
        account_id: "0b14997a-978d-4a51-9cfd-141b715e657a",
        company_name: "Contact Logo Creation",
        company_size: "medium",
        answer: "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years."
      },
      {
        account_id: "5e30b290-524d-4a18-be9d-793bec1b7ff1",
        company_name: "Jacobs - Bogan",
        company_size: "large",
        answer: "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years."
      },
      {
        account_id: "6a7e50d5-d5f7-4565-a6fc-ab97f1212a04",
        company_name: "Not So Cool Company",
        company_size: "medium",
        answer: "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years."
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years."
      },
      {
        account_id: "011d205d-b97e-4b8c-a3c8-0b794d7d21af",
        company_name: "Derick's World",
        company_size: "large",
        answer: "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years."
      }
    ]
  }

  return showLegacyDashboard ? (
    <Box>
      <AeDashboardV2 branding={branding} />
    </Box>
  ) : (
    <Box>
      
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className="chart-container">
          <ResponseDistributionChart data={MultipleChoiceResponseOptionsChartDataQ2} />
        </Grid>
        <Grid item xs={12} md={6} className="chart-stack-container">
          <div className="stacked-chart">
            <BinaryResponseChart data={binaryResponseDataQ10} />
          </div>
          <div className="stacked-chart">
            <BinaryResponseChart data={binaryResponseDataQ33} />
          </div>
        </Grid>

        <Grid item xs={12} md={6} className="chart-container">
          <FillInNumberResponseChart data={FillInNumberResponseChartDataQ9} />
        </Grid>
        <Grid item xs={12} md={6} className="chart-container">
          <FillInNumberResponseChart data={FillInNumberResponseChartDataQ6} />
        </Grid>

        <Grid item xs={12} md={6} className="chart-container">
          <MultipleChoiceResponseOptionsChart data={MultipleChoiceResponseOptionsChartDataQ4} />
        </Grid>
        <Grid item xs={12} md={6} className="chart-container">
          <FillInNumberResponseChart data={FillInNumberResponseChartDataQ11} />
        </Grid>

      </Grid>
    </Box>
  );
};

export default AccountExecutiveDashboard;
