import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosResponse } from 'axios';
import axiosInstance, { axiosV2Instance } from '../../../config/axios';
import { AssessmentListDetail } from '../type';
import { EndPoints } from '../../../constants/apiUrls';

export interface AssessmentsReducer {
  status: RequestStatus;
  assessments: AssessmentListDetail[] | null;
}

const initialState: AssessmentsReducer = {
  status: RequestStatus.Init,
  assessments: null,
};

export const getAssessments = createAsyncThunk<AssessmentListDetail[]>(
  '/getAssessments',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<AssessmentListDetail[]> = await axiosV2Instance.get(
        EndPoints.ASSESSMENT
      );
      return response.data; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const getAssessmentsSlice = createSlice({
  name: '/getAssessments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssessments.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getAssessments.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.assessments = action.payload;
    });
    builder.addCase(getAssessments.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export default getAssessmentsSlice.reducer;