import { AssessmentCreation } from './../type';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { PostAssessment } from '../reducers/postAssessment';

interface usePostAssessmentProps {
    loading: boolean;
    error: AxiosError | null;
    postAssessment: (AssessmentCreation) => Promise<any>;
}

const usePostAssessment = (): usePostAssessmentProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<AxiosError | null>(null);

        const postAssessment = async (assessmentPayload : AssessmentCreation) => {
            try {
                const resultAction = await dispatch(PostAssessment(assessmentPayload));
                if (PostAssessment.fulfilled.match(resultAction)) {
                    setLoading(false);
                } else if (PostAssessment.rejected.match(resultAction)) {
                    setLoading(false);
                    setError(resultAction.payload as AxiosError);
                }
                return resultAction.payload
            } catch (err) {
                setLoading(false);
                setError(err as AxiosError);
            }
        };

    return { postAssessment, error, loading };
};

export default usePostAssessment;
