import { useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import {
  EvaluationStatusPayload,
  updateEvaluationStatus,
} from '../reducers/postEvaluationStatus';
import { useNavigate } from 'react-router-dom';
import { EvaluationActions } from '../type';

interface actionStateProps {
  loading: boolean;
  error: unknown;
  resultFetched: boolean;
  editEvaluationStatus: (payload: EvaluationStatusPayload) => void;
}

const useEditEvaluationStatus = (): actionStateProps => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [resultFetched, setResultFetched] = useState(false);
  const [error, setError] = useState<unknown>();
  const navigate = useNavigate();

  const editEvaluationStatus = async (payload: EvaluationStatusPayload) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(updateEvaluationStatus(payload));
      if (updateEvaluationStatus.fulfilled.match(resultAction)) {
        setLoading(false);
        setResultFetched(true);
        if (payload.to_state === EvaluationActions.DELETE) {
          navigate('/evaluation-assessment-list');
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, resultFetched, error, editEvaluationStatus };
};

export default useEditEvaluationStatus;
