import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { postActivateUser } from '../reducers/activateUser';
import { ActivateAccountPayload } from '../Interfaces/Interfaces';
import { useNavigate } from 'react-router';

const useActivatePassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const postActivateAccountMethod = async (
    activateUserData: ActivateAccountPayload,
  ) => {
    try {
      const resultAction = await dispatch(postActivateUser(activateUserData));
      if (postActivateUser.fulfilled.match(resultAction)) {
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { postActivateAccountMethod };
};

export default useActivatePassword;
