import { useRef, useEffect } from 'react';

const useDebounce = <T>(wait: number) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const debounce = (func: (...args: T[]) => void, ...args: T[]) => {
    clearTimeout(timeout.current!);
    timeout.current = setTimeout(() => func(...args), wait);
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return { debounce };
};

export default useDebounce;
