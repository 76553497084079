import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    CategoryAnalysisNotePayload, ScorableQuestionAndAnswer, QuestionType, CategoryScoreDetails, CategoryScoreQuestionDetails, MultipleChoiceType
} from '../../../type'

interface AnswerSummaryProps {
    questionDetails: CategoryScoreQuestionDetails;
}

// Helper component to style each answer box
const AnswerBox = ({
    label,
    percentage,
    icon,
    color,
    backgroundColor,
}: {
    label: string;
    percentage: number;
    icon: JSX.Element;
    color: string;
    backgroundColor: string;
}) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
        }}
    >
        {icon}
        <Typography
            sx={{
                color: '#2E3645',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '19px',
            }}>
            {label}
        </Typography>
        <Typography
            sx={{
                fontSize: '14px',
                fontWeight: 600,
                color,
                padding: '4px 8px',
                borderRadius: '4px',
                backgroundColor,
            }}
        >
            {percentage}%
        </Typography>
    </Box>
);

function getClosestAnswerText(questionDetails: CategoryScoreQuestionDetails): string {
    const { multiple_choice_answer_distribution, average_score } = questionDetails;

    // Initialize variables to store the closest answer
    let closestAnswerText = "";
    let smallestDifference = Infinity;

    // Iterate over the distribution to find the closest score
    multiple_choice_answer_distribution.forEach((answer) => {
        const score = parseFloat(answer.answer_score);
        const difference = Math.abs(average_score - score);

        if (difference < smallestDifference) {
            smallestDifference = difference;
            closestAnswerText = answer.answer_text;
        }
    });

    // Return the closest answer text in uppercase
    return closestAnswerText.toUpperCase();
}

function getHighestScoreAnswerWithPercentage(questionDetails: CategoryScoreQuestionDetails) {
    const { multiple_choice_answer_distribution, scorable_answer_count } = questionDetails;

    // Find the object with the highest answer_score
    const highestScoreAnswer = multiple_choice_answer_distribution.reduce((maxAnswer, currentAnswer) => {
        return parseFloat(currentAnswer.answer_score) > parseFloat(maxAnswer.answer_score)
            ? currentAnswer
            : maxAnswer;
    });

    const lowestScoreAnswer = multiple_choice_answer_distribution.reduce((minAnswer, currentAnswer) => {
        return parseFloat(currentAnswer.answer_score) < parseFloat(minAnswer.answer_score)
            ? currentAnswer
            : minAnswer;
    });

    // Calculate the percentage response count
    const highestScorePercentageResponse = Math.round((highestScoreAnswer.answer_response_count / scorable_answer_count) * 100 * 100) / 100;
    const lowestScorePercentageResponse = Math.round((lowestScoreAnswer.answer_response_count / scorable_answer_count) * 100 * 100) / 100;


    // Return answer_text in uppercase and percentage response rounded to two decimals
    return {
        positiveAnswerText: highestScoreAnswer.answer_text.toUpperCase(),
        positivePercentageResponse: highestScorePercentageResponse,
        negativeAnswerText: lowestScoreAnswer.answer_text.toUpperCase(),
        negativePercentageResponse: lowestScorePercentageResponse
    };
}


const BinaryResponseDisplay: React.FC<AnswerSummaryProps> = ({
    questionDetails,
}) => {

    const averageAnswerText = getClosestAnswerText(questionDetails);
    const answerDetails = getHighestScoreAnswerWithPercentage(questionDetails);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '29px', marginLeft: '1.5em' }}>
            {/* Answer Section */}
            <Box>
                <Typography
                    sx={{
                        color: '#053D58',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        lineHeight: '15px',
                        letterSpacing: '-0.36px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    Answer:
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        color: '#007BFF',
                        paddingTop: '4px',
                        height: '30px',
                    }}
                >
                    {/* Conditional rendering of icons */}
                    {averageAnswerText === answerDetails.positiveAnswerText ? (
                        <CheckCircleIcon fontSize="small" sx={{ color: '#2FA9E3' }} />
                    ) : averageAnswerText === answerDetails.negativeAnswerText ? (
                        <CancelIcon fontSize="small" sx={{ color: '#D43333' }} />
                    ) : null}

                    <Typography
                        sx={{
                            color: '#2E3645',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '19px',
                        }}
                    >
                        {averageAnswerText}
                    </Typography>
                </Box>
            </Box>

            {/* Divider */}
            <Box sx={{ borderLeft: '1px solid #626262', height: '49px' }} />

            {/* Total answers Section */}
            <Box>
                <Typography
                    sx={{
                        color: '#053D58',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        lineHeight: '15px',
                        letterSpacing: '-0.36px',
                    }}
                >
                    Total answers:
                </Typography>
                <Box sx={{ display: 'flex', gap: '50px', marginTop: '4px' }}>
                    <AnswerBox
                        label={answerDetails.positiveAnswerText}
                        percentage={answerDetails.positivePercentageResponse}
                        icon={<CheckCircleIcon fontSize="small" sx={{ color: '#2FA9E3' }} />}
                        color="#053D58"
                        backgroundColor="#e6f7ff"
                    />
                    <AnswerBox
                        label={answerDetails.negativeAnswerText}
                        percentage={answerDetails.negativePercentageResponse}
                        icon={<CancelIcon fontSize="small" sx={{ color: '#D43333' }} />}
                        color="#053D58"
                        backgroundColor="#FFEBEB"
                    />
                </Box>
            </Box>
        </Box >
    );
};

export default BinaryResponseDisplay;
