import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ScoresByCategoryAssessmentDetails, ScoresByCategoryReducer } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: ScoresByCategoryReducer = {
    assessment_category_details: null,
    status: RequestStatus.Init,
};

export const getScoresByCategory = createAsyncThunk(
    'assessment/getScoresByCategory',
    async (assessmentId: string, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<ScoresByCategoryAssessmentDetails>(
                `${EndPoints.ASSESSMENT_SCORES_BY_CATEGORY}/${assessmentId}`,
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getScoresByCategorySlice = createSlice({
    name: 'getScoresByCategory',
    initialState,
    reducers: {
        clearScoresByCategory: (state) => {
            state.assessment_category_details = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getScoresByCategory.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getScoresByCategory.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.assessment_category_details = action.payload;
            })
            .addCase(getScoresByCategory.rejected, (state) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearScoresByCategory } = getScoresByCategorySlice.actions;

export default getScoresByCategorySlice.reducer;
