import { useTheme, createTheme } from '@mui/material/styles';

export const useFormTheme = () => {
  const currentTheme = useTheme();

  const formTheme = createTheme(currentTheme, {
    typography: {
      fontFamily: `Poppins`,
      fontSize: 12,
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h4: {
            fontFamily: `Poppins`,
            fontSize: 18,
            fontWeight: 700,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            padding: '17 16',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            borderRadius: '4px',
            background: '#F1F1F1',
          },
        },
      },
    },
  });

  return formTheme;
};