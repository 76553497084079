import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { ScoinSuppliers } from '../type';
import { RequestStatus } from '../../../types/request';


export interface ScoinSuppliersReducer {
  status: RequestStatus;
  scoinSuppliers: ScoinSuppliers | null;
}

const initialState: ScoinSuppliersReducer = {
  status: RequestStatus.Init,
  scoinSuppliers: null
};

export const getSupplierInfo = createAsyncThunk(
  'scoin/getSupplierInfo',
  async () => {
    const response: AxiosResponse<ScoinSuppliers> = await axiosInstance.get(
      `${EndPoints.SCOIN_SUPPLIER}`,
    );
    return response.data;
  },
);

const ScoinSuppliersSlice = createSlice({
  name: 'scoinsuppliers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupplierInfo.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(
      getSupplierInfo.fulfilled,
      (state, action: PayloadAction<ScoinSuppliers>) => {
        state.scoinSuppliers = action.payload;
        state.status = RequestStatus.Done;
      },
    );
    builder.addCase(getSupplierInfo.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export default ScoinSuppliersSlice.reducer;
