import React from 'react';
import { Box, Typography, Grid, Link, IconButton, Tooltip } from '@mui/material';
import { CustomAccountBranding } from './../../Branding/type';
import SurveryCompletionGraphic from '../../../assets/svg-components/SurveryCompletionGraphic';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';

interface CompletionConfirmationTabProps {
    childSurveyLink: string | null;
    branding: CustomAccountBranding;
}

const CompletionConfirmationTab: React.FC<CompletionConfirmationTabProps> = ({ childSurveyLink, branding }) => {
    return (
        <Grid container
            spacing={2}
            sx={{
                padding: 2,
                marginX: '5%',
                marginTop: '3em',
                display: 'flex',
                justifyContent: 'center',
            }}>
            {/* Main Thank You Message Section */}
            <Grid item xs={12}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        // padding: '20px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '85%',
                        gap: 5
                    }}
                >
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                color: branding.primary_color || '#053D58',
                                fontFamily: 'Inter',
                                fontSize: '48px',
                                fontWeight: 700,
                            }}
                        >
                            Thank you for completing the survey!
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#6A6A6A',
                                fontFamily: 'Inter',
                                fontSize: '24px',
                                fontWeight: 500,
                                mt: '5%',
                                mb: '5%',
                            }}
                        >
                            Your feedback is greatly appreciated.
                        </Typography>
                    </Box>
                    <SurveryCompletionGraphic />
                </Box>
            </Grid>


            {childSurveyLink && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3em' }}>
                    <Box
                        sx={{
                            borderTop: '2px solid #F5BD4F',
                            borderLeft: '2px solid #F5BD4F',
                            borderRight: '6px solid #F5BD4F',
                            borderBottom: '6px solid #F5BD4F',
                            borderRadius: '8px',
                            paddingY: '2em',
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', width: 'auto' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#000000',
                                    fontFamily: 'Inter',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    mb: 2,
                                }}
                            >
                                This is your direct link to the Employee Engagement Survey:
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#666666',
                                    fontFamily: 'Inter',
                                    fontSize: '20px',
                                    fontWeight: 400,
                                    mb: 2,
                                }}
                            >
                                Copy and share this link with your workforce to quickly assess their perceptions.
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Tooltip title="Open link">
                                    <IconButton
                                        onClick={() => window.open(childSurveyLink, '_blank')}
                                        size="large"
                                        sx={{
                                            color: '#6A6A6A',
                                            backgroundColor: '#E9F2FA',
                                            borderRadius: '0px',
                                            padding: '12px',
                                            mr: 1,
                                            border: '2px solid #D7DDF0'
                                        }}
                                    >
                                        <LinkIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>

                                <Link
                                    href={childSurveyLink}
                                    sx={{
                                        color: '#2E3645',
                                        textDecoration: 'underline',
                                        fontFamily: 'Inter',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        wordBreak: 'break-all',
                                        backgroundColor: '#F7F7F7',
                                        borderRadius: '4px',
                                        padding: '12px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    {childSurveyLink}
                                </Link>

                                {/* Tooltip with IconButton */}
                                <Tooltip title="Copy link">
                                    <IconButton
                                        onClick={() =>
                                            navigator.clipboard.writeText(childSurveyLink)
                                        }
                                        size="small"
                                        sx={{
                                            color: '#6A6A6A',
                                            ml: 1,
                                            backgroundColor: '#E9F2FA',
                                            borderRadius: '4px',
                                            padding: '12px',
                                        }}
                                    >
                                        <ContentCopyIcon fontSize="small" />
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: 600,
                                                ml: 0.5,
                                            }}
                                        >
                                            Copy
                                        </Typography>
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        </Box>
                    </Box>
                </Grid>


            )}
        </Grid>
    );
};

export default CompletionConfirmationTab;
