import { useDispatch } from 'react-redux';
import { clearAssessmentNotes, getAssessmentNotes } from '../reducers/getAssessmentNotes';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

interface useGetAssessmentNotesProps {
    loading: boolean;
    error: AxiosError | null;
    getNotes: (id: string) => void;
}

const useGetAssessmentNotes = (): useGetAssessmentNotesProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const getNotes = async (id: string) => {
        try {
            setLoading(true);
            clearAssessmentNotes();
            const resultAction = await dispatch(getAssessmentNotes(id));
            if (getAssessmentNotes.fulfilled.match(resultAction)) {
                setLoading(false);
            } else if (getAssessmentNotes.rejected.match(resultAction)) {
                setLoading(false);
                setError(resultAction.payload as AxiosError);
            }
        } catch (err) {
            setLoading(false);
            setError(err as AxiosError);
        }
    };

    return { error, loading, getNotes };
};

export default useGetAssessmentNotes;