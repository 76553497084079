import { useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import { getEvaluationQuestionCategories } from '../reducers/getEvaluationQuestionCategory';

interface evaluationQuestionCategoriesProps {
  loading: boolean;
  error: unknown;
  getQuestionCategories: () => void;
}

const useGetEvaluationQuestionCategories =
  (): evaluationQuestionCategoriesProps => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown>();

    const getQuestionCategories = async () => {
      try {
        setLoading(true);
        const resultAction = await dispatch(getEvaluationQuestionCategories());
        if (getEvaluationQuestionCategories.fulfilled.match(resultAction)) {
          setLoading(false);
        }
      } catch (error) {
        setError(error);
      }
    };

    return { loading, error, getQuestionCategories };
  };

export default useGetEvaluationQuestionCategories;
