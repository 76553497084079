import React from 'react';
import translation from '../../../locales/en/translation.json';

//useController == true for array fo strings

export const categoryNoteFields = [
    {
        name: 'strengths_string',
        translationLabel: translation.strengths,
        translationSubtext: translation.evaluation_score_analysis_strengths_label_subtext,
        placeholder: translation.evaluation_score_analysis_strengths_placeholder,
        useController: true,
        minRows: 0,
        maxRows: 8,
    },
    {
        name: 'challenges_threats_string',
        translationLabel: translation.challenges_threats,
        translationSubtext: translation.evaluation_score_analysis_challenges_threats_label_subtext,
        placeholder: translation.evaluation_score_analysis_challenges_threats_placeholder,
        useController: true,
        minRows: 6,
        maxRows: 8,
    },
    {
        name: 'root_causes_string',
        translationLabel: translation.root_cause,
        translationSubtext: translation.evaluation_score_analysis_root_cause_label_subtext,
        placeholder: translation.evaluation_score_analysis_root_causes_placeholder,
        useController: true,
        minRows: 0,
        maxRows: 8,
    },

    {
        name: 'possible_offerings_string',
        translationLabel: translation.possible_offerings,
        translationSubtext: translation.evaluation_score_analysis_offerings_label_subtext,
        placeholder: translation.evaluation_score_analysis_offerings_placeholder,
        useController: true,
        minRows: 6,
        maxRows: 8,
    },
    {
        name: 'go_forward_plan',
        translationLabel: translation.go_forward_plan,
        translationSubtext: translation.evaluation_score_analysis_go_forward_plan_cause_label_subtext,
        placeholder: translation.evaluation_score_analysis_go_forward_plan_placeholder,
        minLength: 0,
    },
    //   {
    //     name: 'key_priorities',
    //     translationLabel: translation.key_priorities,
    //     translationSubtext: translation.evaluation_score_analysis_key_priorities_cause_label_subtext,
    //     placeholder: translation.evaluation_score_analysis_key_priorities_placeholder,
    //     useController: true,
    //     minRows: 0,
    //     maxRows: 8,
    //   },
    //   {
    //     name: 'development_opportunities',
    //     translationLabel: translation.development_opportunities,
    //     translationSubtext: translation.evaluation_score_analysis_development_opportunities_cause_label_subtext,
    //     placeholder: translation.evaluation_score_analysis_development_opportunities_placeholder,
    //     useController: true,
    //     minRows: 0,
    //     maxRows: 8,
    //   },
    //   {
    //     name: 'implementation_details_and_timing',
    //     translationLabel: translation.implementation_details_and_timing,
    //     translationSubtext: translation.evaluation_score_analysis_implementation_details_and_timing_cause_label_subtext,
    //     placeholder: translation.evaluation_score_analysis_implementation_details_and_timing_placeholder,
    //     useController: true,
    //     minRows: 0,
    //     maxRows: 8,
    //   },
];

export const assessmentAnalysisNotesFields = [
{
    name: 'key_priorities',
    translationLabel: translation.key_priorities,
    translationSubtext: translation.evaluation_score_analysis_key_priorities_cause_label_subtext,
    placeholder: translation.evaluation_score_analysis_key_priorities_placeholder,
    minRows: 0,
    maxRows: 8,
    minLength: 0,
    multiline: true,
    useController: false,
},
{
    name: 'development_opportunities',
    translationLabel: translation.development_opportunities,
    translationSubtext: translation.evaluation_score_analysis_development_opportunities_cause_label_subtext,
    placeholder: translation.evaluation_score_analysis_development_opportunities_placeholder,
    minRows: 0,
    maxRows: 8,
    minLength: 0,
    multiline: true,
    useController: false,
},
{
    name: 'implementation_details_and_timings',
    translationLabel: translation.implementation_details_and_timing,
    translationSubtext: translation.evaluation_score_analysis_implementation_details_and_timing_cause_label_subtext,
    placeholder: translation.evaluation_score_analysis_implementation_details_and_timing_placeholder,
    minRows: 0,
    maxRows: 8,
    minLength: 0,
    multiline: true,
    useController: false,
},
];

