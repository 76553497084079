export type Environment = 'development' | 'production' | 'uat' | 'qa' | 'local';

// Set the tenant name
export const TENANT_NAME = process.env.REACT_APP_TENANT_NAME;

// Currently not used; but will be useful in the future.
export const APP_ENV: Environment = (process.env.REACT_APP_APP_ENV as Environment) ?? 'local';

// must be declared as a const
const LOCAL_URL = 'http://localhost:8000/';

// If we have a pulled in URL use that; otherwise local URL
export const BASE_URL: string = process.env.REACT_APP_URL ?? LOCAL_URL;
