import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AccountSettingsUser } from '../AccountSettings/AccountSettings';

export interface AccountSettingInitialState {
  status: RequestStatus;
  accountSetting: AccountSettingsUser[] | null;
}

const initialState: AccountSettingInitialState = {
  status: RequestStatus.Init,
  accountSetting: null,
};

const AccountSettingSlice = createSlice({
  name: 'accountSetting',
  initialState,
  reducers: {
    resetState: (state) => {
      state.accountSetting = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountSetting.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(
      getAccountSetting.fulfilled,
      (state, action: PayloadAction<AccountSettingsUser[]>) => {
        state.accountSetting = action.payload;
        state.status = RequestStatus.Done;
      },
    );
    builder.addCase(getAccountSetting.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const { resetState } = AccountSettingSlice.actions;

export const accountSettingReducer = AccountSettingSlice.reducer;

export const getAccountSetting = createAsyncThunk<
  AccountSettingsUser[],
  string
>('accountSetting', async (id: string) => {
  const response = await getAccountSettingById(id);
  return response.data;
});

const getAccountSettingById = async (
  id: string,
): Promise<AxiosResponse<AccountSettingsUser[]>> => {
  return await axiosInstance.get(`${EndPoints.ACCOUNT_SETTING + id}/`);
};
