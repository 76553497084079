import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { EvaluationListPayload } from '../type';
import { useState } from 'react';
import { postEvaluationList } from '../reducers/postEvaluationList';

interface evaluationListProp {
  loading: boolean;
  error: unknown;
  getAllEvaluations: (evaluationPayload: EvaluationListPayload) => void;
}

const usePostEvaluationList = (): evaluationListProp => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const getAllEvaluations = async (
    evaluationPayload: EvaluationListPayload,
  ) => {
    try {
      const resultAction = await dispatch(
        postEvaluationList(evaluationPayload),
      );
      if (postEvaluationList.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, getAllEvaluations };
};

export default usePostEvaluationList;
