import React from 'react';

function AtIcon({ width = 16, height = 16, fill = '#1C1C1C' }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8C14 11.3137 11.3137 14 8 14C4.68628 14 2 11.3137 2 8C2 4.68629 4.68628 2 8 2C11.3137 2 14 4.68629 14 8Z"
        fill="#1C1C1C"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5C8 5 9.24264 5 10.1213 5.87868C10.1213 5.87868 11 6.75736 11 8C11 8 11 9.24264 10.1213 10.1213C10.1213 10.1213 9.24264 11 8 11C8 11 6.75736 11 5.87868 10.1213C5.87868 10.1213 5 9.24264 5 8C5 8 5 6.75736 5.87868 5.87868C5.87868 5.87868 6.75736 5 8 5ZM8 6C8 6 7.17157 6 6.58579 6.58579C6.58579 6.58579 6 7.17157 6 8C6 8 6 8.82843 6.58579 9.41421C6.58579 9.41421 7.17157 10 8 10C8 10 8.82843 10 9.41421 9.41421C9.41421 9.41421 10 8.82843 10 8C10 8 10 7.17157 9.41421 6.58579C9.41421 6.58579 8.82843 6 8 6Z"
        fill="#1C1C1C"
      />
      <path
        d="M11.0001 8.00013V5.50013C11.0001 5.22399 10.7762 5.00013 10.5001 5.00013C10.224 5.00013 10.0001 5.22399 10.0001 5.50013V8.00013C10.0001 9.25749 10.4817 10.0321 10.4817 10.0321C11.0836 11.0001 12.2501 11.0001 12.2501 11.0001C13.4166 11.0001 14.0185 10.0321 14.0185 10.0321C14.5001 9.25748 14.5001 8.00013 14.5001 8.00013C14.4996 5.76531 13.125 4.00337 13.125 4.00337C11.7504 2.24142 9.58299 1.69722 9.58299 1.69722C7.41556 1.15302 5.37171 2.05667 5.37171 2.05667C3.32786 2.96031 2.27176 4.92972 2.27176 4.92972C1.21567 6.89913 1.59387 9.1016 1.59387 9.1016C1.97207 11.3041 3.62465 12.8084 3.62465 12.8084C5.27723 14.3126 7.50546 14.4827 7.50546 14.4827C9.73344 14.6527 11.595 13.417 11.595 13.417L11.5954 13.4167C11.735 13.324 11.8189 13.1676 11.8189 13.0001L11.8188 12.9966C11.8182 12.8994 11.7892 12.8045 11.7354 12.7236C11.6427 12.584 11.4864 12.5001 11.3189 12.5001L11.3153 12.5001C11.2181 12.5008 11.1233 12.5298 11.0423 12.5836C9.46698 13.6295 7.58156 13.4856 7.58156 13.4856C5.69613 13.3417 4.2978 12.0688 4.2978 12.0688C2.89946 10.796 2.57944 8.93236 2.57944 8.93236C2.25943 7.06873 3.15304 5.40231 3.15304 5.40231C4.04666 3.73589 5.77608 2.97126 5.77608 2.97126C7.50549 2.20664 9.33947 2.66712 9.33947 2.66712C11.1735 3.12759 12.3366 4.61847 12.3366 4.61847C13.4997 6.10935 13.5001 8.00013 13.5001 8.00013C13.5001 8.97195 13.1692 9.50409 13.1692 9.50409C12.8608 10.0001 12.2501 10.0001 12.2501 10.0001C11.6394 10.0001 11.331 9.50409 11.331 9.50409C11.0001 8.97195 11.0001 8.00013 11.0001 8.00013Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default AtIcon;
