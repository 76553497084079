import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { ScoinNaicsCodes } from '../type';
import { RequestStatus } from '../../../types/request';


export interface ScoinNaicsCodesReducer {
  status: RequestStatus;
  scoinNaicsCodes: ScoinNaicsCodes | null;
}

const initialState: ScoinNaicsCodesReducer = {
  status: RequestStatus.Init,
  scoinNaicsCodes: null
};

export const getNaicsCodeInfo = createAsyncThunk(
  'scoin/getNaicsCodeInfo',
  async () => {
    const response: AxiosResponse<ScoinNaicsCodes> = await axiosInstance.get(
      `${EndPoints.SCOIN_NAICS_CODE}`,
    );
    return response.data;
  },
);

const ScoinNaicsCodesSlice = createSlice({
  name: 'scoinnaicscodes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNaicsCodeInfo.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(
      getNaicsCodeInfo.fulfilled,
      (state, action: PayloadAction<ScoinNaicsCodes>) => {
        state.scoinNaicsCodes = action.payload;
        state.status = RequestStatus.Done;
      },
    );
    builder.addCase(getNaicsCodeInfo.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export default ScoinNaicsCodesSlice.reducer;
