import { Grid, Box, styled } from '@mui/material';

export const AddUserCardGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 305px;
  min-width: 305px;
  padding: 20px;
  height: 128px;
  cursor: pointer;
  margin: 10px 0;
`;

export const UserCarBox = styled(Box)`
  border-radius: 16px;
  width: 305px;
  min-width: 305px;
  padding: 20px;
  height: 128px;
`;
