import React, { useEffect } from 'react';
import { TextField, ThemeProvider, Typography } from '@mui/material';
import translation from '../../../locales/en/translation.json';
import { Form, FormContainer } from '../Authentication.style';
import {
  CustomLink,
  ThemeForm,
  CustomButton,
  ErrorMessage,
} from '../../../styles/Global.styles';
import Hero from '../../../components/Hero/Hero';
import { useForm } from 'react-hook-form';
import {
  ResetPasswordFormInputs,
  ActivateAccountPayload,
  ResetPasswordParams,
} from '../Interfaces/Interfaces';
import { useLocation } from 'react-router-dom';
import useActivatePassword from '../hooks/useActivatePassword';
import { useFormTheme } from '../../../hooks/useFormTheme';

const ResetPasswordForm = () => {
  const formTheme = useFormTheme();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ResetPasswordFormInputs>();
  const password = watch('new_password', '');

  const location = useLocation();

  const queryParams = location.search.substring(1).split('&');

  const params: ResetPasswordParams = { uid: '', token: '' };

  useEffect(() => {
    queryParams.forEach((param) => {
      const [key, value] = param.split('=');
      params[key] = value;
    });
  }, [queryParams]);

  const { postActivateAccountMethod } = useActivatePassword();

  const onSubmit = (data: ResetPasswordFormInputs) => {
    const payload: ActivateAccountPayload = {
      uid: params.uid,
      token: params.token,
      ...data,
    };
    postActivateAccountMethod(payload);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <ThemeProvider theme={formTheme}>
          <Typography my={2} variant="h4" align="center">
            Reset Password
          </Typography>
          <div>Please create a new password</div>
          <ThemeForm>
            <TextField
              fullWidth
              placeholder={translation.password}
              label={translation.password}
              type="password"
              variant="outlined"
              {...register('new_password', {
                required: {
                  value: true,
                  message: translation.password_is_required,
                },
              })}
            />
            {errors.new_password && (
              <ErrorMessage>
                {errors.new_password?.message?.toString()}
              </ErrorMessage>
            )}

            <TextField
              fullWidth
              label={translation.confirm_password}
              type="password"
              placeholder={translation.confirm_password}
              variant="outlined"
              {...register('new_password_confirm', {
                required: {
                  value: true,
                  message: translation.confirm_password_is_required,
                },
                validate: (value): string | boolean => {
                  return (
                    value === password || translation.password_do_not_match
                  );
                },
                minLength: {
                  value: 8,
                  message: translation.min_password_length_error,
                },
                maxLength: {
                  value: 64,
                  message: translation.max_password_length_error,
                },
              })}
            />
            {errors.new_password_confirm && (
              <ErrorMessage>
                {errors.new_password_confirm?.message?.toString()}
              </ErrorMessage>
            )}

            <CustomButton variant="contained" type="submit" size="medium">
              {translation.create_new_password}
            </CustomButton>
          </ThemeForm>
        </ThemeProvider>
        <CustomLink to={'/'}>{translation.back_to_signin}</CustomLink>
      </FormContainer>
    </Form>
  );
};

const ResetPasswordComponent = () => {
  return <Hero component={<ResetPasswordForm />} />;
};

export default ResetPasswordComponent;
