import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AccountContact } from '../../Accounts/types';

export interface AccountInformation {
  id: number;
  account_contacts: AccountContact[];
  name: string;
  is_active: boolean;
  website: string;
  email: string;
  date_of_establishment: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  created_at: string;
  last_updated_at: string;
  created_by: string;
  last_updated_by: string;
  company_logo: string;
}

export interface AccountDetailInitialState {
  status: string;
  accountDetail: AccountInformation | null;
}

const initialState: AccountDetailInitialState = {
  status: '',
  accountDetail: null
};

export const getAccountInfo = createAsyncThunk<AccountInformation, string>(
  'evaluationDetail/getAccountInfo',
  async (id: string) => {
    const response: AxiosResponse<AccountInformation> = await axiosInstance.get(
      `${EndPoints.GET_CLIENT_ACCOUNT + id}`,
    );
    return response.data;
  },
);

const AccountInfoSlice = createSlice({
  name: 'accountInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountInfo.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(
      getAccountInfo.fulfilled,
      (state, action: PayloadAction<AccountInformation>) => {
        state.accountDetail = action.payload;
        state.status = 'done';
      },
    );
    builder.addCase(getAccountInfo.rejected, (state) => {
      state.status = 'error';
    });
  },
});

export default AccountInfoSlice.reducer;
