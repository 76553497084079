import axios, { AxiosError } from 'axios';
import { BASE_URL } from './env';
import Cookies from 'universal-cookie';
import { AuthConstants } from '../modules/Auth/constants';
import { toast } from 'react-toastify';
import { logout } from '../modules/Auth/reducers/authenticateUser';
import { store } from '../redux/store';
import { TenantInterface } from '../modules/Branding/type';
import { BrandingConstants } from '../modules/Branding/constants';

export let Tenant: TenantInterface

let apiURL: string
export const cookie = new Cookies();

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

export const axiosV2Instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

axios.get(BASE_URL + 'get-tenants/') 
  .then(res => {
    try{
      if (res.status == 200) {
        apiURL = res.data.domain as string
        cookie.set(BrandingConstants.api_url_name, res.data.domain as string)
        Tenant = res.data        
      }
      else {      
        apiURL = BASE_URL
      }
    }
    catch (error) {
      console.error('Failed to GET tenants:', error); 
    }
  });

let isSessionMessageShown = false;

axiosInstance.interceptors.request.use(async (config) => {
  const authorizationToken = cookie.get(AuthConstants.access_token);
  config.headers.Authorization = authorizationToken;
  if (apiURL == undefined) {
    await axios.get(BASE_URL + 'get-tenants/').then( res => {
      if (res.status == 200) {
        apiURL = res.data.domain as string
        cookie.set(BrandingConstants.api_url_name, res.data.domain as string)
        Tenant = res.data
      }
    });
    apiURL = cookie.get(BrandingConstants.api_url_name) as string
  }
  config.baseURL = apiURL + 'api/v1/';
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status == 401) {
      if (!isSessionMessageShown) {
        toast.error('Your session has expired!');
        isSessionMessageShown = true;
      }
      store.dispatch(logout());
    }
    throw error;
  },
);


axiosV2Instance.interceptors.request.use(async (config) => {
  const authorizationToken = cookie.get(AuthConstants.access_token);
  config.headers.Authorization = authorizationToken;
  if (apiURL == undefined) {
    await axios.get(BASE_URL + 'get-tenants/').then( res => {
      if (res.status == 200) {
        apiURL = res.data.domain as string
        cookie.set(BrandingConstants.api_url_name, res.data.domain as string)
        Tenant = res.data
      }
    });
    apiURL = cookie.get(BrandingConstants.api_url_name) as string
  }
  config.baseURL = apiURL + 'api/v2/';
  return config;
});

axiosV2Instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status == 401) {
      if (!isSessionMessageShown) {
        toast.error('Your session has expired!');
        isSessionMessageShown = true;
      }
      store.dispatch(logout());
    }
    throw error;
  },
);


export default axiosInstance;
