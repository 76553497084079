import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoginFormInputs } from '../Interfaces/Interfaces';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import Cookies from 'universal-cookie';
import { AuthConstants } from '../constants';
import { toast } from 'react-toastify';

type LoginResponse = { access: string; refresh: string; account_id: string; user_type: string[]};

export interface AuthResponse {
  currentUser: LoginResponse | null;
  loading: boolean;
  error: boolean;
}

const initialState: AuthResponse = {
  currentUser: null,
  loading: false,
  error: false,
};

export const authenticateUserSlice = createSlice({
  name: 'authenticateUser',
  initialState,
  reducers: {
    logout: (state) => {
      const cookie = new Cookies();
      cookie.remove(AuthConstants.access_token);
      state.currentUser = null;
      state.loading = false;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        // toast.success('Welcome Back!');
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        const errorMessage = 'Invalid credentials'
        toast.error(errorMessage);
      });
  },
});

export const login = createAsyncThunk<LoginResponse, LoginFormInputs>(
  `auth/login`,
  async (logInRequestParams: LoginFormInputs, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<LoginResponse> = await authenticateUser(
        logInRequestParams,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const authenticateUser = async (
  request: LoginFormInputs,
): Promise<AxiosResponse<LoginResponse>> => {
  return await axiosInstance.post(EndPoints.POST_LOG_IN, request);
};

export const { logout } = authenticateUserSlice.actions;

export default authenticateUserSlice.reducer;
