import React from 'react';

function IdentificationBadgeIcon({
  width = 16,
  height = 16,
  fill = '#1C1C1C',
}) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 2H12.5C12.6326 2 12.7598 2.05273 12.8535 2.14648C12.9473 2.24023 13 2.36743 13 2.5V13.5C13 13.6326 12.9473 13.7598 12.8535 13.8535C12.7598 13.9473 12.6326 14 12.5 14H3.5C3.36743 14 3.24023 13.9473 3.14648 13.8535C3.05273 13.7598 3 13.6326 3 13.5V2.5C3 2.36743 3.05273 2.24023 3.14648 2.14648C3.24023 2.05273 3.36743 2 3.5 2ZM6.88892 10.163C7.21777 10.3827 7.60449 10.5 8 10.5C8.5304 10.5 9.03918 10.2893 9.41418 9.91418C9.78931 9.53918 10 9.0304 10 8.5C10 8.10449 9.88269 7.71777 9.66296 7.38892C9.44312 7.05994 9.13086 6.80359 8.76538 6.65222C8.3999 6.50085 7.9978 6.4613 7.60986 6.53845C7.2218 6.6156 6.86548 6.80603 6.58582 7.08582C6.30603 7.36548 6.1156 7.7218 6.03845 8.10986C5.9613 8.4978 6.00085 8.8999 6.15222 9.26538C6.30359 9.63086 6.55994 9.94312 6.88892 10.163Z"
        fill="#1C1C1C"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C8 6 9.03553 6 9.76777 6.73223C9.76777 6.73223 10.5 7.46447 10.5 8.5C10.5 8.5 10.5 9.53553 9.76777 10.2678C9.76777 10.2678 9.03553 11 8 11C8 11 6.96447 11 6.23223 10.2678C6.23223 10.2678 5.5 9.53553 5.5 8.5C5.5 8.5 5.5 7.46447 6.23223 6.73223C6.23223 6.73223 6.96447 6 8 6ZM8 7C8 7 7.37868 7 6.93934 7.43934C6.93934 7.43934 6.5 7.87868 6.5 8.5C6.5 8.5 6.5 9.12132 6.93934 9.56066C6.93934 9.56066 7.37868 10 8 10C8 10 8.62132 10 9.06066 9.56066C9.06066 9.56066 9.5 9.12132 9.5 8.5C9.5 8.5 9.5 7.87868 9.06066 7.43934C9.06066 7.43934 8.62132 7 8 7Z"
        fill="#1C1C1C"
      />
      <path
        d="M9.45344 11.3431C10.1397 11.6862 10.6 12.3 10.6 12.3C10.6796 12.4061 10.798 12.4762 10.9293 12.495C10.9527 12.4983 10.9763 12.5 11 12.5L11.0017 12.5C11.1093 12.4996 11.2139 12.4646 11.3 12.4C11.4259 12.3056 11.5 12.1574 11.5 12L11.5 11.9934C11.4986 11.8875 11.4636 11.7848 11.4 11.7C10.798 10.8974 9.90066 10.4487 9.90066 10.4487C9.00329 10 8 10 8 10C6.99671 10 6.09934 10.4487 6.09934 10.4487C5.20197 10.8974 4.6 11.7 4.6 11.7C4.53509 11.7865 4.5 11.8918 4.5 12L4.50002 12.0044C4.50021 12.0266 4.50189 12.0487 4.50503 12.0707C4.52378 12.202 4.59391 12.3204 4.7 12.4C4.78655 12.4649 4.89181 12.5 5 12.5L5.0044 12.5C5.02659 12.4998 5.04874 12.4981 5.07071 12.495C5.20199 12.4762 5.32044 12.4061 5.4 12.3C5.86033 11.6862 6.54656 11.3431 6.54656 11.3431C7.23278 11 8 11 8 11C8.76722 11 9.45344 11.3431 9.45344 11.3431Z"
        fill="#1C1C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 1.5C12.5 1.5 12.9142 1.5 13.2071 1.79289C13.2071 1.79289 13.5 2.08579 13.5 2.5V13.5C13.5 13.5 13.5 13.9142 13.2071 14.2071C13.2071 14.2071 12.9142 14.5 12.5 14.5H3.5C3.5 14.5 3.08578 14.5 2.79289 14.2071C2.79289 14.2071 2.5 13.9142 2.5 13.5V2.5C2.5 2.5 2.5 2.08579 2.79289 1.79289C2.79289 1.79289 3.08579 1.5 3.5 1.5L12.5 1.5ZM12.5 2.5L3.5 2.5V13.5H12.5V2.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M6 4.5H10C10.2761 4.5 10.5 4.27614 10.5 4C10.5 3.72386 10.2761 3.5 10 3.5H6C5.72386 3.5 5.5 3.72386 5.5 4C5.5 4.27614 5.72386 4.5 6 4.5Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default IdentificationBadgeIcon;
