import { useDispatch, useSelector } from 'react-redux';
import { login } from '../reducers/authenticateUser';
import { DecodedTokenDetails, LoginFormInputs } from '../Interfaces/Interfaces';
import Cookies from 'universal-cookie';
import { decodeToken } from 'react-jwt';
import { AuthConstants } from '../constants';
import { AppDispatch } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useBranding } from '../../Branding/reducers/getBranding';
import {FeatureFlags} from '../../Branding/type'

interface useLoginProps {
  postLogin: (logInRequestParams: LoginFormInputs) => Promise<void>;
}

export function useLogin(): useLoginProps {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const cookie = new Cookies();
  const branding = useBranding();

  const postLogin = async (logInRequestParams: LoginFormInputs) => {
    const dashboardAccess = branding?.active_feature_flags?.[FeatureFlags.DASHBOARD] === true;
    const resultAction = await dispatch(login(logInRequestParams));
    if (login.fulfilled.match(resultAction)) {
      const accessToken = resultAction.payload.access;
      const token: DecodedTokenDetails | null =
        decodeToken<DecodedTokenDetails>(accessToken);

      const expiration = token ? +token.exp * 1000 : 0;
      cookie.set(AuthConstants.access_token, `Bearer ${accessToken}`, {
        expires: new Date(expiration),
      });

      if (
        (dashboardAccess)
        && (resultAction.payload.user_type?.includes("admin") || resultAction.payload.user_type?.includes("account_executive"))
      ) {
        navigate('/dashboard');
      } else if (
        (dashboardAccess)
        && (!resultAction.payload.user_type?.includes("admin") && !resultAction.payload.user_type?.includes("account_executive"))
      ) {
        navigate('/client-dashboard');
      }
      else {
        navigate('/account-list');
      }
    }
  };

  return { postLogin };
}
