import React from 'react';

function InfoIcon({ width = 16, height = 16, fill = '#1C1C1C' }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8C14 11.3137 11.3137 14 8 14C4.68628 14 2 11.3137 2 8C2 4.68629 4.68628 2 8 2C11.3137 2 14 4.68629 14 8Z"
        fill="#1C1C1C"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5C8 1.5 9.32209 1.5 10.5302 2.01101C10.5302 2.01101 11.6968 2.50442 12.5962 3.40381C12.5962 3.40381 13.4956 4.30319 13.989 5.46975C13.989 5.46975 14.5 6.67791 14.5 8C14.5 8 14.5 9.32208 13.989 10.5302C13.989 10.5302 13.4956 11.6968 12.5962 12.5962C12.5962 12.5962 11.6968 13.4956 10.5302 13.989C10.5302 13.989 9.32208 14.5 8 14.5C8 14.5 6.67791 14.5 5.46975 13.989C5.46975 13.989 4.30319 13.4956 3.40381 12.5962C3.40381 12.5962 2.50442 11.6968 2.01101 10.5302C2.01101 10.5302 1.5 9.32209 1.5 8C1.5 8 1.5 6.67791 2.01101 5.46975C2.01101 5.46975 2.50442 4.30319 3.40381 3.40381C3.40381 3.40381 4.30319 2.50442 5.46975 2.01101C5.46975 2.01101 6.67791 1.5 8 1.5ZM8 2.5C8 2.5 6.8807 2.5 5.85931 2.93201C5.85931 2.93201 4.87238 3.34945 4.11091 4.11091C4.11091 4.11091 3.34945 4.87238 2.93201 5.85931C2.93201 5.85931 2.5 6.8807 2.5 8C2.5 8 2.5 9.1193 2.93201 10.1407C2.93201 10.1407 3.34945 11.1276 4.11091 11.8891C4.11091 11.8891 4.87238 12.6506 5.85931 13.068C5.85931 13.068 6.8807 13.5 8 13.5C8 13.5 9.1193 13.5 10.1407 13.068C10.1407 13.068 11.1276 12.6506 11.8891 11.8891C11.8891 11.8891 12.6506 11.1276 13.068 10.1407C13.068 10.1407 13.5 9.1193 13.5 8C13.5 8 13.5 6.8807 13.068 5.85931C13.068 5.85931 12.6506 4.87238 11.8891 4.11091C11.8891 4.11091 11.1276 3.34945 10.1407 2.93201C10.1407 2.93201 9.1193 2.5 8 2.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M8 11.5H8.5C8.77614 11.5 9 11.2761 9 11C9 10.7239 8.77614 10.5 8.5 10.5V7.5C8.5 7.22386 8.27614 7 8 7H7.5C7.22386 7 7 7.22386 7 7.5C7 7.77614 7.22386 8 7.5 8V11C7.5 11.2761 7.72386 11.5 8 11.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M8.625 5.25C8.625 5.66422 8.28918 6 7.875 6C7.46082 6 7.125 5.66422 7.125 5.25C7.125 4.83578 7.46082 4.5 7.875 4.5C8.28918 4.5 8.625 4.83578 8.625 5.25Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default InfoIcon;
