import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  ThemeProvider,
  Typography,
  Grid,
} from '@mui/material';
import translation from '../../../locales/en/translation.json';
import { Form, FormContainer } from '../Authentication.style';
import {
  CustomLink,
  ThemeForm,
  ErrorMessage,
  CustomButton,
} from '../../../styles/Global.styles';
import Hero from '../../../components/Hero/Hero';
import { useForm } from 'react-hook-form';
import { LoginFormInputs } from '../Interfaces/Interfaces';
import { useLogin } from '../hooks/useLogin';
import { useFormTheme } from '../../../hooks/useFormTheme';

const LoginForm = () => {
  const formTheme = useFormTheme();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormInputs>();

  const { postLogin } = useLogin();
  const onSubmit = (data: LoginFormInputs) => {
    postLogin(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <ThemeProvider theme={formTheme}>
          <Typography my={2} variant="h4" align="center">
            Hello Again
          </Typography>
          <div>Enter your credentials to access your account</div>
          <ThemeForm>
            <TextField
              fullWidth
              variant="outlined"
              label={translation.email}
              placeholder={translation.email}
              {...register('email', {
                required: {
                  value: true,
                  message: translation.email_is_required,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: translation.invalid_email,
                },
              })}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message?.toString()}</ErrorMessage>
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label={translation.password}
              placeholder={translation.password}
              {...register('password', {
                required: {
                  value: true,
                  message: translation.password_is_required,
                },
              })}
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message?.toString()}</ErrorMessage>
            )}
            <Grid
              justifyContent={'space-between'}
              display={'flex'}
              alignItems={'center'}
            >
              <FormControlLabel
                value="end"
                control={<Checkbox color="default" />}
                label="Remember me"
                labelPlacement="end"
              />
              <CustomLink
                to={'/forget-password'}
                style={{
                  textAlign: 'right',
                }}
              >
                {translation.forget_password}
              </CustomLink>
            </Grid>
            <CustomButton type="submit" variant="contained" size="medium">
              {translation.log_in}
            </CustomButton>
          </ThemeForm>
        </ThemeProvider>
      </FormContainer>
    </Form>
  );
};

const LoginComponent = () => {
  return <Hero component={<LoginForm />} />;
};

export default LoginComponent;
