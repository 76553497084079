import { useDispatch } from 'react-redux';
import { getEvaluationName } from '../reducers/getEvaluationName';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

interface useGetNameProps {
  evaluationNameLoading: boolean;
  evaluationNameError: AxiosError | undefined;
}

const useGetEvaluationName = (
  shareableLinkId: string,
): useGetNameProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();

  const getName = async () => {
    const resultAction = await dispatch(
      getEvaluationName(shareableLinkId),
    ).then((res) => {
      if (getEvaluationName.fulfilled.match(res)) {
        setLoading(false);
      }
      if (getEvaluationName.rejected.match(res)) {
        setLoading(false);
        setError(res.payload as AxiosError);
      }
    });
  };

  useEffect(() => {
    getName();
  }, []);

  return { evaluationNameError: error, evaluationNameLoading: loading };
};

export default useGetEvaluationName;
