/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import EvaluationCard from '../../Evaluations/EvaluationList/components/v1/EvaluationCard';
import { useSelector } from 'react-redux';
import usePostEvaluationList from '../../Evaluations/hooks/usePostEvaluationList';
import { RootStoreType } from '../../../redux/store.type';
import useDeleteEvaluation from '../../Evaluations/hooks/useDeleteEvaluation';
import ConfirmationBox from '../../../components/ConfirmationBox/ConfirmBox';

const AccountEvaluation = (prop: { id: string }) => {
  const { error, loading, getAllEvaluations } = usePostEvaluationList();
  const [deleteEvaluationId, setDeleteEvaluationId] = useState('');
  const [openConfirmBox, SetOpenConfirmBox] = useState(false);

  const deleteEvaluationReducer = useDeleteEvaluation();

  useEffect(() => {
    getAllEvaluations({ account_id: prop.id });
  }, [deleteEvaluationReducer.loading]);

  const evaluationDetails = useSelector((state: RootStoreType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.allEvaluationReducers.postEvaluationListReducer
      .evaluationResponse?.results;
  });

  const handleDelete = (id: string) => {
    setDeleteEvaluationId(id);
    SetOpenConfirmBox(true);
  };

  return (
    <Box sx={{ maxWidth: '1540px' }}>
      {error || loading ? (
        <CircularProgress sx={{ margin: '20vh 48%' }} />
      ) : evaluationDetails && evaluationDetails.length > 0 ? (
        evaluationDetails.map((evaluation) => (
          <>
            <EvaluationCard
              key={evaluation.id}
              evaluationDetails={evaluation}
              deleteEvaluation={handleDelete}
            />
            {openConfirmBox && (
              <ConfirmationBox
                doOpen={openConfirmBox}
                deleteRecord={() => {
                  deleteEvaluationReducer.deleteEvaluationById(
                    deleteEvaluationId,
                  );
                }}
                doClose={() => {
                  SetOpenConfirmBox(false);
                }}
                confirmationMessage="Respondents will not be able to submit responses after it has been deleted."
              />
            )}
          </>
        ))
      ) : (
        <Typography variant="h6" paddingTop={2} color="textSecondary">
          {'No evaluation is available for this account.'}
        </Typography>
      )}
    </Box>
  );
};

export default AccountEvaluation;
