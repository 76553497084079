import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getEvaluationDetail } from '../reducers/getEvaluationDetail';

interface actionStateProps {
  loading: boolean;
  error: unknown;
  getEvaluationDetailInfo: (id: string) => void;
}

const useGetEvaluationDetail = (): actionStateProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const getEvaluationDetailInfo = async (id: string) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(getEvaluationDetail(id));
      if (getEvaluationDetail.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }
  };

  return { loading, error, getEvaluationDetailInfo };
};

export default useGetEvaluationDetail;
