import React from 'react';

function PhoneIcon({ width = 16, height = 16, fill = '#1C1C1C' }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.27404 5.9999L6.23226 7.58437C6.23226 7.58437 6.95085 9.06665 8.43273 9.77956L8.43477 9.77819L9.99434 8.73641C9.99434 8.73641 10.2062 8.59275 10.461 8.56928C10.461 8.56928 10.7159 8.5458 10.9473 8.64704L13.8671 9.9011C13.8671 9.9011 14.1773 10.0302 14.3455 10.3211C14.3455 10.3211 14.5137 10.6119 14.4709 10.945C14.4709 10.945 14.3031 12.2548 13.3119 13.1274C13.3119 13.1274 12.3207 13.9999 11 14.0002C11 14.0002 9.20979 14.0002 7.55585 13.3151C7.55585 13.3151 5.90191 12.63 4.63604 11.3642C4.63604 11.3642 3.37017 10.0983 2.68508 8.44436C2.68508 8.44436 2 6.79042 2 5.0001C2 5.0001 2.0003 3.67955 2.87283 2.68831C2.87283 2.68831 3.74536 1.69708 5.05505 1.52928C5.05505 1.52928 5.3883 1.48648 5.67915 1.65468C5.67915 1.65468 5.97001 1.82289 6.09707 2.12825L7.35236 5.05727C7.35236 5.05727 7.45187 5.28639 7.43195 5.53539C7.43195 5.53539 7.41203 5.78439 7.27404 5.9999ZM5.33024 8.01605L5.32944 8.01438C5.32944 8.01438 5.21768 7.77982 5.23431 7.5204C5.23431 7.5204 5.251 7.26006 5.39274 7.04105L6.43512 5.4556L5.17793 2.52217C5.17793 2.52217 4.24512 2.64281 3.62345 3.34905C3.62345 3.34905 3.00021 4.05707 3 5.00032C3 5.00032 3 6.59151 3.60896 8.06168C3.60896 8.06168 4.21793 9.53184 5.34315 10.6571C5.34315 10.6571 6.46836 11.7823 7.93853 12.3912C7.93853 12.3912 9.4087 13.0002 11 13.0002C11 13.0002 11.9431 13 12.6512 12.3768C12.6512 12.3768 13.3575 11.755 13.4785 10.8224L10.5528 9.56592L8.99023 10.6097C8.99023 10.6097 8.77267 10.7566 8.50846 10.7767C8.50846 10.7767 8.24425 10.7968 8.00477 10.6834C8.00477 10.6834 6.20186 9.81602 5.33124 8.01813L5.33086 8.01735L5.33064 8.01688L5.33024 8.01605Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default PhoneIcon;
