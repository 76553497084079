import { useDispatch } from 'react-redux';
import { getLightAssessmentDetails } from '../reducers/getLightAssessmentDetails';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

interface useGetLightAssessmentDetailsProps {
    loading: boolean;
    error: AxiosError | null;
    getLightDetails: (id: string) => void;
}

const useGetLightAssessmentDetails = (): useGetLightAssessmentDetailsProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const getLightDetails = async (id: string) => {
        try {
            setLoading(true);
            const resultAction = await dispatch(getLightAssessmentDetails(id));
            if (getLightAssessmentDetails.fulfilled.match(resultAction)) {
                setLoading(false);
            } else if (getLightAssessmentDetails.rejected.match(resultAction)) {
                setLoading(false);
                setError(resultAction.payload as AxiosError);
            }
        } catch (err) {
            setLoading(false);
            setError(err as AxiosError);
        }
    };

    return { error, loading, getLightDetails };
};

export default useGetLightAssessmentDetails;