import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AssessmentEnquiryFormRequest } from '../type';
import { toast } from 'react-toastify';
import { RequestStatus } from '../../../types/request';

// Define the initial state type
interface PostAssessmentEnquiryFormState {
    status: RequestStatus;
    errorMessage?: string;
}

// Initialize state
const initialState: PostAssessmentEnquiryFormState = {
    status: RequestStatus.Init,
    errorMessage: undefined,
};

// Define error response type
interface ErrorResponse {
    message: string;
}

// Axios POST request function
const createAssessmentEnquiryForm = async (
    requestPayload: AssessmentEnquiryFormRequest,
    assessmentId: string
): Promise<AxiosResponse<void>> => {
    return axiosV2Instance.post<void>(
        `${EndPoints.PUBLIC_ASSESSMENT_RESPONSE}/${assessmentId}`,
        requestPayload
    );
};

// Async thunk for posting the assessment enquiry form
export const postAssessmentEnquiryForm = createAsyncThunk<
    void, // No return data expected from the fulfilled case
    { assessmentId: string; requestPayload: AssessmentEnquiryFormRequest }, // Arguments type
    { rejectValue: string } // Error payload type
>(
    'assessment/createAssessmentEnquiryForm',
    async ({ assessmentId, requestPayload }, thunkAPI) => {
        try {
            await createAssessmentEnquiryForm(requestPayload, assessmentId);
        } catch (error) {
            const axiosError = error as AxiosError<ErrorResponse>;
            const errorMessage = axiosError.response?.data?.message ?? 'Failed to submit form';
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// Slice
const postAssessmentEnquiryFormSlice = createSlice({
    name: 'postAssessmentEnquiryForm',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postAssessmentEnquiryForm.pending, (state) => {
                state.status = RequestStatus.Loading;
                state.errorMessage = undefined;
            })
            .addCase(postAssessmentEnquiryForm.fulfilled, (state) => {
                state.status = RequestStatus.Done;
                // toast.success('Evaluation Completed Successfully!');
            })
            .addCase(postAssessmentEnquiryForm.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.status = RequestStatus.Error;
                state.errorMessage = action.payload;
                if (action.payload) {
                    toast.error(action.payload);
                }
            });
    },
});

export default postAssessmentEnquiryFormSlice.reducer;
