import { createAsyncThunk, createSlice, PayloadAction  } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { EvaluationCategoryResponse } from '../type';

export interface EvaluationCategoriesResultAnalysisPayload {
  evaluation_category_id: string;
}

export interface EvaluationCategoriesResultAnalysisReducer {
  evaluationResponseAggregateByCategory: EvaluationCategoryResponse;
  status: RequestStatus;
}

const initialState: EvaluationCategoriesResultAnalysisReducer = {
  evaluationResponseAggregateByCategory: {
    evaluationCategoryId: '',
    evaluationQuestionCategories: []
  },
  status:RequestStatus.Init,
};

const postEvaluationCategoriesResultAnalysisSlice = createSlice({
  name: 'evaluation/postEvaluationCategoriesResultAnalysis',
  initialState,
  reducers: {
    resetEvaluationCategoriesResultAnalysisState: (state) => {
      state.evaluationResponseAggregateByCategory = {
        evaluationCategoryId: '',
        evaluationQuestionCategories: []
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvaluationCategoriesResultAnalysis.pending, (state) => {
        state.status = RequestStatus.Loading;
      })
      .addCase(fetchEvaluationCategoriesResultAnalysis.fulfilled, (state, action: PayloadAction<EvaluationCategoryResponse>) => {
        state.status = RequestStatus.Done;
        state.evaluationResponseAggregateByCategory = action.payload;
      })
      .addCase(fetchEvaluationCategoriesResultAnalysis.rejected, (state) => {
        state.status = RequestStatus.Error;
      });
  },
});

export const fetchEvaluationCategoriesResultAnalysis = createAsyncThunk<
EvaluationCategoryResponse,
EvaluationCategoriesResultAnalysisPayload
>(
  'fetchEvaluationCategoriesResultAnalysis',
  async (
    evaluationCategoriesPayload: EvaluationCategoriesResultAnalysisPayload,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<EvaluationCategoryResponse> =
        await axiosInstance.post<EvaluationCategoryResponse>(
          EndPoints.POST_EVALUATION_CATEGORIES_RESULT_ANALYSIS,
          evaluationCategoriesPayload,
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { resetEvaluationCategoriesResultAnalysisState } =
  postEvaluationCategoriesResultAnalysisSlice.actions;

export default postEvaluationCategoriesResultAnalysisSlice.reducer;