import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import {
  getEvaluationScoreHistory as getEvaluationScoreHistory,
  EvaluationScoreHistoryPayload as EvaluationScoreHistoryPayload,
} from '../reducers/postEvaluationScoreHistory';

const usePostEvaluationScoreHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const postEvaluationScoreHistory = async (
    requestPayload: EvaluationScoreHistoryPayload,
  ) => {
    try {
      const resultAction = await dispatch(getEvaluationScoreHistory(requestPayload));
      if (getEvaluationScoreHistory.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, postEvaluationScoreHistory: postEvaluationScoreHistory };
};

export default usePostEvaluationScoreHistory;
