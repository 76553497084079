import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { AxiosResponse } from 'axios';
import { UpdateAssessorScoreData as UpdateAssessorScoreData } from '../type';


export interface AssessmentAssessorScoreReducer {
  status: RequestStatus;
  assessmentAssessorScoreResponse: UpdateAssessorScoreData | null;
}

const initialState: AssessmentAssessorScoreReducer = {
  status: RequestStatus.Init,
  assessmentAssessorScoreResponse: null,
};

const postAssessmentAssessorScoreSlice = createSlice({
  name: 'assessment/postAssessmentAssessorScores',
  initialState: initialState,
  reducers: {
    resetAssessmentAssessorScoreState: (state) => {
      state.assessmentAssessorScoreResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postAssessmentAssessorScore.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postAssessmentAssessorScore.fulfilled, (state, action) => {
      state.assessmentAssessorScoreResponse = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(postAssessmentAssessorScore.rejected, (state, action) => {
      //this needs to be set so we can highlight the correct input on error
      state.assessmentAssessorScoreResponse = action.meta.arg;
      state.status = RequestStatus.Error;
    });
  },
});

export const postAssessmentAssessorScore = createAsyncThunk<
  UpdateAssessorScoreData,
  UpdateAssessorScoreData
>(
  'postAssessmentAssessorScore',
  async (
    assessmentAssessorScorePayload: UpdateAssessorScoreData,
    { rejectWithValue },
  ) => {
    try {
      const url = `/assessment/assessorscore/${assessmentAssessorScorePayload.assessment_id}`
      const result: AxiosResponse<UpdateAssessorScoreData> =
        await axiosV2Instance.put<UpdateAssessorScoreData>(
          url,
          assessmentAssessorScorePayload
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);


export const { resetAssessmentAssessorScoreState } =
  postAssessmentAssessorScoreSlice.actions;

export default postAssessmentAssessorScoreSlice.reducer;
