import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getEvaluationCategories } from '../reducers/getEvaluationsCategories';

interface evaluationCategoriesProps {
  loading: boolean;
  error: unknown;
}

const useGetEvaluationCategories = (): evaluationCategoriesProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const getAllEvaluationCategories = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(getEvaluationCategories());
      if (getEvaluationCategories.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getAllEvaluationCategories();
  }, []);

  return { loading, error };
};

export default useGetEvaluationCategories;
