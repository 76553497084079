import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EvaluationQuestionReducer, EvaluationQuestions } from '../types';
import { RequestStatus } from '../../../types/request';
import { storeKey } from '../constants';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: EvaluationQuestionReducer = {
  questions: [],
  status: RequestStatus.Init,
};

const getEvaluationQuestionsSlice = createSlice({
  name: 'getEvaluationQuestions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvaluationQuestions.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationQuestions.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.questions = action.payload;
    });
    builder.addCase(getEvaluationQuestions.rejected, (state, action) => {
      state.status = RequestStatus.Done;
      state.questions = [];
    });
  },
});

export const getEvaluationQuestions = createAsyncThunk(
  `${storeKey}/getEvaluationQuestions`,
  async (questionId: string, { rejectWithValue }) => {
    try {
      const response = await evaluationQuestions(questionId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const evaluationQuestions = (
  questionId: string,
): Promise<AxiosResponse<EvaluationQuestions[]>> => {
  return axiosInstance.get(
    `${EndPoints.GET_EVALUATION_QUESTIONS + questionId}/`,
  );
};

export default getEvaluationQuestionsSlice.reducer;
