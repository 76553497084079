import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {
  Container,
  Grid,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Typography,
  Box,
} from '@mui/material';
import counties from '../helpers/Counties';
import MenuHelper from '../helpers/Helpers';

interface IntakeFormCountyIntakeProps {
  formData: { county: string };
  onFormChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => void;
}

const IntakeFormCountyIntake: FunctionComponent<
  IntakeFormCountyIntakeProps
> = ({ formData, onFormChange }) => {
  return (
    <Container>
      <Grid container>
        <Typography variant="h4" color="primary" width="inherit">
          County
        </Typography>
        <Box sx={{ marginTop: '3%' }}>
          <Typography variant="body1">
            Please identify and select the county where your company is located
            from the drop-down list.
          </Typography>
        </Box>

        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" sx={{ marginTop: '4%' }}>
            <InputLabel id="county-select-label">Select County</InputLabel>
            <Select
              labelId="county-select-label"
              id="county-select"
              name="county"
              value={formData.county}
              onChange={(event: SelectChangeEvent<string>) =>
                onFormChange(event)
              }
              label="Select County"
              MenuProps={MenuHelper}
            >
              {counties.map((county) => (
                <MenuItem key={county} value={county}>
                  {county}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntakeFormCountyIntake;
