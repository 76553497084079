import { combineReducers } from '@reduxjs/toolkit';
import getSuppliersReducer, { ScoinSuppliersReducer } from './getSuppliers';
import getNaicsCodesReducer, { ScoinNaicsCodesReducer } from './getNaicsCodes';

export default combineReducers<{
    getSuppliersReducer: ScoinSuppliersReducer;
    getNaicsCodesReducer: ScoinNaicsCodesReducer;
}>({
  getSuppliersReducer,
  getNaicsCodesReducer,
});
