import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { ResultEvaluationResponse } from '../type';

export interface EvaluationResultAnalysisPayload {
  evaluation_id: string;
  question_category_id: string;
}

export interface ResultsEvaluationReducer {
  status: RequestStatus;
  resultEvaluationResponses: { [categoryId: string]: ResultEvaluationResponse };
}

const initialState: ResultsEvaluationReducer = {
  status: RequestStatus.Init,
  resultEvaluationResponses: {}
};

const postEvaluationResultsAnalysisSlice = createSlice({
  name: 'evaluation/postEvaluationResultsAnalysisByCategory',
  initialState,
  reducers: {
    updateResultsAnalysis: (state) => {
      state.resultEvaluationResponses = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvaluationResults.pending, (state) => {
        state.status = RequestStatus.Loading;
      })
      .addCase(getEvaluationResults.fulfilled, (state, action) => {
        action.payload.forEach(item => {
          state.resultEvaluationResponses[item.categoryId] = item.response;
        });
        state.status = RequestStatus.Done;
      })
      .addCase(getEvaluationResults.rejected, (state) => {
        state.status = RequestStatus.Error;
      });
  },
});

export const getEvaluationResults = createAsyncThunk<
  { categoryId: string; response: ResultEvaluationResponse }[],
  EvaluationResultAnalysisPayload[]
>(
  'postEvaluationResultsAnalysis',
  async (evaluationPayloads, { rejectWithValue }) => {
    try {
      const responses = await Promise.all(
        evaluationPayloads.map(async (payload) => {
          const response = await axiosInstance.post<ResultEvaluationResponse>(
            EndPoints.POST_EVALUATION_RESULT_ANALYSIS,
            payload,
          );
          return {
            categoryId: payload.question_category_id,
            response: response.data,
          };
        })
      );
      return responses;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { updateResultsAnalysis } =
  postEvaluationResultsAnalysisSlice.actions;

export default postEvaluationResultsAnalysisSlice.reducer;