import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { ResultEvaluationResponse } from '../type';

export interface EvaluationResultAnalysisPayload {
  evaluation_id: string;
  question_category_id: string;
}

export interface ResultEvaluationReducer {
  status: RequestStatus;
  resultEvaluationResponse: ResultEvaluationResponse | null;
}

const initialState: ResultEvaluationReducer = {
  status: RequestStatus.Init,
  resultEvaluationResponse: null,
};

const postEvaluationResultAnalysisSlice = createSlice({
  name: 'evaluation/postEvaluationResultAnalysisByCategory',
  initialState,
  reducers: {
    updateResultAnalysis: (state) => {
      state.resultEvaluationResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvaluationResult.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationResult.fulfilled, (state, action) => {
      state.resultEvaluationResponse = action.payload;
      state.status = RequestStatus.Done;
    });
    builder.addCase(getEvaluationResult.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const getEvaluationResult = createAsyncThunk<
  ResultEvaluationResponse,
  EvaluationResultAnalysisPayload
>(
  'postEvaluationResultAnalysis',
  async (
    evaluationPayload: EvaluationResultAnalysisPayload,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<ResultEvaluationResponse> =
        await axiosInstance.post<ResultEvaluationResponse>(
          EndPoints.POST_EVALUATION_RESULT_ANALYSIS,
          evaluationPayload,
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { updateResultAnalysis } =
  postEvaluationResultAnalysisSlice.actions;

export default postEvaluationResultAnalysisSlice.reducer;
