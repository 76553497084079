import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { UpdateAccountRequest, updateAccount } from '../reducers/updateAccount';

interface updateAccountProps {
  error: unknown;
  loading: boolean;
  editAccount: (createClientRequest: UpdateAccountRequest) => Promise<void>;
}

const useUpdateAccount = (): updateAccountProps => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const editAccount = async (requestPayload: UpdateAccountRequest) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(updateAccount(requestPayload));
      if (updateAccount.fulfilled.match(resultAction)) {
        navigate('/account-list');
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };
  return { editAccount, loading, error };
};

export default useUpdateAccount;
