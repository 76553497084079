import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import {
  EvaluationCategoriesResponse,
  GetEvaluationCategoryReducer,
} from '../type';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: GetEvaluationCategoryReducer = {
  status: RequestStatus.Init,
  evaluationCategories: null,
};

const evaluationsCategorySlice = createSlice({
  name: '/getEvaluationCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvaluationCategories.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationCategories.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.evaluationCategories = action.payload.results;
    });
    builder.addCase(getEvaluationCategories.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const getEvaluationCategories =
  createAsyncThunk<EvaluationCategoriesResponse>(
    '/getEvaluationCategory',
    async (_, thunkAPI) => {
      try {
        const response: AxiosResponse<EvaluationCategoriesResponse, null> =
          await evaluationCategories();
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    },
  );

const evaluationCategories = async (): Promise<
  AxiosResponse<EvaluationCategoriesResponse>
> => {
  return await axiosInstance.get(EndPoints.GET_EVALUATION_CATEGORIES);
};

export default evaluationsCategorySlice.reducer;
