import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  SaveButton,
  CancelButton,
} from '../../modules/Accounts/CreateClient/CreateClient.styles';

const ConfirmationBox = (prop: {
  confirmationMessage: string;
  doOpen: boolean;
  doClose: () => void;
  deleteRecord: () => void;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    prop.doClose();
  };

  const handleDelete = () => {
    handleClose();
    prop.deleteRecord();
  };

  return (
    <Dialog
      open={prop.doOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Are you sure you want to delete?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {prop.confirmationMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SaveButton onClick={handleDelete} type="submit">
          Confirm
        </SaveButton>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationBox;
