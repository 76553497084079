/* eslint-disable indent */
import { Box, CircularProgress, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useNavigate, useParams } from 'react-router';
import React from 'react';
import EvaluationDetails from '../../../EvaluationDetails/EvaluationDetails';
import EvaluationAnalysisContainer from '../../../EvaluationAnalysis/EvaluationAnalysisContainer/EvaluationAnalysisContainer';
import useGetEvaluationDetail from '../../../hooks/useGetEvaluationDetail';
import { RootStoreType } from '../../../../../redux/store.type';
import { useAppSelector } from '../../../../../redux/store';
import useGetAccount from '../../../../Assessments/hooks/useGetAccount';
import EvaluationDetailCard from './EvaluationDetailCard';
import useEditEvaluationStatus from '../../../hooks/useEditEvaluationStatus';
import { EvaluationActions, EvaluationStatus } from '../../../type';
import { AccountSettingsUser } from '../../../../Accounts/AccountSettings/AccountSettings';
import ConfirmationBox from '../../../../../components/ConfirmationBox/ConfirmBox';
import translation from '../../../../../locales/en/translation.json';
import {
  SaveButton,
  CancelButton,
} from '../../../../Accounts/CreateClient/CreateClient.styles';
import useAccountSettingsUsers from '../../../hooks/useGetAccountSettingUser';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../../Accounts/reducers/getAccountSetting';
import { useBranding } from '../../../../Branding/reducers/getBranding';

const LegacyEvaluationDetailView = () => {
  const [value, setValue] = React.useState('1');
  const { id } = useParams();
  const getEvaluationDetailReducer = useGetEvaluationDetail();
  const { loading, editEvaluationStatus, resultFetched } =
    useEditEvaluationStatus();
  const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const branding = useBranding()

  const getAccountResponse = useGetAccount();
  const getAccountSettingsUsers = useAccountSettingsUsers();

  React.useEffect(() => {
    getEvaluationDetailReducer.getEvaluationDetailInfo(id || '');
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  let evaluationDetail = useAppSelector((state) => {
    return state.allEvaluationReducers.evaluationDetailReducer?.evaluationDetail
      ?.results[0];
  });


  const accountUserData: AccountSettingsUser[] | null = useAppSelector(
    (state: RootStoreType) => {
      return state.allClientReducer.accountSettingReducer.accountSetting;
    },
  );

  React.useEffect(() => {
    if (evaluationDetail) {
      getAccountResponse.getAccount(`${evaluationDetail.account_id}`);
    }
    if (evaluationDetail?.account_id) {
      getAccountSettingsUsers.getAccountSettingsUsers(`${evaluationDetail.account_id}`);
    }
  }, [evaluationDetail]);

  React.useEffect(() => {
    if (resultFetched) {
      evaluationDetail = undefined;
      getEvaluationDetailReducer.getEvaluationDetailInfo(id || '');
    }
  }, [loading]);

  const accountInformation = useAppSelector((state: RootStoreType) => {
    return state.assessmentReducers.accountInfoReducer.accountDetail;
  });

  return !accountInformation ||
    !accountUserData ||
    getAccountResponse.loading ||
    !evaluationDetail ||
    getAccountResponse.error ||
    loading ||
    getEvaluationDetailReducer.loading ||
    getEvaluationDetailReducer.error ? (
    <CircularProgress sx={{ margin: '20vh 48%' }} />
  ) : (
    <>
      {openConfirmBox && (
        <ConfirmationBox
          doOpen={openConfirmBox}
          deleteRecord={() => {
            editEvaluationStatus({
              evaluation_id: id || '',
              to_state: EvaluationActions.DELETE,
            });
          }}
          doClose={() => {
            setOpenConfirmBox(false);
          }}
          confirmationMessage={translation.evaluation_delete_confirm_text}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: '20px',
          paddingBottom: '20px',
        }}
      >
        <SaveButton
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </SaveButton>
        <SaveButton
          onClick={() => {
            dispatch(resetState());
            navigate(`/edit-evaluation-assessment/${id as string}`);
          }}
        >
          Edit
        </SaveButton>
      </Box>
      <Box sx={{ padding: '8px', marginBottom: '10px' }}>
        <Typography
          sx={{
            color: '#1C1C1C',
            fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
          }}
        >
          {branding?.evaluation_property_name_translation} Details
        </Typography>
      </Box>
      <EvaluationDetailCard
        reRender={openConfirmBox}
        evaluationDetail={evaluationDetail}
        updateStatus={(status: string) => {
          if (status !== EvaluationActions.DELETE) {
            editEvaluationStatus({ evaluation_id: id || '', to_state: status });
          } else {
            setOpenConfirmBox(true);
          }
        }}
      />

      <TabContext value={value}>
        <Box>
          <TabList
            sx={{
              '& .MuiTab-root': {
                '&.Mui-selected': {
                  color: '#00DB99',
                },
                textTransform: 'none',
              },
            }}
            onChange={handleChange}
            TabIndicatorProps={{ style: { backgroundColor: '#00DB99' } }}
            aria-label="lab API tabs example"
          >
            <Tab label="Summary" value="1" />
            {(
              (evaluationDetail.evaluation_status == EvaluationStatus.IN_PROGRESS ||
              evaluationDetail.evaluation_status == EvaluationStatus.RESULTS_REVIEW ||
              evaluationDetail.evaluation_status == EvaluationStatus.CLOSED)
            ) && (
                <Tab label="Analysis" value="2" />
              )}
            {/* <Tab label="Notes" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel sx={{ padding: '0' }} value="1">
          <EvaluationDetails
            accountInformation={accountInformation}
            evaluationDetail={evaluationDetail}
            accountUserData={accountUserData}
          />
        </TabPanel>
        <TabPanel sx={{ padding: '0' }} value="2">
          <EvaluationAnalysisContainer info={evaluationDetail} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default LegacyEvaluationDetailView;
