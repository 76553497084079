import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getEvaluationAnalysisResults } from '../reducers/getEvaluationResultAnalysis';

interface actionStateProps {
  loading: boolean;
  error: unknown;
  evaluationAnalysisResult: (evaluationId: string, questionId: string) => void;
}

const useGetEvaluationAnalysisResults = (): actionStateProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const evaluationAnalysisResult = async (
    evaluationId: string,
    questionId: string,
  ) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        getEvaluationAnalysisResults({
          evaluation_id: evaluationId,
          question_category_id: questionId,
        }),
      );
      if (getEvaluationAnalysisResults.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }
  };

  return { loading, error, evaluationAnalysisResult };
};

export default useGetEvaluationAnalysisResults;
