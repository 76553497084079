import { Typography, TextField, styled } from '@mui/material';

export const FieldName = styled(Typography)`
  color: #464e5f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const FieldValue = styled(Typography)`
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchTextField = styled(TextField)`
  background: '#F1F1F1';
  float: right;
  margin-top: 10px;

  & .MuiOutlinedInput-root {
    font-family: 'Poppins';
    font-size: 12px;
    max-height: 30px;
  }
`;
