/* eslint-disable indent */
import React from 'react';
import {
  Box,
  Grid
} from '@mui/material';
import { EvaluationDetail } from '../reducers/getEvaluationDetail';
import { AccountInformation } from '../../Assessments/reducers/getAccount';
import { EvaluationStatus } from '../type';
import { AccountSettingsUser } from '../../Accounts/AccountSettings/AccountSettings';
import DirectRecipientCard from './components/DirectRecipientCard';
import DistributionCard from './components/DistributionCard';
import EvaluationReport from './components/EvaluationReport';
import GeneralInfoCard from './components/GeneralInfoCard';
import ResponsesGraphCard from './components/ResponsesGraphCard';

const EvaluationDetails = (prop: {
  evaluationDetail: EvaluationDetail;
  accountInformation: AccountInformation;
  accountUserData: AccountSettingsUser[];
}) => {
  const { evaluationDetail, accountInformation, accountUserData } = prop;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        {evaluationDetail && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <GeneralInfoCard
                accountInfo={accountInformation && accountInformation}
                info={evaluationDetail}
                accountUserData={accountUserData}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      marginLeft: '10px',
                      backgroundColor: '#F7F9FB',
                      padding: '30px 20px',
                      borderRadius: '16px',
                    }}
                  >
                    <ResponsesGraphCard info={evaluationDetail} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {(evaluationDetail.evaluation_status !== EvaluationStatus.RESULTS_REVIEW && 
                  evaluationDetail.evaluation_status !== EvaluationStatus.CLOSED)
                    && (
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#F7F9FB',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <DistributionCard info={evaluationDetail} />
                        <DirectRecipientCard info={evaluationDetail} />
                      </Box>
                    )}
                </Grid>
                {evaluationDetail &&
                  evaluationDetail.evaluation_status ===
                  EvaluationStatus.RESULTS_REVIEW && (
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#F7F9FB',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <EvaluationReport info={evaluationDetail} />
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EvaluationDetails;