import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssessmentOverviewDetails, AssessmentDetailsReducer } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: AssessmentDetailsReducer = {
    assessment_details: null,
    status: RequestStatus.Init,
};

export const getAssessmentDetails = createAsyncThunk(
    'assessment/getAssessmentDetails',
    async (assessmentId: string, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<AssessmentOverviewDetails>(
                `${EndPoints.ASSESSMENT}/${assessmentId}`,
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getAssessmentDetailsSlice = createSlice({
    name: 'getAssessmentDetails',
    initialState,
    reducers: {
        clearAssessmentDetails: (state) => {
            state.assessment_details = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssessmentDetails.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getAssessmentDetails.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.assessment_details = action.payload;
            })
            .addCase(getAssessmentDetails.rejected, (state) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearAssessmentDetails } = getAssessmentDetailsSlice.actions;

export default getAssessmentDetailsSlice.reducer;
