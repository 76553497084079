import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssessmentDefinition, AssessmentDefinitionsReducer } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: AssessmentDefinitionsReducer = {
    assessment_definitions: null,
    status: RequestStatus.Init,
};

export const getPublicAssessmentDefinitions = createAsyncThunk(
    'assessment/getAssessmentDefinitions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<AssessmentDefinition[]>(
                EndPoints.PUBLIC_ASSESSMENT_DEFINITIONS
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getPublicAssessmentDefinitionsSlice = createSlice({
    name: 'getAssessmentDefinitions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPublicAssessmentDefinitions.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getPublicAssessmentDefinitions.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.assessment_definitions = action.payload;
            })
            .addCase(getPublicAssessmentDefinitions.rejected, (state, action) => {
                state.status = RequestStatus.Error;
            });
    },
});

export default getPublicAssessmentDefinitionsSlice.reducer;
