import { styled, Box, Typography } from '@mui/material';

export const Form = styled('form')`
  margin: 20px;
  padding: 20px;
  max-width: 1512px;
`;

export const TitleTypography = styled(Typography)`
  color: #181c32;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SubTitleTypography = styled(Typography)`
  color: #b5b5c3;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 5px 0;
  margin-bottom: 18px;
`;
