import React from 'react';

function TrashDIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.625 8.375V20.25C19.625 20.4157 19.5591 20.5747 19.4419 20.6919C19.3247 20.8091 19.1657 20.875 19 20.875H9C8.83429 20.875 8.67529 20.8091 8.55811 20.6919C8.44092 20.5747 8.375 20.4157 8.375 20.25V8.375H19.625Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M20.875 7.75H7.125C6.77982 7.75 6.5 8.02982 6.5 8.375C6.5 8.72018 6.77982 9 7.125 9H20.875C21.2202 9 21.5 8.72018 21.5 8.375C21.5 8.02982 21.2202 7.75 20.875 7.75Z"
        fill="#1C1C1C"
      />
      <path
        d="M11.5 12.125V17.125C11.5 17.4702 11.7798 17.75 12.125 17.75C12.4702 17.75 12.75 17.4702 12.75 17.125V12.125C12.75 11.7798 12.4702 11.5 12.125 11.5C11.7798 11.5 11.5 11.7798 11.5 12.125Z"
        fill="#1C1C1C"
      />
      <path
        d="M15.25 12.125V17.125C15.25 17.4702 15.5298 17.75 15.875 17.75C16.2202 17.75 16.5 17.4702 16.5 17.125V12.125C16.5 11.7798 16.2202 11.5 15.875 11.5C15.5298 11.5 15.25 11.7798 15.25 12.125Z"
        fill="#1C1C1C"
      />
      <path
        d="M9 20.25V8.375C9 8.02982 8.72018 7.75 8.375 7.75C8.02982 7.75 7.75 8.02982 7.75 8.375V20.25C7.75 20.7678 8.11612 21.1339 8.11612 21.1339C8.48223 21.5 9 21.5 9 21.5H19C19.5178 21.5 19.8839 21.1339 19.8839 21.1339C20.25 20.7678 20.25 20.25 20.25 20.25V8.375C20.25 8.02982 19.9702 7.75 19.625 7.75C19.2798 7.75 19 8.02982 19 8.375V20.25H9Z"
        fill="#1C1C1C"
      />
      <path
        d="M10.7992 5.79917C10.25 6.34835 10.25 7.125 10.25 7.125V8.375C10.25 8.72018 10.5298 9 10.875 9C11.2202 9 11.5 8.72018 11.5 8.375V7.125C11.5 6.86612 11.6831 6.68306 11.6831 6.68306C11.8661 6.5 12.125 6.5 12.125 6.5H15.875C16.1339 6.5 16.3169 6.68306 16.3169 6.68306C16.5 6.86612 16.5 7.125 16.5 7.125V8.375C16.5 8.72018 16.7798 9 17.125 9C17.4702 9 17.75 8.72018 17.75 8.375V7.125C17.75 6.34835 17.2008 5.79917 17.2008 5.79917C16.6517 5.25 15.875 5.25 15.875 5.25H12.125C11.3483 5.25 10.7992 5.79917 10.7992 5.79917Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default TrashDIcon;
