import { Card, styled } from '@mui/material';

export const FormContainer = styled(Card)`
  width: 90%;
  padding: 20px;
  margin-left: 10px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  gap: 10px;
`;

export const Form = styled('form')``;
