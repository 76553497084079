import { combineReducers } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { storeKey as key } from '../constants';
import {
  CreateClientReducer,
  EvaluationQuestions,
  GetAllClientsReducer,
  GetAllUsersReducer,
  GetClientReducer,
} from '../types';
import { assignAccountRoleSliceReducer } from './assignAccountRole';
import createClientReducer from './createClient';
import { deleteAccountReducer } from './deleteAccount';
import {
  AccountSettingInitialState,
  accountSettingReducer,
} from './getAccountSetting';
import { getAllUsersReducer } from './getAllUsers';
import { getClientReducer } from './getClient';
import { reducer as getClientListReducer } from './getClientList';
import getEvaluationQuestions from './getEvaluationQuestions';
import { deleteAccountSettingsRoleSliceReducer } from './postDeleteUser';
import updateAccountReducer from './updateAccount';
import { AccountAndContactInitialState, postAccountAndContactsReducer} from './postAccountAndContact';

export { key };

export default combineReducers<{
  accountSettingReducer: AccountSettingInitialState;
  createClientReducer: CreateClientReducer;
  clientListReducer: GetAllClientsReducer;
  getClientReducer: GetClientReducer;
  getEvaluationQuestions: {
    questions: EvaluationQuestions[];
    status: RequestStatus;
  };
  getAllUsersReducer: GetAllUsersReducer;
  postAccountAndContactsReducer : AccountAndContactInitialState;
  assignAccountRoleSliceReducer: {
    status: RequestStatus;
  };
  deleteAccountReducer: { status: RequestStatus };
  deleteAccountSettingsRoleSliceReducer: { status: RequestStatus };
  updateAccountReducer: { status: RequestStatus };
}>({
  createClientReducer,
  clientListReducer: getClientListReducer,
  getClientReducer,
  getEvaluationQuestions,
  accountSettingReducer,
  getAllUsersReducer,
  postAccountAndContactsReducer,
  assignAccountRoleSliceReducer,
  deleteAccountReducer,
  deleteAccountSettingsRoleSliceReducer,
  updateAccountReducer,
});
