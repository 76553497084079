import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import {
  postGetEvaluationTextAnswers,
  EvaluationAnswersPayload,
} from '../reducers/postEvaluationTextAnswers';

const usePostEvaluationTextAnswers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const postEvaluationResponseTextAnswers = async (
    requestPayload: EvaluationAnswersPayload,
  ) => {
    try {
      const resultAction = await dispatch(postGetEvaluationTextAnswers(requestPayload));
      if (postGetEvaluationTextAnswers.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, postEvaluationResponseTextAnswers };
};

export default usePostEvaluationTextAnswers;
