import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axios, { AxiosError } from 'axios';
import axiosInstance from '../../../config/axios';
import { CloudinaryImageUrlResponse } from '../../Cloudinary/type';

export interface CloudinaryImageUrlReducer {
  cloudinaryImageDetails: CloudinaryImageUrlResponse | null;
  status: RequestStatus;
}

const initialState: CloudinaryImageUrlReducer = {
  cloudinaryImageDetails: null,
  status: RequestStatus.Init,
};

type CloudinaryIdRequest = string;

export const getCloudinaryImageUrl = createAsyncThunk<
  CloudinaryImageUrlResponse, // Type of the return value (payload)
  CloudinaryIdRequest, // Type of the argument
  {
    rejectValue: AxiosError // Type for rejectWithValue
  }
>(
  'cloudinaryImageUrl/getCloudinaryImageUrl',
  async (shareableLinkId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<CloudinaryImageUrlResponse>(
        `evaluation/${shareableLinkId}/imagedetails/`
      );
      return response.data as CloudinaryImageUrlResponse; // Specify the type of response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Ensure that the rejection is of the type AxiosError
        return rejectWithValue(error);
      }
      throw error; // Throw error if it's not an AxiosError
    }
  }
);

const getCloudinaryImageUrlSlice = createSlice({
  name: 'getCloudinaryImageUrl',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCloudinaryImageUrl.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getCloudinaryImageUrl.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.cloudinaryImageDetails = action.payload;
    });
    builder.addCase(getCloudinaryImageUrl.rejected, (state) => {
      state.status = RequestStatus.Error;
      state.cloudinaryImageDetails = null;
    });
  },
});

export default getCloudinaryImageUrlSlice.reducer;
