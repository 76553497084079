import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  BackgroundCoverImg,
  ContentCard,
  FormContainer,
  PrimaryText,
  BusinessText,
  LogoImg,
  PrimaryColorBoldText,
} from './Hero.style';
import translation from '../../locales/en/translation.json';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../redux/store.type';
import { Navigate } from 'react-router-dom';
import { useBranding } from '../../modules/Branding/reducers/getBranding';
import { useTextTheme } from '../../hooks/usetextTheme';

const LegacyHero = ({ component }) => {
  const textTheme = useTextTheme();
  const branding = useBranding();
  const { currentUser } = useSelector(
    (state: RootStoreType) => state.authReducers?.user,
  );
  if (currentUser) {
    return <Navigate to="/account-list" replace />;
  }

  return (
    <Box>
      <BackgroundCoverImg
        src={require('../../assets/authentication-background.png')}
        alt="Background"
      />
      <ContentCard>
        <Grid container>
          <ThemeProvider theme={textTheme}>
            <Grid item xs={12} sm={3}>
              <Box>
                <div>
                  <LogoImg
                    src={branding?.company_logo_secondary}
                    alt=""
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      objectFit: 'contain'
                    }}
                  />
                </div>
                <PrimaryText className="primary-text">
                  Helping
                  <PrimaryColorBoldText> Manufacturers Excel</PrimaryColorBoldText> in Every
                  Facet of
                  <PrimaryColorBoldText> Business.</PrimaryColorBoldText>
                </PrimaryText>{' '}
                <BusinessText className="business-text">
                  {translation.authentication_business_text}
                </BusinessText>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid item xs={12} sm={5} style={{ marginLeft: 'auto' }}>
                <FormContainer>{component}</FormContainer>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Grid>
      </ContentCard>
    </Box>
  );
};

export default LegacyHero;
