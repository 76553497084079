import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { EvaluationQuestionCategoriesResponse } from '../type';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface EvaluationQuestionCategoryReducer {
  status: RequestStatus;
  evaluationQuestionCategories: EvaluationQuestionCategoriesResponse | null;
}

const initialState: EvaluationQuestionCategoryReducer = {
  status: RequestStatus.Init,
  evaluationQuestionCategories: null,
};

const getEvaluationQuestionCategorySlice = createSlice({
  name: '/getEvaluationQuestionCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvaluationQuestionCategories.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(
      getEvaluationQuestionCategories.fulfilled,
      (state, action) => {
        state.status = RequestStatus.Done;
        state.evaluationQuestionCategories = action.payload;
      },
    );
    builder.addCase(getEvaluationQuestionCategories.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const getEvaluationQuestionCategories =
  createAsyncThunk<EvaluationQuestionCategoriesResponse>(
    '/getEvaluationQuestionCategories',
    async (_, thunkAPI) => {
      try {
        const response: AxiosResponse<
          EvaluationQuestionCategoriesResponse,
          null
        > = await evaluationCategories();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    },
  );

const evaluationCategories = async (): Promise<
  AxiosResponse<EvaluationQuestionCategoriesResponse>
> => {
  return await axiosInstance.get(EndPoints.GET_EVALUATION_QUESTION_CATEGORIES);
};

export default getEvaluationQuestionCategorySlice.reducer;
