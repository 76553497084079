import React from 'react';
import { styled, Slider, SliderThumb, IconButton } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function LikertScaleThumb(props: any) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <IconButton size="large">
        <ArrowDropDownIcon />
      </IconButton>
    </SliderThumb>
  );
}

const LikertScaleSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  marginTop: '20px',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    background: '#d8d8d8',
    height: 3,
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#ddd',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  },
}));
interface LikertScaleProps {
  question: any;
  setFormData: any;
  marks: any;
  formData?: any;
}
const LikertScale = ({
  question,
  setFormData,
  marks,
  formData,
}: LikertScaleProps) => {
  const handleChange = (event: any) => {
    setFormData({ ...formData, [question.id]: event.target.value[0] });
  };
  return (
    <LikertScaleSlider
      sx={{ width: '300px', marginLeft: '60px' }}
      marks={marks}
      step={1}
      min={1}
      max={5}
      slots={{ thumb: LikertScaleThumb }}
      defaultValue={[1]}
      onChange={handleChange}
    />
  );
};

export default LikertScale;
