import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import { Box, Button, Container, Grid, Typography, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';

interface EnquiryFormCompanySizeIntakeProps {
  formData: { companySize: string };
  onFormChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement > | SelectChangeEvent<string>) => void;
}


const IntakeFormCompanySize : FunctionComponent<EnquiryFormCompanySizeIntakeProps> = ({ formData, onFormChange }) => {

  return (
    <Container sx={{ marginTop: '3%' }}>
      <Typography variant="h4" color="primary">Company Size</Typography>
      <Box sx={{ marginTop: '3%' }}>
        <Typography variant="body1">
          To better understand the context of your survey responses, please indicate the total number of employees within your organization.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '2%' }}>
          Please select the option from the drop-down list that best represents your company&apos;s size category.
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: '3%' }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select an option</InputLabel>
            <Select label="Select an option" value={formData.companySize} onChange={onFormChange} name='companySize'>
              <MenuItem value="1">Small Manufacturer: 1-50 employees</MenuItem>
              <MenuItem value="2">Medium Manufacturer: 51 - 500 employees</MenuItem>
              <MenuItem value="3">Large Manufacturer: 500+ employees</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntakeFormCompanySize;
