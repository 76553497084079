/* eslint-disable indent */
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Typography,
    Grid,
    TextField,
    Button
} from '@mui/material';
import { LinkSharp } from '@mui/icons-material';
import { EvaluationDetail } from '../../reducers/getEvaluationDetail';
import { EvaluationStatus } from '../../type';
import { useBranding } from '../../../Branding/reducers/getBranding';

const DistributionCard = ({ info }: { info: EvaluationDetail }) => {
    const location = useLocation();
    const baseUrl = window.location.href.replace(location.pathname, '/evaluate/');
    const copyEvaluationLinkToClipboard = () => {
        const tempInput = document.createElement('input');
        tempInput.id = 'evaluation-link';
        tempInput.value = baseUrl + info?.shareable_link;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    };
    const branding = useBranding();

    return (
        <Box>
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                }}
                variant="h5"
                component="h2"
                gutterBottom
            >
                Share {branding?.evaluation_property_name_translation}
            </Typography>
            {info.evaluation_status === EvaluationStatus.DRAFT && (
                <Typography
                    variant="body1"
                    sx={{
                        color: '#B5B5C3',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        marginBottom: '14px',
                    }}
                >
                    <span style={{ color: 'red' }}>
                        Anyone with this link will be able to view and complete once it is published.
                    </span>
                </Typography>
            )}
            {info.evaluation_status === EvaluationStatus.IN_PROGRESS && (
                <Typography
                    variant="body1"
                    sx={{
                        color: '#B5B5C3',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        marginBottom: '14px',
                    }}
                >
                    Anyone with this link will be able to view and complete once it is published.
                </Typography>
            )}
            <Typography
                sx={{
                    marginTop: '14px',
                    fontSize: '14px',
                    fontWeight: 600,
                }}
                variant="h5"
                component="h2"
                gutterBottom
            >
                Anonymous Respondents
            </Typography>
            <Grid container gap={1} display={'flex'} alignItems={'center'}>
                <Grid item sm={8}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={baseUrl + info?.shareable_link || ''}
                    />
                </Grid>
                <Grid item sm={3}>
                    <Button
                        size="small"
                        sx={{
                            backgroundColor: '#000',
                            textTransform: 'none',
                            maxHeight: '32px',
                            color: '#fff',
                            padding: '10px 20px',
                            whiteSpace: 'nowrap',
                            '&:hover': {
                                backgroundColor: '#000',
                                color: '#fff',
                            },
                        }}
                        endIcon={<LinkSharp />}
                        onClick={copyEvaluationLinkToClipboard}
                    >
                        Copy Link
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DistributionCard;