/* eslint-disable indent */
import React, { useEffect, useState, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Supplier, Naics } from '../type';
import { Modal, Box, Typography, Button, TextField, Autocomplete, Checkbox, FormControlLabel } from '@mui/material';
import useUpdateSupplier from '../hooks/usePutSupplier';
import useCreateSupplier from '../hooks/usePostSupplier';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiY29udGluZXVyIiwiYSI6ImNseWRoNjd4aTA2Mmsya3EyemFib3h1dWcifQ.MD3QstVeymX9B0xKr8XqBQ';

interface MapChartProps {
    suppliers: Supplier[];
    naicsCodes: Naics[];
    onSupplierChange: () => void;
}

const MapChart: React.FC<MapChartProps> = ({ suppliers, naicsCodes, onSupplierChange }) => {
    const mapContainerRef = useRef<HTMLDivElement | null>(null);
    const mapRef = useRef<mapboxgl.Map | null>(null);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSuppliers, setFilteredSuppliers] = useState<Supplier[]>(suppliers);
    const [selectedNaicsCodesForFilter, setSelectedNaicsCodesForFilter] = useState<string[]>([]);
    const [selectedNaicsCodesForModal, setSelectedNaicsCodesForModal] = useState<Naics[]>([]);
    const [naicsOptions, setNaicsOptions] = useState<string[]>([]);
    const [showPartners, setShowPartners] = useState(false);
    const [showOemManufacturers, setShowOemManufacturers] = useState(false);
    const [showFederalManufacturers, setShowFederalManufacturers] = useState(false);
    const [showMilitaryManufacturers, setShowMilitaryManufacturers] = useState(false);
    const markersRef = useRef<Record<string, mapboxgl.Marker>>({});
    const popupsRef = useRef<Record<string, mapboxgl.Popup>>({});
    const currentPopupRef = useRef<mapboxgl.Popup | null>(null);
    const autocompleteInputRef = useRef<HTMLInputElement | null>(null);

    const { editSupplier, loading, error } = useUpdateSupplier();
    const { createSupplier } = useCreateSupplier();
    const [editSupplierData, setEditSupplierData] = useState<Supplier | null>(null);

    useEffect(() => {
        if (mapContainerRef.current) {
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [-157.5, 20], // Initial center of the map (This needs to be made dynamic)
                zoom: 6.5, // (This needs to be made dynamic)
            });

            mapRef.current.on('load', () => {
                updateMarkers(filteredSuppliers);
            });
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    useEffect(() => {
        // Type guard to ensure the safety of the naics_codes
        const getNaicsCodes = (supplier: Supplier): string[] => {
            if (!supplier.naics_codes) return [];
            return supplier.naics_codes.map((naics: Naics) => {
                const code = naics.code;
                if (typeof code !== 'number') {
                    throw new Error('Invalid NAICS code type');
                }
                return code.toString();
            });
        };

        // Extract unique NAICS codes from suppliers, sort them numerically, and set the options for the search bar.
        const uniqueNaicsCodes: string[] = Array.from(
            new Set(
                suppliers
                    .flatMap((supplier) => getNaicsCodes(supplier))
                    .filter((code: string): code is string => code.trim() !== '')
            )
        );

        uniqueNaicsCodes.sort((a, b) => Number(a) - Number(b));

        setNaicsOptions(uniqueNaicsCodes);
    }, [suppliers]);

    useEffect(() => {
        // Update the list of Suppliers to only include those that meet the filter and search criteria.
        setFilteredSuppliers(
            suppliers.filter((supplier) => {
                const matchesCompanyName = supplier.company_name.toLowerCase().includes(searchTerm.toLowerCase());
                const matchesNaicsCodes = selectedNaicsCodesForFilter.length === 0 ||
                    selectedNaicsCodesForFilter.some((code) =>
                        supplier.naics_codes?.some((naics: Naics) => naics.code.toString().startsWith(code)) ?? false
                    );
                const matchesPartner = !showPartners || supplier.is_partner === true;
                const matchesOemManufacturers = !showOemManufacturers || supplier.is_oem_manufacturer === true;
                const matchesFederalManufacturers = !showFederalManufacturers || supplier.is_federal_manufacturer === true;
                const matchesMilitaryManufacturers = !showMilitaryManufacturers || supplier.is_military_manufacturer === true;

                return matchesCompanyName &&
                    matchesNaicsCodes &&
                    matchesPartner &&
                    matchesOemManufacturers &&
                    matchesFederalManufacturers &&
                    matchesMilitaryManufacturers;
            })
        );
    }, [searchTerm, suppliers, selectedNaicsCodesForFilter, showPartners, showOemManufacturers, showFederalManufacturers, showMilitaryManufacturers]);

    useEffect(() => {
        if (mapRef.current) {
            updateMarkers(filteredSuppliers);
        }
    }, [filteredSuppliers]);

    const updateMarkers = (suppliers: Supplier[]) => {
        Object.values(markersRef.current).forEach((marker) => marker.remove());
        markersRef.current = {};

        suppliers.forEach((supplier) => {
            if (supplier.id == null) {
                console.error("Supplier ID is missing");
                return;
            }

            const markerElement = document.createElement('div');
            markerElement.className = 'marker';
            markerElement.style.backgroundColor = '#034dc5';
            markerElement.style.width = '20px';
            markerElement.style.height = '20px';
            markerElement.style.borderRadius = '50%';
            markerElement.style.cursor = 'pointer';

            const marker = new mapboxgl.Marker(markerElement)
                .setLngLat([supplier.longitude ?? 0, supplier.latitude ?? 0])
                .addTo(mapRef.current!);

            const popup = new mapboxgl.Popup({
                offset: { top: [0, 10], bottom: [0, -10], left: [10, 0], right: [-10, 0] },
                closeButton: false,
            }).setHTML(`<h3>${supplier.company_name}</h3>`);

            marker.setPopup(popup);

            marker.getElement().addEventListener('mouseenter', () => {
                resetMarkersColor();
                (marker.getElement() as HTMLElement).style.backgroundColor = 'red';
                if (currentPopupRef.current) {
                    currentPopupRef.current.remove();
                }
                popup.addTo(mapRef.current!);
                currentPopupRef.current = popup;
            });

            marker.getElement().addEventListener('mouseleave', () => {
                if (currentPopupRef.current !== popup) {
                    (marker.getElement() as HTMLElement).style.backgroundColor = '#034dc5';
                }
            });

            marker.getElement().addEventListener('click', () => {
                handleSupplierClick(supplier);
            });

            const popupElement = popup.getElement();
            if (popupElement) {
                popupElement.addEventListener('click', () => {
                    handleSupplierClick(supplier);
                });
            }

            markersRef.current[supplier.id] = marker;
            popupsRef.current[supplier.id] = popup;
        });

        mapRef.current!.on('zoom', () => {
            const zoom = mapRef.current!.getZoom();
            updateMarkerSize(zoom);
        });

        updateMarkerSize(mapRef.current!.getZoom());
    };

    const updateMarkerSize = (zoom: number) => {
        const size = Math.max(5, Math.min(20, (zoom - 5) * 4));
        Object.values(markersRef.current).forEach((marker) => {
            const markerElement = marker.getElement() as HTMLElement;
            markerElement.style.width = `${size}px`;
            markerElement.style.height = `${size}px`;
        });
    };

    const resetMarkersColor = () => {
        Object.values(markersRef.current).forEach((marker) => {
            (marker.getElement() as HTMLElement).style.backgroundColor = '#034dc5';
        });
    };

    const flyToSupplier = (supplier: Supplier) => {
        if (supplier.id == null) {
            console.error("Supplier ID is missing");
            return;
        }

        if (mapRef.current) {
            mapRef.current.flyTo({
                center: [supplier.longitude ?? 0, supplier.latitude ?? 0],
                essential: true,
                zoom: 15,
            });

            resetMarkersColor();
            const selectedMarker = markersRef.current[supplier.id];
            if (selectedMarker) {
                (selectedMarker.getElement() as HTMLElement).style.backgroundColor = 'red';
            }

            if (currentPopupRef.current) {
                currentPopupRef.current.remove();
            }

            const selectedPopup = popupsRef.current[supplier.id];
            if (selectedPopup) {
                selectedPopup.addTo(mapRef.current!);
                currentPopupRef.current = selectedPopup;
            }
        }
    };

    const handleSupplierClick = (supplier: Supplier) => {
        if (supplier.id == null) {
            console.error("Supplier ID is missing");
            return;
        }

        setSelectedSupplier(supplier);
        setEditSupplierData(supplier);
        setSelectedNaicsCodesForModal(supplier.naics_codes || []);
        setOpen(true);
        setIsEditMode(false);
        setIsCreateMode(false);

        resetMarkersColor();

        const selectedMarker = markersRef.current[supplier.id];
        if (selectedMarker) {
            (selectedMarker.getElement() as HTMLElement).style.backgroundColor = 'red';
        }

        if (currentPopupRef.current) {
            currentPopupRef.current.remove();
        }

        const selectedPopup = popupsRef.current[supplier.id];
        if (selectedPopup) {
            selectedPopup.addTo(mapRef.current!);
            currentPopupRef.current = selectedPopup;
        }
    };

    const getDefaultSupplier = (): Supplier => ({
        id: null,
        company_id: 0,
        source_file_id: null,
        company_name: '',
        physical_address: '',
        physical_city: '',
        physical_state: '',
        physical_zip: '',
        physical_county: '',
        latitude: 0,
        longitude: 0,
        mailing_address: null,
        mailing_city: null,
        mailing_state: null,
        mailing_zip: null,
        phone: null,
        alternate_phone: null,
        toll_free: null,
        fax: null,
        website: null,
        company_email: null,
        num_employees: null,
        total_employees: null,
        annual_sales: null,
        square_footage: null,
        year_established: null,
        distribution_area: null,
        company_ownership: null,
        imports: false,
        is_female_owned: false,
        is_minority_owned: false,
        is_veteran_owned: false,
        iso_certifications: null,
        business_description: null,
        brand_names: null,
        primary_sic_code: null,
        primary_sic_code_description: null,
        sic_code_2: null,
        sic_code_2_description: null,
        sic_code_3: null,
        sic_code_3_description: null,
        sic_code_4: null,
        sic_code_4_description: null,
        is_oem_manufacturer: false,
        is_federal_manufacturer: false,
        is_military_manufacturer: false,
        is_partner: false,
        naics_codes: [],
        offerings: null
    });

    const handleCreateSupplier = () => {
        setSelectedSupplier(null);
        setEditSupplierData(getDefaultSupplier());
        setSelectedNaicsCodesForModal([]);
        setOpen(true);
        setIsEditMode(true);
        setIsCreateMode(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedSupplier(null);
        resetMarkersColor();
    };

    const handleKeyDown = (event: React.KeyboardEvent, supplier: Supplier) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            flyToSupplier(supplier);
        }
    };

    const handleAutocompleteKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && autocompleteInputRef.current) {
            const inputValue = autocompleteInputRef.current.value;
            if (inputValue && !selectedNaicsCodesForModal.some(naics => naics.code === Number(inputValue))) {
                const newNaics = naicsCodes.find(naics => naics.code === Number(inputValue));
                if (newNaics) {
                    setSelectedNaicsCodesForModal([...selectedNaicsCodesForModal, newNaics]);
                }
                autocompleteInputRef.current.value = '';
            }
        }
    };

    const handleFilterAutocompleteKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && autocompleteInputRef.current) {
            const inputValue = autocompleteInputRef.current.value;
            if (inputValue && !selectedNaicsCodesForFilter.includes(inputValue)) {
                setSelectedNaicsCodesForFilter([...selectedNaicsCodesForFilter, inputValue]);
                setNaicsOptions([...naicsOptions, inputValue]);
                autocompleteInputRef.current.value = '';
            }
        }
    };

    const handleEditChange = (field: keyof Supplier, value: any) => {
        if (editSupplierData) {
            setEditSupplierData({ ...editSupplierData, [field]: value });
        }
    };

    const handleNaicsChange = (event: any, newValue: Naics[]) => {
        setSelectedNaicsCodesForModal(newValue);
        if (editSupplierData) {
            setEditSupplierData({ ...editSupplierData, naics_codes: newValue });
        }
    };

    const handleSave = async () => {
        if (isCreateMode) {
            if (editSupplierData) {
                await createSupplier(editSupplierData);
                setIsEditMode(false);
                setIsCreateMode(false);
                onSupplierChange();
            }
        } else {
            if (editSupplierData) {
                await editSupplier(editSupplierData);
                setIsEditMode(false);
                onSupplierChange();
            }
        }
    };

    const sortedNaicsOptions = [...naicsOptions].sort((a, b) => {
        const aSelected = selectedNaicsCodesForFilter.includes(a);
        const bSelected = selectedNaicsCodesForFilter.includes(b);
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;
        return Number(a) - Number(b);
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <ToastContainer autoClose={5000} />
            <div style={{ display: 'flex', flexDirection: 'row', padding: '10px', paddingLeft: '0px', backgroundColor: 'white', zIndex: 1 }}>
                <TextField
                    label="Search Suppliers"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginRight: '10px', width: '300px' }}
                />
                <Autocomplete
                    multiple
                    options={sortedNaicsOptions}
                    getOptionLabel={(option) => option}
                    value={selectedNaicsCodesForFilter}
                    onChange={(event, newValue) => setSelectedNaicsCodesForFilter(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Filter NAICS Codes"
                            placeholder="NAICS Codes"
                            inputRef={autocompleteInputRef}
                            onKeyDown={handleFilterAutocompleteKeyDown}
                        />
                    )}
                    style={{ width: '300px' }}
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={showPartners} onChange={() => setShowPartners(!showPartners)} color="primary" style={{ marginLeft: '5px' }} />
                    }
                    label="Partners"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={showOemManufacturers} onChange={() => setShowOemManufacturers(!showOemManufacturers)} color="primary" style={{ marginLeft: '5px' }} />
                    }
                    label="OEM"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={showFederalManufacturers} onChange={() => setShowFederalManufacturers(!showFederalManufacturers)} color="primary" style={{ marginLeft: '5px' }} />
                    }
                    label="Federal"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={showMilitaryManufacturers} onChange={() => setShowMilitaryManufacturers(!showMilitaryManufacturers)} color="primary" style={{ marginLeft: '5px' }} />
                    }
                    label="Military"
                />
            </div>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ width: '25%', overflowY: 'auto', paddingRight: '5px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateSupplier}
                        style={{
                            width: '100%',
                            marginBottom: '10px',
                            position: 'sticky',
                            top: 0,
                            zIndex: '1',
                        }}
                    >
                        Add New Supplier
                    </Button>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {filteredSuppliers.map((supplier) => (
                            <li key={supplier.id} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid black', paddingBottom: '5px' }}>
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => flyToSupplier(supplier)}
                                    onKeyDown={(event) => handleKeyDown(event, supplier)}
                                    style={{
                                        flexGrow: 1,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <strong>{supplier.company_name}</strong>
                                    {supplier.naics_codes && supplier.naics_codes.length > 0 ? (
                                        supplier.naics_codes.map((naics) => (
                                            <p key={naics.code}>
                                                {naics.code} - {naics.description}
                                            </p>
                                        ))
                                    ) : (
                                        <p>No NAICS codes available</p>
                                    )}
                                </div>
                                <button
                                    type="button"
                                    onClick={() => handleSupplierClick(supplier)}
                                    style={{
                                        marginLeft: '10px',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        background: 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Details
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div ref={mapContainerRef} style={{ width: '75%', height: '100%' }} />
            </div>

            <Modal open={open} onClose={handleClose}>
                <Box sx={{ ...modalStyle }}>
                    {editSupplierData && (
                        <>
                            {isEditMode ? (
                                <>
                                    <TextField
                                        label="Company Name"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={editSupplierData?.company_name}
                                        onChange={(e) => handleEditChange('company_name', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <TextField
                                        label="Company Id"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        size="small"
                                        value={editSupplierData?.company_id}
                                        onChange={(e) => handleEditChange('company_id', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <Autocomplete
                                        multiple
                                        options={naicsCodes}
                                        getOptionLabel={(option) => `${option.code} - ${option.description}`}
                                        value={selectedNaicsCodesForModal}
                                        onChange={handleNaicsChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="NAICS Codes"
                                                placeholder="NAICS Codes"
                                                sx={{ mt: 2 }}
                                            />
                                        )}
                                    />
                                    <TextField
                                        label="Address"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        size="small"
                                        value={editSupplierData?.physical_address}
                                        onChange={(e) => handleEditChange('physical_address', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <TextField
                                        label="City"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        size="small"
                                        value={editSupplierData?.physical_city}
                                        onChange={(e) => handleEditChange('physical_city', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <TextField
                                        label="State"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        size="small"
                                        value={editSupplierData?.physical_state}
                                        onChange={(e) => handleEditChange('physical_state', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <TextField
                                        label="Zip"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        size="small"
                                        value={editSupplierData?.physical_zip}
                                        onChange={(e) => handleEditChange('physical_zip', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <TextField
                                        label="County"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={editSupplierData?.physical_county}
                                        onChange={(e) => handleEditChange('physical_county', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <TextField
                                        label="Phone"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={editSupplierData?.phone}
                                        onChange={(e) => handleEditChange('phone', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        size="small"
                                        value={editSupplierData?.company_email}
                                        onChange={(e) => handleEditChange('company_email', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <TextField
                                        label="Website"
                                        variant="outlined"
                                        size="small"
                                        value={editSupplierData?.website}
                                        onChange={(e) => handleEditChange('website', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox size="small" checked={editSupplierData?.is_partner} onChange={(e) => handleEditChange('is_partner', e.target.checked)} />}
                                        label="Partner"
                                        sx={{ mt: 2 }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox size="small" checked={editSupplierData?.is_oem_manufacturer} onChange={(e) => handleEditChange('is_oem_manufacturer', e.target.checked)} />}
                                        label="OEM"
                                        sx={{ mt: 2 }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox size="small" checked={editSupplierData?.is_federal_manufacturer} onChange={(e) => handleEditChange('is_federal_manufacturer', e.target.checked)} />}
                                        label="Federal"
                                        sx={{ mt: 2 }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox size="small" checked={editSupplierData?.is_military_manufacturer} onChange={(e) => handleEditChange('is_military_manufacturer', e.target.checked)} />}
                                        label="Military"
                                        sx={{ mt: 2 }}
                                    />
                                    <Button onClick={handleSave} sx={{ mt: 2 }}>
                                        Save
                                    </Button>
                                    <Button onClick={() => { setIsEditMode(false); if (isCreateMode) setOpen(false); }} sx={{ mt: 2 }}>
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Typography variant="h6" component="h2">
                                        {selectedSupplier?.company_name}
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        <strong>NAICS Codes and Descriptions:</strong>
                                    </Typography>
                                    {selectedSupplier?.naics_codes && selectedSupplier.naics_codes.length > 0 ? (
                                        selectedSupplier.naics_codes.map((naics) => (
                                            <Typography sx={{ mt: 1 }} key={naics.code}>
                                                {naics.code} | {naics.description}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography sx={{ mt: 2 }}>No NAICS codes available</Typography>
                                    )}
                                    <Typography sx={{ mt: 2 }}>
                                        <strong>Address:</strong> {selectedSupplier?.physical_address}, {selectedSupplier?.physical_city}, {selectedSupplier?.physical_state} {selectedSupplier?.physical_zip}
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        <strong>Phone:</strong> {selectedSupplier?.phone}
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        <strong>Email:</strong> {selectedSupplier?.company_email}
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        <strong>Website:</strong> <a href={selectedSupplier?.website ?? ''} target="_blank" rel="noopener noreferrer">{selectedSupplier?.website}</a>
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        <FormControlLabel control={<Checkbox checked={selectedSupplier?.is_partner} />} label="Partner" />
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        <FormControlLabel control={<Checkbox checked={selectedSupplier?.is_oem_manufacturer} />} label="OEM" />
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        <FormControlLabel control={<Checkbox checked={selectedSupplier?.is_federal_manufacturer} />} label="Federal" />
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        <FormControlLabel control={<Checkbox checked={selectedSupplier?.is_military_manufacturer} />} label="Military" />
                                    </Typography>
                                    <Button onClick={() => setIsEditMode(true)} sx={{ mt: 2 }}>
                                        Edit
                                    </Button>
                                </>
                            )}
                            <Button onClick={handleClose} sx={{ mt: 2 }}>
                                Close
                            </Button>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
} as const;

export default MapChart;
