import React from 'react';
import { Box } from '@mui/material';
import GeneralInfoCard from './GeneralnfoCard/GeneralInfoCard';
import ContactCard from './ContactsCard/ContactsCard';
import { AccountDetails } from '../../../modules/Accounts/types';

const Overview = (prop: { accountDetails: AccountDetails }) => {
  const { accountDetails } = prop;
  return (
    <Box sx={{ padding: '20px' }}>
      <GeneralInfoCard props={accountDetails} />
      <ContactCard account_contact={accountDetails.account_contacts} />
    </Box>
  );
};

export default Overview;
