import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { CategoryAnalysisNotePayload } from '../type';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';

const initialState = {
    status: RequestStatus.Init,
};

const putCategoryAnalysisNoteSlice = createSlice({
    name: 'assessment/CategoryAnalysisNote',
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(putCatagoryAnalysisNoteThunk.pending, (state) => {
            state.status = RequestStatus.Loading;
        });
        builder.addCase(putCatagoryAnalysisNoteThunk.fulfilled, (state) => {
            state.status = RequestStatus.Done;
        });
        builder.addCase(putCatagoryAnalysisNoteThunk.rejected, (state) => {
            state.status = RequestStatus.Error;
        });
    },
});

export const putCatagoryAnalysisNoteThunk = createAsyncThunk<
    string,
    CategoryAnalysisNotePayload
>(
    'updateCatagoryAnalysisNote',
    async (notePayload: CategoryAnalysisNotePayload, { rejectWithValue }) => {
        try {
            const result: AxiosResponse<string> = await axiosV2Instance.put(
                `${EndPoints.ASSESSMENT_CATEGORY_ANALYSIS_NOTE}/${notePayload.assessment_id}`,
                notePayload,
            );
            return result.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export default putCategoryAnalysisNoteSlice.reducer;
