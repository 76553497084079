import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  Typography10px400,
  Typography19px600,
  Typography14px600,
  Typography12px400,
  CommonTypography,
} from '../../../styles/Global.styles';

interface CategoryProps {
  title: string;
  challengesThreats: string[];
  possibleOfferings: string[];
}

const ChallengesAndOpportunitiesComponent: React.FC<CategoryProps> = ({ title, challengesThreats, possibleOfferings }) => (
  <Grid item xs={12} md={4}>
    <Box sx={{ marginBottom: '70px', borderRadius: '16px', background: 'var(--primary-light, #F7F9FB)', padding: '20px' }}>
      <Typography19px600 style={{ textAlign: 'center', fontSize: '16px', marginBottom: '10px', marginTop: '0px' }}>
        {title}
      </Typography19px600>
      <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <Grid item>
          <Typography14px600 sx={{ fontSize: '14px' }}>{'Gaps, Challenges & Threats'}</Typography14px600>
          {challengesThreats.length > 0 ? (
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              {challengesThreats.map((challenge, index) => (
                <li key={index}>
                  <Typography12px400 sx={{ fontSize: '12px' }}>
                    {challenge}
                  </Typography12px400>
                </li>
              ))}
            </ul>
          ) : (
            <Typography12px400 sx={{ fontSize: '12px' }}>
              {"No Challenges specified"}
            </Typography12px400>
          )}
        </Grid>
        <Grid item>
          <Typography14px600 sx={{ fontSize: '14px' }}>{'Solutions'}</Typography14px600>
          {possibleOfferings.length > 0 ? (
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              {possibleOfferings.map((offering, index) => (
                <li key={index}>
                  <Typography12px400 sx={{ fontSize: '12px' }}>
                    {offering}
                  </Typography12px400>
                </li>
              ))}
            </ul>
          ) : (
            <Typography12px400 sx={{ fontSize: '12px' }}>
              {"No Opportunities specified"}
            </Typography12px400>
          )}
        </Grid>
      </Grid>
    </Box>
  </Grid>
);

export default ChallengesAndOpportunitiesComponent;
