// src/hooks/usePostAssessmentLink.ts

import { useState } from 'react';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';

interface PostAssessmentLinkParams {
  email: string;
  assessment_id: string;
  recipient_ids?: number[];
  template_type?: 'INVITATION' | 'REMINDER';
}

export const usePostAssessmentLink = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const postAssessmentLink = async (params: PostAssessmentLinkParams) => {
    try {
      setLoading(true);
      const payload = {
        ...(params.recipient_ids && { recipient_ids: params.recipient_ids }),
        template_type: params.template_type || 'INVITATION'
      };

      const response = await axiosV2Instance.post(
        `${EndPoints.ASSESSMENT}/sendrecipientemails/${params.assessment_id}`,
        payload
      );

      if (response.status === 204) {
        toast.success('Assessment emails sent successfully');
        return true;
      }
      return false;

    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to send assessment emails';
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { postAssessmentLink, loading, error };
};