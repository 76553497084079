import { useDispatch } from 'react-redux';
import { getScoresByCategory } from '../reducers/getScoresByCategory';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

interface useGetScoresByCategoryProps {
    loading: boolean;
    error: AxiosError | null;
    getAssessmentCategoryScoreDetails: (assessmentId: string) => void;
}

const useGetScoresByCategory = (): useGetScoresByCategoryProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const getAssessmentCategoryScoreDetails = async (assessmentId: string) => {
        try {
            setLoading(true);
            const resultAction = await dispatch(getScoresByCategory(assessmentId));
            if (getScoresByCategory.fulfilled.match(resultAction)) {
                setLoading(false);
            } else if (getScoresByCategory.rejected.match(resultAction)) {
                setLoading(false);
                setError(resultAction.payload as AxiosError);
            }
        } catch (err) {
            setLoading(false);
            setError(err as AxiosError);
        }
    };

    return { error, loading, getAssessmentCategoryScoreDetails };
};

export default useGetScoresByCategory;