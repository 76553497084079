import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  CircularProgress,
  Autocomplete,
  Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../../redux/store.type';
import { AccountDetails } from '../../../modules/Accounts/types';
import { usePublicGetClients } from '../../../modules/Accounts/hooks/useGetClients';
import IntakeFormExistingCompanyModal from './IntakeFormExistingCompanyModal';
import { useGetPublicAssessments } from '../../../modules/Assessments/hooks/useGetPublicAssessments';
import {
  AssessmentDefinition,
  PublicAssessmentOverviewDetails,
} from '../../../modules/Assessments/type';
import { useNavigate } from 'react-router-dom';
import { CustomAccountBranding } from '../../../modules/Branding/type';
import {
  PostGroupRequest,
  PostGroupResponse,
} from '../../../modules/Assessments/reducers/postGroup';
import usePostGroup from '../../../modules/Assessments/hooks/usePostGroup';
import { mapToAssessmentCreation } from '../helpers/Helpers';
import usePostAssessment from '../../../modules/Assessments/hooks/usePostAssessment';
import { PreviousButton, ContinueButton } from './IntakeFormDemographics'

interface CompanySelectionProps {
  onCompanySelect: (companyData: any) => void;
  branding: CustomAccountBranding;
  assessmentDefinitions: AssessmentDefinition[] | null;
  selectedCompany: AccountDetails | null;
  selectedCompanyChange: React.Dispatch<React.SetStateAction<AccountDetails | null>>;
}

const CompanySelectionForm: React.FC<CompanySelectionProps> = ({
  onCompanySelect,
  branding,
  assessmentDefinitions,
  selectedCompany,
  selectedCompanyChange
}) => {
  const accountListResponse = useSelector((state: RootStoreType) => {
    return state.allClientReducer.clientListReducer.accountListResponse;
  });

  const postAssessment = usePostAssessment();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<AccountDetails[]>([]);
  const [assessment, setAssessment] =
    useState<PublicAssessmentOverviewDetails | null>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const clientListReducer = usePublicGetClients();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExistingAssessmentGroup, setIsExistingAssessmentGroup] =
    useState(true);
  const { getPublicAssessmentsByAccountId, error, loading } =
    useGetPublicAssessments();
  const postGroup = usePostGroup();

  const fetchCompanies = (searchText = '') => {
    setPageLoading(true);
    try {
      clientListReducer.clientList({ search: searchText });
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleInputChange = (
    event: ChangeEvent<object>,
    searchValue: string,
  ) => {
    if (searchValue.trim() === '') {
      fetchCompanies();
      return;
    }
    fetchCompanies(searchValue);
  };

  const handleInputSelect = (
    event: React.SyntheticEvent,
    companyAccount: AccountDetails | null,
  ) => {
    if (!companyAccount) {
      // Clear the company state if input is cleared
      selectedCompanyChange(null);
      return;
    }
    selectedCompanyChange(companyAccount);
    
    // const company = companyAccount;
    // const today = new Date();
    // const year = today.getFullYear();
    // const groupName = `${year} ${company.name}`;

    //Check if assessmentGroup already exists
    // setAssessment(
    //   (await getPublicAssessmentsByAccountId(
    //     company?.id as string,
    //     groupName,
    //   )) as PublicAssessmentOverviewDetails,
    // );
    // onCompanySelect(false);

    // CALEB
    // setIsModalOpen(true);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (accountListResponse) {
      setCompanies(accountListResponse.results);
    }
  }, [accountListResponse]);

  // useEffect(() => {
  //   if (!assessment) {
  //     setIsExistingAssessmentGroup(false);
  //   } else {
  //     setIsExistingAssessmentGroup(true);
  //   }
  // }, [assessment]);

  // Logic called from existing company modal
  // const handleAssessmentCreationContinue = async () => {
  //   setIsModalOpen(false);
  //   const today = new Date();
  //   const year = today.getFullYear();

  //   const groupRequest: PostGroupRequest = {
  //     name: `${year} ${company?.name as string}`,
  //     account_id: company?.id as string,
  //   };
  //   const assessmentGroup = (await postGroup.postGroups(
  //     groupRequest,
  //   )) as PostGroupResponse;

  //   const dateNow = today.toISOString().split('T')[0];
  //   const futureDate = new Date();
  //   futureDate.setDate(today.getDate() + 14);
  //   const dateNowPlus14Days = futureDate.toISOString().split('T')[0];

  //   const eocTargetResponses = 1;
  //   const eesTargetResponses = 5;
  //   const employerOfChoiceSurveyDefinition = assessmentDefinitions!.find(
  //     (assessmentDef) =>
  //       assessmentDef.name.includes('Employer of Choice Survey'),
  //   );

  //   const employerOfChoiceSurvey = mapToAssessmentCreation(
  //     company!.id as string,
  //     employerOfChoiceSurveyDefinition!.id,
  //     ((company!.name as string) +
  //       ' ' +
  //       employerOfChoiceSurveyDefinition!.name) as string,
  //     dateNow,
  //     dateNowPlus14Days,
  //     eocTargetResponses,
  //     assessmentGroup.id,
  //   );

  //   const employeeEngagementSurveyDefinition = assessmentDefinitions!.find(
  //     (assessmentDef) =>
  //       assessmentDef.name.includes('Employee Engagement Survey'),
  //   );

  //   const employeeEngagementSurvey = mapToAssessmentCreation(
  //     company!.id as string,
  //     employeeEngagementSurveyDefinition!.id,
  //     ((company!.name as string) +
  //       ' ' +
  //       employeeEngagementSurveyDefinition!.name) as string,
  //     dateNow,
  //     dateNowPlus14Days,
  //     eesTargetResponses,
  //     assessmentGroup.id,
  //     true
  //   );

  //   const eocAssessment = await postAssessment.postAssessment(
  //     employerOfChoiceSurvey,
  //   );

  //   const eesAssessment = await postAssessment.postAssessment(
  //     employeeEngagementSurvey,
  //   );
  //   setPageLoading(false);
  //   navigate(`/evaluate/${eocAssessment.uuid as string}`);
  // };

  // const handleExistingAssessmentContinue = () => {
  //   setIsModalOpen(false);
  //   setPageLoading(false);
  //   navigate(`/evaluate/${assessment?.uuid as string}`);
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // navigate(`/`);
  };

  return (
    <Container>
      {/* <IntakeFormExistingCompanyModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onAssessmentCreationContinue={handleAssessmentCreationContinue}
        onExistingAssessmentContinue={handleExistingAssessmentContinue}
        existingCompany={company}
        branding={branding}
        existingAssessmentGroup={isExistingAssessmentGroup}
      /> */}
      <Typography variant="h4" color="primary" sx={{ marginBottom: '5%' }}>
        Company Selection
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '2%' }}>
        Input the name of your company to check for existing registration:
      </Typography>
      <Autocomplete
        options={companies}
        getOptionLabel={(option) => option.name}
        loading={pageLoading}
        onInputChange={handleInputChange}
        onChange={handleInputSelect}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        filterOptions={(x) => x} // Prevents filtering on client side since backend is filtering based on input
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for Company"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {pageLoading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Typography
        style={{ marginTop: '3%' }}
        onClick={onCompanySelect}
        color="primary"
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        No search results for your company? Click here to register
      </Typography>
    </Container>
  );
};

export default CompanySelectionForm;
