import { useAppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { postGroup, PostGroupRequest } from '../reducers/postGroup';

interface postGroupProps {
  error: unknown;
  loading: boolean;
  postGroups: (createClientRequest: PostGroupRequest) => Promise<any>;
}

const usePostGroup = (): postGroupProps => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const postGroups = async (requestPayload: PostGroupRequest) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(postGroup(requestPayload));
      if (postGroup.fulfilled.match(resultAction)) {
        setLoading(false);
      }
      return resultAction.payload;
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };
  return { postGroups, loading, error };
};

export default usePostGroup;
